<div class="banner">
   <div class="info">
      <i
         nz-icon
         class="icon"
         nzType="lock"
         nzTheme="fill"></i>
   </div>

   <div class="text">
      Post Locked: This post is currently being edited by
      <button
         nz-popover
         class="name"
         [nzPopoverContent]="profileCardTemplate"
         [nzPopoverPlacement]="'right'">
         {{ name }}
      </button>
   </div>

   <!-- UNLOCK BUTTON -->
   <div class="unlock-banner">
      <button
         nz-button
         nzType="default"
         nzSize="small"
         (click)="unlockEvent()"
         *ngIf="unlock">
         Unlock Post
      </button>
   </div>
</div>

<ng-template #profileCardTemplate>
   <app-profile-overlay [profileData]="postDetails.lockedByUser"></app-profile-overlay>
</ng-template>
