import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { environment } from '@environments/environment';
import { Angle, AngleMode, RelatedStory } from '@models/ncx/angle';
import { PERMISSIONS, User } from '@models/users';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { CSSThemeService } from '@services/css-theme.service';
import { NavigationService } from '@services/navigation-service';
import { PermissionsService } from '@services/profile/permissions.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-angle-landing',
  templateUrl: './angle-landing.component.html',
  styleUrls: ['./angle-landing.component.scss']
})
export class AngleLandingComponent implements OnInit, OnDestroy, AfterViewChecked {

  // Fetching Angle Details by Angle Id
  angleId!: string;

  // Get URL for Angle Details
  angleURL = environment.getAngleApi;

  // API Loader
  isLoaded = false;

  // Angle Details based on route Angle Id
  angleDetails: Angle = {} as Angle;

  // Filter Drawer
  filterDrawerOpen = false;

  // Angle Title
  angleTitle = '';

  // Related Story Details
  relatedStories: RelatedStory[] = [];

  // Story Team Members
  collaboratorsDetails: User[] = [] as User[];

  // Tab Menu Selection Index
  tabMenuSelection = 0;

  // Overview Tab Content
  overviewContent = '';

  // Default footer height
  footerHeight = 65;

  // Material Tab Search Form
  postSearchForm!: UntypedFormGroup;

  // Material Tab Filter Form
  postFilterForm!: UntypedFormGroup;

  // Emits when Scroll down the bottom of page
  scrollDownSubject: Subject<boolean> = new Subject<boolean>();

  // Material Tab menu
  materialSelectedTab = 'all';

  // Active tab
  activeTab: number = 0;

  // Material Tab API Loaded
  isMaterialAPILoaded = true;

  // Edit Access
  editAccess: boolean = false;

  // View Access
  viewAccess: boolean = false;

  // Access to view or edit angle details
  canViewAngleDetails: boolean = false;

  // Delete Access
  deleteAccess: boolean = false;

  //To show footer
  showFooter: boolean = false;

  // Angle page have Back history button
  private canGoBack!: boolean;

  // Which completes the Observable when destroy the component
  private destroy$ = new Subject();

  materialTabs = ['all', 'elements', 'standards']

  // Filter Applied or not in Material tab
  isFilterApply = false;

  // Material Tab Filter Editorial Object
  filterEditorialObj = {
    reportable: 'REPORTABLE',
    notReportable: 'NOT REPORTABLE',
    verified: 'VERIFIED',
    publishedAired: 'PUBLISHED/AIRED',
    log: 'LOG',
    greatVideo: 'GREAT VIDEO',
    important: 'IMPORTANT',
    hot: 'HOT',
    standards: 'STANDARDS'
  };

  // Material Tab Filter Legal Object
  filterLegalObj = {
    cleared: 'CLEARED',
    licensed: 'LICENSED',
    limitedLicense: 'LIMITED LICENSE',
    needsLicensing: 'NEEDS LICENSING',
    copyrightRisk: 'COPYRIGHT RISK',
    doNotUse: 'DO NOT USE',
    legal: 'LEGAL'
  };

  // Material Tab Filter Sort By Object
  sortByObj = {
    descending: 'Newest First',
    ascending: 'Oldest First'
  };

  // Material Tab Filter Editorial Object Keys
  filterEditorial = Object.keys(this.filterEditorialObj);

  // Material Tab Filter Legal Object Keys
  filterLegal = Object.keys(this.filterLegalObj);

  // Material Tab Filter Sort By Object Keys
  sortBy = Object.keys(this.sortByObj);

  // default Sort
  defaultSort = 'descending';

  userSettingUrl: string = environment.getSettingsAPIURL + localStorage.getItem('userId') + '/settings';


  //smaller screens
  isSmall = false;

  betaMargin: any = {
    top: -2,
    right: 0,
    bottom: 0,
    left: 0
  };

  // Holds Observable subscriptions
  private subs = new SubSink();

  options = [
    { label: 'All', value: 'All', icon: 'unordered-list', disabled: false },
    { label: 'Elements', value: 'Elements', icon: 'file-image', disabled: false },
    { label: 'Standards', value: 'Standards', icon: 'audit', disabled: false }
  ];

  privateAngleIcon: string = '';

  @ViewChild('tabContent', { static: false }) tabContentElement: ElementRef | any;

  @HostListener('window:resize', ['$event'])
  onResize() {

    this.windowResizeEvent();

  }

  public windowResizeEvent() {

    const tabContent = (this.tabContentElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    const tabContentHeight = window.innerHeight - tabContent?.top - this.footerHeight;

    document.documentElement.style.setProperty('--angleLandingTabBodyHeightInPx', tabContentHeight + 'px');

  }

  /**
   * Default method of the class that is executed when the class is instantiated
   * Setup Dependency Injection
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cService: CommonService,
    private navigationService: NavigationService,
    private location: Location,
    private fb: UntypedFormBuilder,
    private permissions: PermissionsService,
    private tI: TransferInformationComponent,
    private toastService: ToastService,
    private modalService: NzModalService,
    private breakpointService: BreakpointService,
    private changeDetector: ChangeDetectorRef,
    private themeService: CSSThemeService
  ) {

    // This is where the check is done. Make sure to do this
    // here in the constructor, otherwise `getCurrentNavigation()`
    // will return null.
    this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);

  }

  /**
   * ngOnInit Angular Life cycle
   * Angular has initialized all data-bound properties of a directive
   */
  ngOnInit(): void {

    this.isLoaded = false;

    /**
     * below code can be referred if the icon in nz-empty has to be changed in future NRT-12287
     * in html file --- [nzNotFoundImage]="privateAngleIcon"
     */
    // this.themeService.theme.subscribe(() => {
    //   const icon = this.themeService.currentTheme == 'dark' ? 'dark' : 'light'
    //   this.privateAngleIcon = `./assets/svg/${icon}-mode-private-angle.svg`;
    // });

    this.angleId = this.route.snapshot.params['angleId'].substr(1);
    this.getAngleDetails();

    this.tI.getUserFunctionAbility().subscribe(() => {

      this.editAccessToAngle();
      this.viewAccessToAngle();
      this.deleteAccessToAngle();

    });

    this.breakpointService.isSmall$.subscribe(res => {

      this.isSmall = res;

    });


    this.postSearchForm = this.fb.group({
      searchBox: [null],
      searchPost: [null]
    });

    this.postFilterForm = this.fb.group(this.formFields());

    this.formValueChanges();

    // Router have standards then we need to change
    if (this.route.snapshot.params['tab'] === 'standards') {

      this.tabMenuSelection = 1;
      this.materialTabChange(2);
      this.checkMaterialFilter();

    }

    this.subs.sink = this.postSearchForm.get('searchBox')?.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.searchMaterialPost();
    });

  }

  /**
   * Reactive Form value changes (postSearchForm, postFilterForm) captured in this function
   */
  formValueChanges(): void {

    this.postFilterForm.valueChanges.pipe(debounceTime(750), takeUntil(this.destroy$)).subscribe(_ => {

      this.checkMaterialFilter();

    });

  }

  /**
   * After view checked angular event - to get the window size at runtime
   */

  ngAfterViewChecked() {

    this.windowResizeEvent();

    this.changeDetector.detectChanges();

  }

  /**
   * This function captured filter Applied or not in Material tab
   */
  checkMaterialFilter() {

    this.isFilterApply = false;

    const postFilterFormValues = JSON.parse(JSON.stringify(this.postFilterForm.getRawValue()));

    if (postFilterFormValues['sortBy'] !== this.defaultSort) {

      this.isFilterApply = true;

    }
    const dates = postFilterFormValues['date'];

    if (dates && Array.isArray(dates) && dates[0] && dates[1]) {

      this.isFilterApply = true;

    }
    delete postFilterFormValues['sortBy'];
    delete postFilterFormValues['date'];
    for (const val of Object.keys(postFilterFormValues)) {

      if (postFilterFormValues[val]) {

        this.isFilterApply = true;

      }

    }

  }

  /**
   * Material Tab default form object
   * @param isStandard {Boolean}
   * default isStandard is false
   */
  formFields(isStandard = false): { [key: string]: any } {

    const fieldObj = {};

    // Editorial/Standards Controls
    for (const val of this.filterEditorial) {

      fieldObj[val] = false;

    }

    // R&C Legal Controls
    for (const val of this.filterLegal) {

      fieldObj[val] = false;

    }

    // If Standard tab selected then value must be true and disabled
    fieldObj['standards'] = { value: isStandard, disabled: isStandard };

    // Sort By Controls
    fieldObj['sortBy'] = this.defaultSort; // Default Sort by is Newest First

    // Date Control
    fieldObj['date'] = null;

    return fieldObj;

  }

  /**
   * Fetching Angle Details based on Angle Id
   */
  getAngleDetails(): void {

    const queryStr = `/${this.angleId}` + '?isPosts=false';

    this.cService.serviceRequestCommon('get', this.angleURL, queryStr).subscribe((angleDetails: any) => {

      this.isLoaded = true;
      if (angleDetails.deleted) {

        this.toastService.createMessage('error', 'Requested angle does not exist. Redirecting to stories dashboard');
        setTimeout(() => {

          this.router.navigate(['ncx/stories-dashboard']);

        }, 500);

      } else {

        this.setAngleDetails(angleDetails);

      }

    }, (error) => {

      this.toastService.createMessage('error', error + ' Redirecting to stories dashboard');
      setTimeout(() => {

        this.router.navigate(['ncx/stories-dashboard']);

      }, 500);
      this.isLoaded = true;

    });

  }

  /**
   * Set Angle Details from the API response
   * @param angleDetails {Angle}
   */
  setAngleDetails(angleDetails: Angle): void {

    this.angleDetails = angleDetails;
    this.angleDetails.privacy = this.angleDetails.angleAccess == AngleMode.Private ? true : false;
    //this.angleDetails.relatedStories[0].autoGeneratedStoryId = 'HardCoded - SA47FL0001';
    this.relatedStories.push(this.angleDetails.relatedStories[0]);
    this.formatCollaboratorsNameToDisplay();
    this.collaboratorsDetails = this.angleDetails.collaboratorsDetails;
    this.overviewContent = this.angleDetails.angleContent;
    this.editAccessToAngle();
    this.viewAccessToAngle();
    this.deleteAccessToAngle();
    this.showOrHideFooter();
    this.navigationService.overridePageTitle(this.angleDetails.angleTitle);

  }

  /*
   *Collaborators name should be displayed as 'First letter of First Name followed by Dot and Last Name'
   */
  formatCollaboratorsNameToDisplay() {

    this.angleDetails.collaboratorsDetails.forEach(collaborator => {

      collaborator.fullName = Common.formatName({ displayName: null, firstName: collaborator.firstName, lastName: collaborator.lastName }, true);

    });

  }

  /**
   * This event is similar to the event editAccessToCollaborators in Linked-Story-Team component
   * User who have edit access to angle will have edit access to collaborators in simple point   *
   * As of now Admins, Standards and Senior Editors have edit access by default. Refer 'Pemission-Matrix' excel. Rights can change at any time
   * Creator and Collaborators have edit access other than the users who have default rights to edit angle
   */
  editAccessToAngle() {

    const collaboratorsIds = this.collaboratorsDetails.map(x => x.userId);

    if (this.angleDetails.privacy) {

      this.editAccess = this.permissions.hasPermissionTo(PERMISSIONS.EDIT_PRIVATE_ANGLE,
        { createUser: this.angleDetails.createdUser ? this.angleDetails.createdUser : {} as User, collaborators: collaboratorsIds });

    } else {

      this.editAccess = this.permissions.hasPermissionTo(PERMISSIONS.EDIT_PUBLIC_ANGLE,
        { createUser: this.angleDetails.createdUser ? this.angleDetails.createdUser : {} as User, collaborators: collaboratorsIds });

    }

  }

  /**
   * By default all users have view access to public angles
   * For private angles, Admins have view access by default. Refer 'Pemission-Matrix' excel. Rights can change at any time
   * Creator and Collaborators have view access other than the users who have default rights to view angle
   */
  viewAccessToAngle() {

    const collaboratorsIds = this.collaboratorsDetails.map(x => x.userId);

    if (this.angleDetails.privacy) {

      this.viewAccess = this.permissions.hasPermissionTo(PERMISSIONS.VIEW_PRIVATE_ANGLE,
        { createUser: this.angleDetails.createdUser ? this.angleDetails.createdUser : {} as User, collaborators: collaboratorsIds });

    } else {

      this.viewAccess = this.permissions.hasPermissionTo(PERMISSIONS.VIEW_PUBLIC_ANGLE);

    }

  }

  deleteAccessToAngle() {

    const collaboratorsIds = this.collaboratorsDetails.map(x => x.userId);

    if (this.angleDetails.privacy) {

      this.deleteAccess = this.permissions.hasPermissionTo(PERMISSIONS.DELETE_PRIVATE_ANGLE,
        { createUser: this.angleDetails.createdUser ? this.angleDetails.createdUser : {} as User, collaborators: collaboratorsIds });

    } else {

      this.deleteAccess = this.permissions.hasPermissionTo(PERMISSIONS.DELETE_PUBLIC_ANGLE,
        { createUser: this.angleDetails.createdUser ? this.angleDetails.createdUser : {} as User, collaborators: collaboratorsIds });

    }

  }

  showOrHideFooter() {

    this.showFooter = this.editAccess || this.deleteAccess;
    this.footerHeight = (this.editAccess || this.deleteAccess) ? 65 : this.viewAccess ? 0 : 25;
    this.windowResizeEvent();

  }

  /**
   * Back Button like browser back button
   */
  backClicked() {

    if (this.canGoBack) {

      // We can safely go back to the previous location as
      // we know it's within our app.
      this.location.back();

    } else {

      // There's no previous navigation.
      // Here we decide where to go. For example, let's say the
      // upper level is the index page, so we go up one level.
      this.router.navigate(['ncx/stories-dashboard']);

    }

  }

  /**
   * Delete Action in Angle
   */
  deleteAngle() {

    const queryStr = `/${this.angleId}`;

    this.isLoaded = false;
    this.cService.serviceRequestCommon('delete', this.angleURL, queryStr).subscribe(() => {

      this.toastService.createMessage('success', 'The angle has been successfully deleted');
      this.router.navigate(['ncx/stories-dashboard']);
      this.isLoaded = true;

    }, () => {

      this.toastService.createMessage('error', 'Error While deleting please try again');
      this.isLoaded = true;

    });

  }

  /**
   * Add New Element Action in Post via Angle
   */
  addNewElement() {

    const storyId = this.angleDetails?.relatedStories[0]?.storyId;

    const angleId = this.angleDetails?.angleId;

    this.router.navigateByUrl(`/ncx/post?storyId=${storyId}&angleId=${angleId}`);

  }

  /**
   * Edit Angle Information
   */
  editAngle() {

    this.router.navigateByUrl('/ncx/angle/:' + this.angleDetails.angleId);

  }

  /**
   * Material Tab Change
   * @param tab {string}
   */
  materialTabChange(tab) {

    const opts = { onlySelf: true, emitEvent: false };

    if (tab === 2) {

      this.postFilterForm.get('standards')?.setValue(true, opts);
      this.postFilterForm.get('standards')?.disable(opts);

    } else {

      const standardFilter = this.postFilterForm.get('standards');

      // If Standard tab to another tab we must clear standards filter and enable the standards filter
      if (standardFilter.disabled) {

        standardFilter?.setValue(false, opts);
        standardFilter?.enable(opts);

      }

    }
    this.checkMaterialFilter();
    this.materialSelectedTab = this.materialTabs[tab];
    this.activeTab = tab;

  }

  /**
   * Material Tab Clear search
   *
   */
  clearMaterialSearch() {

    this.postSearchForm.reset();

  }

  /**
   * Material Tab search Post
   *
   */
  searchMaterialPost() {

    this.postSearchForm.get('searchPost')?.setValue(
      this.postSearchForm.get('searchBox')?.value
    );

  }

  /**
   * Material Tab Toggle Right Sider
   *
   */
  toggleFilterDrawer() {

    this.filterDrawerOpen = !this.filterDrawerOpen;

  }

  /**
   * Material Tab Clear filter
   *
   */
  clearMaterialFilter() {

    const isStandard = this.materialSelectedTab === 'standards';

    this.postFilterForm.reset(this.formFields(isStandard));

  }

  /**
   * Angle Tab Menu Changes
   *
   */
  tabMenuChanges(menuIndex: number) {

    if (menuIndex !== 1) {

      this.filterDrawerOpen = false;

    } else {

      this.materialTabChange(0);

    }

  }

  /**
   * Angle Mobile Tab Changes
   *
   */
  mobileTabChanges() {

    this.tabMenuChanges(this.tabMenuSelection);

  }

  /**
   * Scroll Down Event emitter
   *
   */
  onScrollDown() {

    this.scrollDownSubject.next(true);

  }

  /**
   * Material API Loaded Output Event
   *
   */
  materialAPILoad(isMaterialAPILoaded: boolean) {

    this.isMaterialAPILoaded = isMaterialAPILoaded;

  }

  /**
   * Show the confirm Modal for delete the Angle
   *
   */
  showConfirmModal(): void {

    this.modalService.confirm({
      nzTitle: 'Are you sure you want to delete this angle?',
      nzOkText: 'Delete',
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {

        this.deleteAngle();

      },
      nzOnCancel: () => {

        console.log('Angle Delete operation aborted');

      }
    });

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

  /**
   * ngOnDestroy Angular Life cycle
   * Use for any custom cleanup that needs to occur when the instance is destroyed
   */
  ngOnDestroy(): void {

    //this.destroy$.next()
    this.destroy$.complete();
    this.subs.unsubscribe();
  }

}
