<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<div
   class="create-story"
   *ngIf="!viewPreview">
   <app-standard-header
      title="Back"
      (clickBack)="redirectTo()"></app-standard-header>

   <div *ngIf="!isSmall">
      <div class="create-story-content">
         <div class="story-split center-align-flex">
            <ng-container [ngTemplateOutlet]="createStoryPostAngleTpl"></ng-container>
            <div
               [ngClass]="{ 'create-story-body': true, 'enable-scroll': enableScroll }"
               #bodyElement>
               <ng-container [ngTemplateOutlet]="createStoryTpl"></ng-container>
            </div>
         </div>
         <div class="story-split">
            <ng-container [ngTemplateOutlet]="metaDataTitleTpl"></ng-container>
            <div
               class="create-story-side"
               #sideMetaElement>
               <ng-container [ngTemplateOutlet]="metaDataTpl"></ng-container>
            </div>
         </div>
      </div>
      <ng-container [ngTemplateOutlet]="footerTpl"></ng-container>
   </div>

   <div *ngIf="isSmall">
      <div class="story-small-screen">
         <div class="story-post-angle-btn">
            <ng-container *ngTemplateOutlet="createStoryPostAngleTpl"></ng-container>
         </div>
         <div class="ant-tab-override ant-tab-border-override create-story-content">
            <nz-tabset
               class="tabset"
               [(nzSelectedIndex)]="selectedTab">
               <nz-tab nzTitle="Main Details">
                  <div
                     [ngClass]="{ 'create-story-body': true, 'enable-scroll': enableScroll }"
                     #bodyElement>
                     <ng-container [ngTemplateOutlet]="createStoryTpl"></ng-container>
                  </div>
               </nz-tab>
               <nz-tab nzTitle="Metadata">
                  <div
                     class="create-story-side"
                     #sideMetaElement>
                     <ng-container [ngTemplateOutlet]="metaDataTpl"></ng-container>
                  </div>
               </nz-tab>
            </nz-tabset>
         </div>
      </div>
      <ng-container [ngTemplateOutlet]="footerTpl"></ng-container>
   </div>

   <ng-template #createStoryTpl>
      <form [formGroup]="storyForm">
         <div class="content-section">
            <div class="content">
               <div class="content-label">Status & View</div>
               <div class="status-view-grp-btn">
                  <nz-radio-group
                     class="work-ready"
                     formControlName="state"
                     [nzButtonStyle]="'solid'">
                     <label
                        nz-radio-button
                        nzValue="WORKING"
                        *ngIf="functionAbility.fa_change_story_state">
                        Working
                     </label>
                     <label
                        nz-radio-button
                        nzValue="READY"
                        *ngIf="functionAbility.fa_change_story_state"
                        [nzDisabled]="getUserRole() === userRoles.Journalist">
                        Ready
                     </label>
                  </nz-radio-group>

                  <div class="privacy-group">
                     <div class="privacy-icon-text">
                        <button
                           nz-button
                           class="privacy-icon"
                           nzType="default"
                           [disabled]="
                              (!isNewStory && storyDetails.storyAccess === 'public') ||
                              !functionAbility.fa_create_private_story
                           "
                           (click)="toggleAccessValue()">
                           <i
                              nz-icon
                              [nzType]="storyDetails.storyAccess === 'private' ? 'eye-invisible' : 'eye'"
                              nzTheme="outline"></i>
                        </button>
                     </div>
                     <div class="privacy-icon-text">
                        <nz-select
                           formControlName="access"
                           [nzDisabled]="!isNewStory && storyDetails.storyAccess === 'public'"
                           class="privacy-text">
                           <nz-option
                              nzValue="private"
                              nzLabel="Private"
                              *ngIf="functionAbility.fa_create_private_story"></nz-option>
                           <nz-option
                              nzValue="public"
                              nzLabel="Public"
                              *ngIf="functionAbility.fa_create_public_story"></nz-option>
                        </nz-select>
                     </div>
                  </div>
               </div>

               <div class="content-label">
                  <span class="mandatory">*</span>
                  <span>
                     Story Name
                     <i
                        nz-icon
                        nzType="question-circle"
                        nzTheme="outline"
                        nz-tooltip
                        nzTooltipPlacement="topRight"
                        [nzTooltipTitle]="storyNameToolTipTemplate"
                        class="ml-4"
                        (click)="toggleTooltip()"
                        (mouseover)="showTooltipEvent()"
                        (mouseout)="hideTooltip()"
                        [nzTooltipTrigger]="tooltipTrigger"
                        [nzTooltipVisible]="showToolTip"></i>
                  </span>
               </div>
               <div class="mb-16">
                  <div
                     [ngClass]="{
                        storyInputInvalidBorder: formControls.title.invalid && (showErrorMsg || showTitleErrorMsg),
                     }"
                     *ngIf="storyDetails && (storyDetails.storyTitle || !storyDetails.storyTitle)">
                     <input
                        required
                        placeholder="Give your story a title"
                        nz-input
                        formControlName="title"
                        (input)="onInput($event.target?.value)"
                        #storyTitle />
                     <div
                        *ngIf="formControls.title.invalid && (showErrorMsg || showTitleErrorMsg)"
                        class="alert alert-danger">
                        <div
                           class="errorMessage"
                           *ngIf="formControls.title.errors?.required">
                           <span>Please add a title to your story.</span>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="grid-container">
                  <div class="grid-item">
                     <div class="content-label">
                        <span class="mandatory">*</span>
                        <span>
                           Topic
                           <i
                              nz-icon
                              nzType="question-circle"
                              nzTheme="outline"
                              nz-tooltip
                              nzTooltipPlacement="topRight"
                              [nzTooltipTitle]="topicToolTipTemplate"
                              class="mr-l-4"
                              (click)="toggleTopicTooltip()"
                              (mouseover)="showTopicTooltipEvent()"
                              (mouseout)="hideTopicTooltip()"
                              [nzTooltipTrigger]="tooltipTrigger"
                              [nzTooltipVisible]="showTopicToolTip"></i>
                        </span>
                     </div>

                     <app-additional-topic-new
                        formGroupName="topicForm"
                        [assignedTopics]="storyDetails.topicDetails"
                        (addedTopics)="attachTopicsToStory($event)"
                        [showErrorMsg]="showErrorMsg"
                        (touchedEvent)="markStoryNameTouchedIfInvalid()"
                        (dropdownOpenChangeEvent)="topicDropdownOpenChangeEvent($event)"></app-additional-topic-new>
                  </div>
                  <div class="grid-item">
                     <div class="content-label">
                        <span class="mandatory">*</span>
                        <span>
                           Subject
                           <i
                              nz-icon
                              nzType="question-circle"
                              nzTheme="outline"
                              nz-tooltip
                              nzTooltipPlacement="topRight"
                              [nzTooltipTitle]="subjectToolTipTemplate"
                              class="mr-l-4"
                              (click)="toggleSubjectTooltip()"
                              (mouseover)="showSubjectTooltipEvent()"
                              (mouseout)="hideSubjectTooltip()"
                              [nzTooltipTrigger]="tooltipTrigger"
                              [nzTooltipVisible]="showSubjectToolTip"></i>
                        </span>
                     </div>

                     <div
                        [ngClass]="{
                           storyInputInvalidBorder: formControls.subject.invalid && showErrorMsg,
                        }">
                        <nz-select
                           class="subjectSelect"
                           formControlName="subject"
                           (nzFocus)="markStoryNameTopicTouchedIfInvalid()"
                           [nzLoading]="!isSubjectLoaded"
                           nzMode="multiple"
                           [(ngModel)]="tagValue"
                           (ngModelChange)="onTagValueChange($event)"
                           [nzMaxTagCount]="1"
                           [nzAllowClear]="true"
                           [nzShowArrow]="true"
                           nzPlaceHolder="Select Subject"
                           [nzDisabled]="
                              !(formControls.title.value.length > 2 && isSubjectLoaded) ||
                              (formControls.subject.value.length > 0 && this.storyDetails.isStoryPublished)
                           ">
                           <nz-option
                              *ngFor="let subject of subjectsForTheTitle"
                              [nzLabel]="subject.entity + ' - ' + subject.entityCode"
                              [nzValue]="subject"></nz-option>
                        </nz-select>
                        <div
                           *ngIf="formControls.subject.invalid && showErrorMsg"
                           class="alert alert-danger">
                           <div
                              class="errorMessage"
                              *ngIf="formControls.subject.errors?.required">
                              <span>Please select a person/location before publishing the story.</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="mt-16 content-label">
                  <span class="mandatory">*</span>
                  Add Description
               </div>

               <div
                  class="rteOffset"
                  tabindex="0">
                  <app-rteditor
                     [rteValue]="rteValue"
                     [isEmitted]="isEmitted"
                     [googleDocs]="googleDocs"
                     rteHeight="150"
                     [input]="'storyDesc'"
                     (rteFocus)="handleRTEFocus()"
                     (rteUpdated)="getRTEData($event)"
                     (rtePasteContent)="getRTEPastedData($event)"
                     (gFlag)="checkFlag($event)"></app-rteditor>
                  <div
                     *ngIf="formControls.description.invalid && showErrorMsg"
                     class="alert alert-danger">
                     <div
                        class="errorMessage"
                        *ngIf="formControls.description.errors?.required">
                        <span>Please add a description to your story.</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </form>
   </ng-template>

   <ng-template #createStoryPostAngleTpl>
      <div class="story-post-btn">
         <app-content-types contentType="Story"></app-content-types>
      </div>
   </ng-template>

   <ng-template #metaDataTpl>
      <div class="metadata">
         <div>
            <app-tags-new
               [tagContent]="tagValues"
               [isGenerateTags]="storyDetails.isGenerateTags"
               [title]="storyDetails.storyTitle"
               [description]="rteValue"
               (tagsUpdated)="getTagsData($event)"
               (generateFlag)="toggleGenerateFlag($event)"></app-tags-new>

            <div *ngIf="userInfoDetails.role !== userRoles.Journalist">
               <nz-collapse
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="slackHeader"
                     nzActive>
                     <div class="example-input">
                        <nz-input-group [nzSuffix]="suffixTemplate">
                           <input
                              placeholder="Enter Slack Channel Name Here"
                              nz-input
                              [(ngModel)]="slackName"
                              required
                              pattern="[a-zA-Z0-9\s]+"
                              #slackChannelName="ngModel"
                              (keypress)="AddSlackChannelOnEnterClick($event)"
                              #searchGroupTitleText />
                        </nz-input-group>
                        <ng-template #suffixTemplate>
                           <i
                              class="cursor-pointer add-icon"
                              nz-icon
                              nzType="plus-circle"
                              nzTheme="outline"
                              (click)="AddSlackChannelOnBtnClick()"></i>
                        </ng-template>
                     </div>

                     <div
                        class="show-slack"
                        *ngIf="slackChannelNames.length > 0">
                        <ng-container *ngFor="let slack of slackChannelNames; let i = index">
                           <span class="display-slack">
                              <span class="slack-text">#{{ slack }}</span>
                              <span
                                 nz-icon
                                 nzType="close"
                                 nzTheme="outline"
                                 (click)="removeTag(slack)"
                                 class="slack-close"></span>
                           </span>
                        </ng-container>
                     </div>
                  </nz-collapse-panel>
               </nz-collapse>
            </div>
         </div>
      </div>
   </ng-template>

   <ng-template #slackHeader>
      <i
         nz-icon
         nzType="slack"></i>
      Slack Integration
   </ng-template>

   <ng-template #metaDataTitleTpl>
      <div class="meta-data-title"><span class="title">Metadata</span></div>
   </ng-template>
</div>

<ng-template #footerTpl>
   <nz-footer class="page-footer">
      <button
         class="cancel-btn"
         nz-button
         [nzShape]="windowWidth >= 992 ? 'round' : 'circle'"
         nzType="danger"
         (click)="viewPreview ? redirectToEdit() : redirectTo()">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
         <span *ngIf="windowWidth >= 992">Cancel</span>
      </button>
      <button
         *ngIf="functionAbility.fa_create_draft && !viewPreview && !storyDetails.isStoryPublished"
         class="align-right-btn"
         nz-button
         [nzShape]="windowWidth >= 992 ? 'round' : 'circle'"
         nzType="default"
         (click)="draft()">
         <i
            nz-icon
            nzType="save"
            nzTheme="outline"></i>
         <span *ngIf="windowWidth >= 992">Save Draft</span>
      </button>
      <button
         *ngIf="!viewPreview"
         class="preview-btn"
         [ngClass]="{
            'align-right-btn': !(functionAbility.fa_create_draft && !viewPreview && !storyDetails.isStoryPublished),
         }"
         nz-button
         [nzShape]="windowWidth >= 992 ? 'round' : 'circle'"
         nzType="default"
         (click)="showPreview()">
         <i
            nz-icon
            nzType="expand"
            nzTheme="outline"></i>
         <span *ngIf="windowWidth >= 992">Preview</span>
      </button>
      <button
         [ngClass]="{ 'align-right-btn': viewPreview }"
         nz-button
         nzShape="round"
         nzType="primary"
         [disabled]="!storyForm.valid && viewPreview"
         (click)="publish()">
         <i
            nz-icon
            nzType="send"
            nzTheme="outline"></i>
         Publish
      </button>
   </nz-footer>
</ng-template>

<div
   *ngIf="viewPreview"
   class="preview-section">
   <app-standard-header
      title="Back"
      (clickBack)="goBackToCreate()"></app-standard-header>
   <div class="display-story-content">
      <div
         #displayElement
         class="display-story-body center-align-flex">
         <app-detail-story
            [storyDetails]="storyDetails"
            class="display-story-body-details"></app-detail-story>
      </div>
      <ng-container [ngTemplateOutlet]="footerTpl"></ng-container>
   </div>
</div>

<app-google-docs
   [isVisible]="isVisible"
   (docLinkUpdated)="getDocLink($event)"></app-google-docs>

<ng-template #storyNameToolTipTemplate>
   <span class="tool-tip">
      When naming your story, include important details like WHERE the story takes place or WHO is involved. e.g.
      Government opposition leader John Doe arrested at rally in Gotham City
   </span>
</ng-template>

<ng-template #topicToolTipTemplate>
   <span class="tool-tip">
      Please select the topic which best fits your story. You can select multiple, but only the first topic will be
      included in the Story ID.
   </span>
</ng-template>

<ng-template #subjectToolTipTemplate>
   <span class="tool-tip">
      Subjects are based on WHO or WHERE from the story name. If no names or locations are identified in story name,
      Subject will default to MISC. To generate new Subjects, add names or locations to your story name.
   </span>
</ng-template>

<!-- Please do not remove this template or element, as it is required for updating Google Docs links in the RT Editor. -->
<ng-container [ngTemplateOutlet]="googleDocsTpl"></ng-container>
<ng-template #googleDocsTpl>
   <div id="googleDocsDummyElement"></div>
</ng-template>
