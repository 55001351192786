<div class="attachmentsElements">
   <div nz-row>
      <div
         nz-col
         nzXs="24"
         class="attachmentsTitle">
         <span>{{ attachmentTitle }}</span>
      </div>
   </div>
   <div class="attachmentsDetails clearfix">
      <nz-upload
         nzType="drag"
         [nzMultiple]="true"
         [nzLimit]="25"
         nzListType="picture"
         [nzDisabled]="disbaleUpload"
         (nzChange)="handleChange($event)"
         [(nzFileList)]="fileList"
         [nzCustomRequest]="customReq"
         [nzHeaders]="setMediaUploadHeaders"
         [nzBeforeUpload]="beforeUpload"
         class="upload-list-inline"
         [nzShowUploadList]="showIcons">
         <p class="ant-upload-drag-icon">
            <i
               nz-icon
               nzType="inbox"
               class="attachIcon"></i>
         </p>

         <p class="ant-upload-text">Click or drag file(s) to this area to upload</p>
         <p class="ant-upload-hint">
            NOTE: Max file size is 307.2MB. You may attach up to 25 files, but their combined size must not exceed
            307.2MB
         </p>
      </nz-upload>
   </div>

   <div
      class="elements"
      *ngIf="!hideElements && !saveDiscussionId">
      <div nz-row>
         <div
            nz-col
            nzXs="24"
            class="elementDetails">
            <span>Element Details</span>
         </div>
      </div>

      <div
         nz-row
         class="linkToSource">
         <div
            nz-col
            nzXs="24"
            class="linkToSourceLabel">
            <span>Link to Source</span>
         </div>
         <div
            nz-col
            nzXs="24">
            <nz-input-group class="input-group">
               <input
                  nz-input
                  placeholder="Enter Link to Source"
                  [(ngModel)]="attachmentDetails.linkToSource"
                  (ngModelChange)="onChange($event, 'linkToSource')" />
            </nz-input-group>
         </div>
      </div>

      <div class="pd-8"></div>

      <div
         nz-row
         class="CreditAndCleared">
         <div
            nz-col
            [nzXXl]="6"
            [nzXl]="8"
            [nzLg]="24"
            [nzMd]="24"
            [nzSm]="24"
            [nzXs]="24"
            [ngClass]="isXSToLarge ? 'pd-b-16' : 'pd-r-16'">
            <div class="dateOfCapture">
               <div
                  nz-col
                  nzXs="24"
                  class="linkToSourceLabel">
                  <span>Date of Capture</span>
               </div>
               <div
                  nz-col
                  nzXs="24">
                  <nz-date-picker
                     [(ngModel)]="attachmentDetails.captureDate"
                     (ngModelChange)="onChange($event, 'captureDate')"
                     nzPlaceHolder="CaptureDate"
                     style="width: 100%"></nz-date-picker>
               </div>
            </div>
         </div>
         <div
            nz-col
            [nzXXl]="12"
            [nzXl]="16"
            [nzLg]="24"
            [nzMd]="24"
            [nzSm]="24"
            [nzXs]="24"
            [ngClass]="isXSToLarge ? 'pd-b-16' : isXtraLarge ? '' : 'pd-r-8'">
            <div class="mandatoryCredits">
               <div
                  nz-col
                  nzXs="24"
                  class="linkToSourceLabel">
                  <span>Mandatory Credit?</span>
               </div>
               <div
                  nz-col
                  nzXs="24">
                  <nz-input-group
                     nzCompact
                     class="mandatoryCreditOption input-group">
                     <nz-select
                        (ngModelChange)="onCreditChange($event)"
                        [(ngModel)]="attachmentDetails.isMandatoryCredit"
                        class="mandatoryCreditSelect"
                        nzAllowClear
                        nzPlaceHolder="">
                        <nz-option
                           nzLabel="Yes"
                           nzValue="yes"></nz-option>
                        <nz-option
                           nzLabel="No"
                           nzValue="no"></nz-option>
                     </nz-select>
                     <input
                        class="mandatoryCreditInput"
                        [ngClass]="
                           attachmentDetails?.isMandatoryCredit === 'yes' && attachmentDetails?.creditName === ''
                              ? 'red-highlight-border'
                              : 'no-border'
                        "
                        #creditName
                        id="for-testing"
                        type="text"
                        nz-input
                        (ngModelChange)="onCreditNameChange($event)"
                        placeholder="Enter Credit Name"
                        [(ngModel)]="attachmentDetails.creditName" />
                  </nz-input-group>
                  <div
                     class="errorMessage"
                     *ngIf="attachmentDetails?.isMandatoryCredit === 'yes' && attachmentDetails?.creditName === ''">
                     Please Provide Credit Name
                  </div>
               </div>
            </div>
         </div>

         <div
            nz-col
            [nzXXl]="6"
            [nzXl]="24"
            [nzLg]="24"
            [nzMd]="24"
            [nzSm]="24"
            [nzXs]="24"
            [ngClass]="isXSToLarge ? '' : isXtraLarge ? 'pd-t-16' : 'pd-l-8'">
            <div class="materialsClearedFor">
               <div
                  nz-col
                  nzXs="24"
                  class="linkToSourceLabel">
                  <span>Cleared for NBCU Partners?</span>
               </div>

               <div
                  nz-col
                  nzXs="24">
                  <nz-radio-group
                     class="materialsClearedForSelect"
                     (ngModelChange)="materialsClearedForChange($event)"
                     [(ngModel)]="attachmentDetails.clearanceStatus">
                     <label
                        nz-radio-button
                        nzValue="yes">
                        Yes
                     </label>
                     <label
                        nz-radio-button
                        nzValue="no">
                        No
                     </label>
                  </nz-radio-group>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
