import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { FollowStoryUpdateEvent } from '@models/ncx/followers';
import { Stories } from '@models/ncx/story';
import { User } from '@models/users';
import { AuthService } from '@services/auth/auth.service';
import { BreakpointService } from '@services/breakpoint.service';
import { Common } from '@utilities/common';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';

@Component({
  selector: 'app-following',
  templateUrl: './following.component.html',
  styleUrls: ['./following.component.scss']
})
export class FollowingComponent implements OnInit, AfterViewInit {

  isLoaded = false;

  storyId;

  storyFollowers;

  storyDetails = null as Stories;

  getApiURL;

  postFlag = false;

  isSticky = false;

  followerApi;

  isFilter = false;

  sortOrderSelected = 'A - Z';

  searchFollowerText: any;

  isStoryLanding = false;

  isStoriesLandingVisible = false;

  isinfocenterVisible = false;

  followerFilter: { order: string; value: any; };

  searchInput = '';

  storyTitle = '';

  @ViewChild('storyDetailsContent') storyDetailsContent: ElementRef;

  customPopOver: boolean;

  showpop: boolean;

  profileInDescription: User = {} as User;

  constructor(
    private router: ActivatedRoute,
    private cService: CommonService,
    private breakpointService: BreakpointService,
    private toastService: ToastService,
    private reRoute: Router,
    private storiesSiderStatus: TransferInformationComponent,
    private authService: AuthService,
  ) {

    this.storiesSiderStatus.currentstoriesSiderStatus.subscribe(isCollapsed => {

      this.isStoriesLandingVisible = !isCollapsed;

    });

  }

  ngOnInit() {

    this.getApiURL = environment.getStoriesAPI;
    this.followerApi = environment.storyFollower;
    this.isStoryLanding = true;
    this.router.params.subscribe((params: Params) => {

      this.setStoryId(params);

    });

    this.isStoriesLandingVisible = !this.breakpointService.isMobile.value;

    // Close the right-hand drawer if the browser is minimized to handset and the drawer is open
    this.breakpointService.isMobile.subscribe((isMobile: boolean) => {

      if (isMobile && this.isStoriesLandingVisible) {

        this.isStoriesLandingVisible = false;

      }

    });

  }

  ngAfterViewInit() {
    this.setEventListener();
  }

  setStoryId(params) {

    if (params && params.storyId) {

      this.storyId = (params.isFollow ? params.storyId : params.storyId.substr(1));
      if (this.storyId != null || this.storyId !== '') {

        this.getStoryDetails(this.storyId);

      }

    }

  }

  toggleStoryDetail(): void {

    this.isStoriesLandingVisible = !this.isStoriesLandingVisible;
    this.isFilter = false;

  }

  viewStory(content) {

    this.reRoute.navigate(['ncx/view-story/:' + content.storyId]);

  }

  closeDrawer() {

    this.isStoriesLandingVisible = false;

  }

  openInfoCenter() {

    this.isinfocenterVisible = true;

  }

  closeInfoCenter() {

    this.isinfocenterVisible = false;

  }

  changeSortOrder(order) {

    if (order === 'asc') {

      this.sortOrderSelected = 'A - Z';

    } else if (order === 'desc') {

      this.sortOrderSelected = 'Z - A';

    }

    this.searchFollowerText = (this.searchFollowerText === null || this.searchFollowerText === undefined) ? '' : this.searchFollowerText;

    this.followerFilter = { order: (this.sortOrderSelected === 'A - Z') ? 'asc' : 'desc', value: this.searchFollowerText };

  }

  filterFollowers(event, value, type) {

    if (type === 'click') {

      this.followerFilter = { order: (this.sortOrderSelected === 'A - Z') ? 'asc' : 'desc', value: this.searchInput };
      return;

    }
    if (event.keyCode === 13) {

      this.searchFollowerText = event.target.value;
      this.followerFilter = { order: (this.sortOrderSelected === 'A - Z') ? 'asc' : 'desc', value: this.searchFollowerText };

    }
    setTimeout(() => {

      if (this.searchInput === '') {

        this.searchFollowerText = '';
        this.followerFilter = { order: (this.sortOrderSelected === 'A - Z') ? 'asc' : 'desc', value: '' };

      }

    }, 200);

  }

  updateFollowers(status: FollowStoryUpdateEvent) {

    this.storyFollowers = status.users;
    this.storyDetails.storyFollowerCount = status.data.storyFollowerCount;
    this.storyDetails.isUserFollowing = status.type;

  }

  getStoryDetails(storyId) {

    this.isLoaded = false;
    const queryStr = `/${storyId}` + '?isPosts=' + this.postFlag;

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      this.storyDetails = JSON.parse(JSON.stringify(res));
      this.storyFollowers = res.storyFollower;
      this.storyTitle = res.storyTitle;

      this.setEventListener();

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');
      this.isStoriesLandingVisible = false;

    }).add(() => {

      this.isLoaded = true;

    });

  }

  checkScreen() {

    if (window.innerWidth >= 280 && window.innerWidth <= 1024) {

      return {
        'overflow-y': 'overlay'
      };

    }

  }

  /**
   * Append jwttokens to any files embedded in the story content
   *
   */
  get storyContent(): string {

    const token = localStorage.getItem('ncxjwttoken');

    return (this.storyDetails.storyContent || '').replace(/ncx-gateway\/api\/v1([^"]+)/i, (str, match) => {

      return !match.includes('ncxjwttoken=') ? `${str}?ncxjwttoken=${token}` : str;

    });

  }

  get isSortingAscending(): boolean {

    return this.sortOrderSelected === 'A - Z';

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

  /**
   *
   * Listens for click events on HTML content in Story Description
   */
  handleAnchorClick(event) {

    this.customPopOver = false;
    this.showpop = true;
    if (event && event.srcElement && event.srcElement.id && event.srcElement.id.startsWith('user-')) {

      const userId = event.srcElement.id.split('-')[1];

      this.profileInDescription.userId = userId;

    } else {

      return;

    }
    setTimeout(() => {

      const element: HTMLElement = document.getElementById('triggerClick') as HTMLElement;

      element.style.left = (event.layerX + 25) + 'px';
      element.style.top = (event.layerY + 110) + 'px';
      this.customPopOver = true;

      element.click();

    });

  }

  setEventListener() {
    setTimeout(() => {

      if (!Common.isInputHasValidTextContent(this.storyDetails.storyContent)) {

        this.storyDetails.storyContent = '';

      } else {

        this.storyDetails.storyContent = this.authService.addJWTTokenToLink(this.storyDetails.storyContent, 'img');

      } //JWT Token has to be added to the
      // img S3 link to download the file from S3

      /**
       * .fr-deletable class added to the description if a profile is added to the froala editor - @username
       */
      const part = this.storyDetailsContent?.nativeElement?.querySelectorAll('.fr-deletable');

      part.forEach((anchor: HTMLAnchorElement) => {

        anchor.addEventListener('mouseenter', this.handleAnchorClick.bind(this));
        anchor.addEventListener('click', this.handleAnchorClick.bind(this));

      });

    }, 500);
  }

}
