import { Injectable } from '@angular/core';

declare let gtag: (...args: any) => void;

@Injectable({
  providedIn: 'root',
})

export class GoogleAnalyticsEventService {

  constructor() { }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null,
  ) {

    gtag('event', eventName, {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
    });

  }

  loadGoogleAnalytics(trackingID: string): void {

    const gaScript = document.createElement('script');

    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);
    const gaScript2 = document.createElement('script');

    // tslint:disable-next-line: max-line-length
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;
    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);

  }

  trackGlobalSearch(query: string): void {

    console.log('trackGlobalSearch' + query);
    gtag('event', 'search', { search_term: query });

  }

  trackUserSearchAction(searchString: string, difference: number): void {

    const userId = localStorage.getItem('ssoId');

    console.log('trackUserSearchAction:', searchString);
    gtag('event', searchString, {

      // tslint:disable-next-line:object-literal-key-quotes
      'event_category': 'User Search Action',

      // tslint:disable-next-line:object-literal-key-quotes
      'event_label': userId,

      // tslint:disable-next-line:object-literal-key-quotes
      'value': difference,
    });

  }

  // common methods
  trackSelectedContentforPageNumberandIndex(pageOrIndex: number, searchString: string, searchSelection: string, category: string): void {

    console.log('trackSelectedContentforPageNumberandIndex-searchSelection:', searchSelection);
    console.log('trackSelectedContentforPageNumberandIndex-pageOrIndex:', pageOrIndex);
    console.log('trackSelectedContentforPageNumberandIndex-category:', category);
    gtag('event', searchString, {

      // tslint:disable-next-line:object-literal-key-quotes
      'event_category': category,

      // tslint:disable-next-line:object-literal-key-quotes
      'event_label': searchSelection,

      // tslint:disable-next-line:object-literal-key-quotes
      'value': pageOrIndex,
    });

  }

  trackSelectedContentFilter(searchString: string, filterSelection: string, countValue: number, category: string): void {

    console.log('trackSelectedContentFilter-string:', filterSelection);
    gtag('event', searchString, {

      // tslint:disable-next-line:object-literal-key-quotes
      'event_category': category,

      // tslint:disable-next-line:object-literal-key-quotes
      'event_label': filterSelection,

      // tslint:disable-next-line:object-literal-key-quotes
      'value': countValue,
    });

  }

  trackContentResultsCount(searchString: string, contentCount: number, label: string, category: string): void {

    console.log('trackContentResultsCount-number:', contentCount);
    gtag('event', searchString, {

      // tslint:disable-next-line:object-literal-key-quotes
      'event_category': category,

      // tslint:disable-next-line:object-literal-key-quotes
      'event_label': label,

      // tslint:disable-next-line:object-literal-key-quotes
      'value': contentCount,
    });

  }

  trackTrendingAction(action: string): void {

    const userId = localStorage.getItem('ssoId');

    gtag('event', action, {

      // tslint:disable-next-line:object-literal-key-quotes
      'event_category': 'Trending',

      // tslint:disable-next-line:object-literal-key-quotes
      'event_label': userId,

      // tslint:disable-next-line:object-literal-key-quotes
      'value': 1,
    });

  }

  trackRecencyRelevancySearchAction(searchString: string, eventType: string, resultVal: number): void {

    const userId = localStorage.getItem('ssoId');

    gtag('event', searchString, {

      // tslint:disable-next-line:object-literal-key-quotes
      'event_category': eventType,

      // tslint:disable-next-line:object-literal-key-quotes
      'event_label': userId,

      // tslint:disable-next-line:object-literal-key-quotes
      'value': resultVal,
    });

  }

  trackElementClickAction(action: string, storyId: string): void {

    const userId = localStorage.getItem('ssoId');

    gtag('event', storyId, {

      // tslint:disable-next-line:object-literal-key-quotes
      'event_category': action,

      // tslint:disable-next-line:object-literal-key-quotes
      'event_label': userId,

      // tslint:disable-next-line:object-literal-key-quotes
      'value': 1,
    });

  }

  sendEvent(action: string, eventCategory: string, eventLabel: string, eventValue: number): void {
    const userId = localStorage.getItem('ssoId');
    gtag('event', action, {

      // tslint:disable-next-line:object-literal-key-quotes
      'event_category': eventCategory,
      // tslint:disable-next-line:object-literal-key-quotes
      'event_label': eventLabel,
      // tslint:disable-next-line:object-literal-key-quotes
      'value': eventValue
    });
  }
}
