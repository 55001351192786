import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {
  radioValue = 'following';

  name = 'Topics';

  followerApi;

  isFollowing = false;

  connectionsApi;

  followersUsers;

  followingUsers;

  connections;

  users;

  isLoaded;

  screenType;

  currentSelectedTab: any = 'following';

  followingUserIds: any = [];

  followerUserIds: any = [];
  pageIndex: number;
  pageStartCount: any;
  pageLength: any;
  isScrolled: boolean;

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {

    this.screenType = 'connections';
    this.followerApi = environment.getProfileAPIURL;
    this.pageStartCount = 1;
    this.pageLength = 20;
    this.connectionsApi = environment.getAllTopicsAPIURL;
    console.log('connectionsApi', this.connectionsApi);
    this.getTopicsDetails();

  }

  onScrollDown() {
    this.pageStartCount += this.pageLength;
    this.isScrolled = true;
    this.getTopicsDetails();
  }

  getTopicsDetails() {

    this.isLoaded = false;
    let queryStr = `?excludeDeleted=true&pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`;
    this.cService.serviceRequestCommon('get', this.connectionsApi, queryStr).subscribe((res: any) => {

      if (!this.isScrolled) {
        this.followingUsers = [...res.topics];
      } else {
        if (res.topics && res.topics.length) {

          this.followingUsers = [...this.followingUsers, ...res.topics];

        }
      }
      this.radioValue = this.currentSelectedTab;
      if (this.radioValue === 'following') {

        this.users = this.followingUsers;

      } else {

        this.users = this.followersUsers;

      }
      this.isLoaded = true;
      this.isScrolled = false;
    }, () => {

      this.isLoaded = true;
      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  checkFollowing(item) {

    let check = false;

    if (item && item.length) {

      const userId = localStorage.getItem('userId');

      item.forEach(element => {

        if (element.toString() === userId) {

          check = true;

        }

      });

    }
    if (check) {

      return true;

    } else {

      return false;

    }

  }

  followUser(index, targetTopicId, type) {

    let response;

    const userId = localStorage.getItem('userId');

    const queryStr = `/${targetTopicId}`;

    if (type === 'follow') {

      response = {
        topicName: index.topicName,
        updateUserId: userId,
        fields: [
          {
            fieldName: 'topicFollowers',
            fieldValues: [userId],
            fieldToBeRemoved: false
          }
        ]
      };

    }
    if (type === 'unfollow') {

      response = {
        topicName: index.topicName,
        updateUserId: userId,
        fields: [
          {
            fieldName: 'topicFollowers',
            fieldValues: [userId],
            fieldToBeRemoved: true
          }
        ]
      };

    }

    this.cService.serviceRequestCommon('put', environment.updateTopicAPIURL, queryStr, response).subscribe((data: any) => {

      console.log('Follow Response', data);
      if (type === 'follow') {

        this.toastService.createMessage('success', 'You have followed the Topic');

      }
      if (type === 'unfollow') {

        this.toastService.createMessage('success', 'You have unfollowed the Topic');

      }
     const topicRecord = this.users.findIndex(topic => topic.topicId === data.topicId);
     if(topicRecord)
      this.users[topicRecord].topicFollowers = data.topicFollowers;
     if(topicRecord === 0) 
      this.users[0].topicFollowers = data.topicFollowers;
      // this.isFollowing = false;

    }, () => {

      this.toastService.createMessage('error', 'Error saving data. Please try again');

    });

  }

}
