import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DebounceService {

  // Certainly! The debounce function is a utility function that ensures a given function (func) is not called too frequently. 
  // Instead, it delays the execution of the function until after a specified wait time (wait) has passed since the last time the debounced function was invoked. 
  // This is particularly useful for events that can fire rapidly, such as window resize or keypress events.

  private debounceTimeout: any;

  constructor(private ngZone: NgZone) { }

  // debounce(func: Function, wait: number) {
  //   let timeout: any
  //   return function (...args: any[]) {
  //     clearTimeout(timeout);
  //     timeout = setTimeout(() => func.apply(this, args), wait);
  //   };
  // }

  debounce(callback: () => void, delay: number = 200): void {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.ngZone.run(() => {
        callback();
      });
    }, delay);
  }
}
