<div class='linked-angle-section'>
  <div class='link-angle'>
    <div class='link-angle-label'>
      <span>
        Add to Angle
        <i
          nz-icon
          nzType='question-circle'
          nzTheme='outline'
          nz-tooltip
          nzTooltipPlacement='topRight'
          [nzTooltipTitle]='addToAngleToolTipTemplate'
          class='ml-4'></i>
      </span>
    </div>
    <div [ngClass]="highlightMandatory ? 'highlight-story-border' : 'normal-story-border'">

      <nz-input-group
        [nzSuffix]='suffixIconSearch'
        class='input-group'>
        <input
          placeholder='Search Angle Name or ID'
          nz-input
          class='search-text'
          [(ngModel)]='angleValue'
          (keydown)='searchAngle(angleInput.value, $event)'
          (ngModelChange)='angleMatch($event)'
          [nzAutocomplete]='auto'
          #angleInput />
      </nz-input-group>

      <ng-template #suffixIconSearch>
        <span
          nz-icon
          nzType='search'
          class='search-icon'></span>
      </ng-template>

      <nz-autocomplete #auto>
        <ng-container>
          <span *ngFor='let angle of filteredAngleOptions'>
            <nz-auto-option [nzValue]='angle.angleId'>
              <ng-container
                [ngTemplateOutlet]='invisibleIconTemplate'
                [ngTemplateOutletContext]='{
                           angle: angle,
                        }'></ng-container>
              <span
                [innerHTML]="
                           (angle.angleId ? '(' + angle.angleId + ')' + ' ' + angle.angleTitle : angle.angleTitle)
                              | safeHtml
                        "></span>
            </nz-auto-option>
          </span>
        </ng-container>
      </nz-autocomplete>

      <app-loader-component
        loaderType='SECTION_LOADER'
        [isLoaded]='!loading'></app-loader-component>

    </div>
    <div
      *ngIf='highlightMandatory'
      class='mandatory-text'>
      <span>Angle/Story is required</span>
    </div>
  </div>

  <div
    *ngIf='relatedAngles'
    class='linked-angles'
    [class.bottomPad]='relatedAngles.length > 0'>
    <ng-container *ngFor='let angle of relatedAngles'>
      <div class='angle'>
        <span
          class='eye-invisible-icon'
          *ngIf="angle.angleAccess === 'private'">
          <span
            nz-icon
            nzType='eye-invisible'
            nzTheme='outline'></span>
        </span>
        <div class='angle-id-title'>
          <span
            class='angle-title'
            [innerHTML]='angle.angleTitle | safeHtml'></span>
          <span class='angle-id'>({{ angle.angleId }})</span>
        </div>
        <button
          nz-button
          nzType='default'
          class='angle-delete'
          (click)='deleteRelatedAngles(angle.angleId)'>
          <span
            nz-icon
            nzType='delete'
            nzTheme='outline'
            class='del-color'></span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #addToAngleToolTipTemplate>
  <span class='tool-tip'>Search by angle name or ID. You can add this post to multiple angles</span>
</ng-template>

<ng-template
  #invisibleIconTemplate
  let-angle='angle'>
  <span
    class='eye-invisible-icon'
    *ngIf="angle.angleAccess === 'private'">
    <span
      nz-icon
      nzType='eye-invisible'
      nzTheme='outline'></span>
  </span>
</ng-template>

<ng-template #noAnglesFoundTemplate>
  <nz-auto-option [nzValue]=''>No Angles Found</nz-auto-option>
</ng-template>
