import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { ViewAttachmentsComponent } from '@components/view-attachments/view-attachments.component';
import { AppConstants } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { AddAngleComponent } from '@modals/ncx/add-angle/add-angle.component';
import { Post, PostAttachment, RelatedStory } from '@models/ncx/post';
import { IFunctionAbility, PERMISSIONS, User } from '@models/users';
import { AuthManager } from '@services/auth/app.auth.manager';
import { AuthService } from '@services/auth/auth.service';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { DataService } from '@services/data.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { NavigationService } from '@services/navigation-service';
import { PermissionsService } from '@services/profile/permissions.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-view-posts',
  templateUrl: './view-posts.component.html',
  styleUrls: ['./view-posts.component.scss']
})

export class ViewPostsComponent implements OnInit, AfterViewChecked {

  isLoaded = false;

  isMovePostVisible = false;

  isShareStoryVisible = false;

  isMetadataCollapsed = false;

  showPostHeader = true;

  sharePostId: any;

  getApiURL: string;

  postId: number;

  postDetails = null as Post;

  user = {
    author: 'Han Solo',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
  };

  showpop = false;

  customPopOver = false;

  profileInDescription: User = {} as User;

  inputValue = '';

  relatedStoryTitles: any = [];

  relatedAngles: any = [];

  listofStories: any = [];

  shareMessage;

  filterList: any;

  livePostId;

  isPinnedOnTop: boolean;

  originalPostDetails = {} as Post;

  prevUrl: string;

  archiveUrl: string = '';

  urlCheckFlag = false;

  loggedInUserId: number;

  markAs: any;

  isMediaCentralDetails = false;

  getSendToMediaCentralDetails = [];

  // topics = '';

  functionAbility = {} as IFunctionAbility;

  userInfoDetails;

  canEditPost = false;

  canAddAngleToPost = false;

  postDetailsTabBodyHeight = 0;

  metadataTabBodyHeight = 0;

  footerHeight = 61;

  windowWidth: any;

  showBanner: boolean = true;

  @ViewChild('postcontent') postcontent: ElementRef;

  @ViewChild('pageHeader', { static: false }) pageHeaderElement: ElementRef | any;

  @ViewChild('postDetailsBody', { static: false }) postDetailsBodyElement: ElementRef | any;

  @ViewChild('metadataBody', { static: false }) metadataBodyElement: ElementRef | any;

  @ViewChild('footer', { static: false }) footerElement: ElementRef | any;

  @ViewChild('closeIconTpl', { static: false }) closeIconTpl: any;

  @ViewChild(ViewAttachmentsComponent) viewAttachmentsComponent!: ViewAttachmentsComponent;

  @HostListener('window:resize', ['$event'])
  onResize() {

    this.windowResizeEvent();

  }

  public readonly Common = Common;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointService: BreakpointService,
    private navigationService: NavigationService,
    private http: HttpClient,
    private cService: CommonService,
    private reRoute: Router,
    private authService: AuthService,
    private toastService: ToastService,
    private tI: TransferInformationComponent,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private authManager: AuthManager,
    private modalService: NzModalService,
    public permissions: PermissionsService,
    private changeDetector: ChangeDetectorRef,
    private dataService: DataService,
    private gaService: GoogleAnalyticsEventService
  ) {

  }

  ngOnInit() {

    this.getApiURL = environment.getPostApi;

    this.loggedInUserId = +localStorage.getItem('userId');

    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });
    this.dataService.setData('');

    this.userInfoDetails = {
      userId: this.tI.userInfoDetails.userId,
      role: this.tI.userInfoDetails.role.toUpperCase()
    };

    this.activatedRoute.params.subscribe((params: Params) => {

      const id = params.postId;

      if (id.match(/:\d+/)) {

        this.postId = +id.substr(1);
        console.log('PostId', this.postId);
        this.getPostDetails(this.postId);
        this.getFilterList();

      } else {

        this.toastService.createMessage('error', 'Invalid Post Id');
        this.reRoute.navigate(['ncx/stories-dashboard']);

      }

    });

    // Drawer is automatically opened on desktop view
    this.isMetadataCollapsed = this.breakpointService.isMobile.value;

    // Automatically close drawer when moving to mobile view
    this.breakpointService.isMobile.subscribe((isMobile) => {

      if (isMobile && !this.isMetadataCollapsed) {

        this.isMetadataCollapsed = true;

      }

    });

  }

  ngAfterViewChecked() {

    this.windowResizeEvent();
    this.changeDetector.detectChanges();

  }

  /**
   * Load post on page load
   *
   */
  getPostDetails(postId: number) {

    this.prevUrl = this.authManager.getPreviousUrl();

    const queryStr = `/${postId}`;

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      console.log(`Post Details: ${this.getApiURL}`, res);

      const post: Post = res as Post;

      this.postDetails = post;
      this.livePostId = post.livePostId;

      if (this.isPostPrivate(post)) {

        this.toastService.createMessage('error', 'You Do Not Have Access to this Post');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/stories-dashboard']);

        }, 500);
        return;

      }

      if (post.isDeleted) {

        this.toastService.createMessage('error', 'Requested Post does not exist redirecting to stories dashboard');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/stories-dashboard']);

        }, 500);
        return;

      }

      // if (post && post.topicDetails) {

      //   this.topics = Object.values(post.topicDetails || []).join(', ');

      // }

      this.originalPostDetails = JSON.parse(JSON.stringify(this.postDetails));
      this.relatedStoryTitles = [...this.postDetails.relatedStories ?? []];
      this.isPinnedOnTop = this.postDetails.isPinnedOnTop;
      this.relatedAngles = [...this.postDetails.relatedAngles ?? []];
      this.navigationService.overridePageTitle(post.postTitle);

      this.sharePostId = this.postDetails.postId;
      if (this.postDetails.postVersionState === 'Archived') {

        this.archiveUrl = '/ncx/view-post/:' + this.postDetails.livePostId;
        if (this.prevUrl.startsWith(AppConstants.local.Url_postVersions)) {

          this.urlCheckFlag = true;

        } else {

          this.urlCheckFlag = false;
          this.reRoute.navigate([this.archiveUrl]);

        }

      }

      if (this.postDetails.postVersionNumber > 1) {

        this.getVersions(this.postDetails.postVersionGroupId);

      }

      this.initPostContentHTML();

      this.isMediaCentralRecord();

      this.canEditPost = this.canEditThePost();

      this.canAddAngleToPost = this.canAddAngleToThePost();

      if (this.postDetails.postVersionNumber === 1 && (null != this.postDetails.editorialStatus && null != this.postDetails.postLegal)) {

        return this.postDetails.updateDateTime;

      } else {

        return this.postDetails.createDateTime;

      }

    }, err => {

      console.error(`Error: ${this.getApiURL}`);
      if (err === 'POST-003') {

        this.toastService.createMessage('error', 'You Do Not Have Access to the Post');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/stories-dashboard']);

        }, 500);

      } else {

        this.toastService.createMessage('error', err);

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  openSharePost(value) {

    this.isShareStoryVisible = true;
    this.shareMessage = value;
    this.gaService.sendEvent('Share Post Click', 'Post Landing Page', '' + this.postDetails.postId, 1);
  }

  cancelSharedContent() {

    this.isShareStoryVisible = false;

  }

  redirectTo(storyId) {

    this.reRoute.navigate(['ncx/landing-story/:' + storyId]);

  }

  toggleSider() {

    this.isMetadataCollapsed = !this.isMetadataCollapsed;

  }

  closeSlider() {

    this.isMetadataCollapsed = true;

  }

  /**
   * Back button click in Header
   * Fix for NRT-14145
   */
  goBack() {
    if (this.postDetails.storyId) {
      this.redirectTo(this.postDetails.storyId);
    }
    else {
      this.router.navigateByUrl('/ncx/stories-dashboard');
    }

  }

  createNewDraftVersionToEdit(createdUserid: number) {

    let dataToSave: Post = {} as Post;

    const queryStr = '';

    dataToSave = this.postDetails; //Create new version from the latest data fetched from DB
    dataToSave.postId = null;
    dataToSave.isPostPublished = false;
    dataToSave.postVersionState = 'Draft';
    dataToSave.postType = 'Update';
    dataToSave.createUserId = createdUserid;
    dataToSave.createUser = this.originalPostDetails.createUser;
    dataToSave.postContent = this.authService.removeJWTTokenFromLink(this.postDetails.postContent, 'img');
    dataToSave.postTopicIds = this.postDetails.topicDetails ? this.postDetails.topicDetails?.map(topic => topic.topicId.toString()) : [];

    this.isLoaded = false;

    this.cService.serviceRequestCommon('post', environment.getPostApi, queryStr, dataToSave).subscribe((res: any) => {

      if (res && res.postId) {

        this.reRoute.navigate(['ncx/post/:' + res.postId]);

      }

    },
      (err: any) => {

        console.error(err);
        this.toastService.createMessage('error', 'Error Loading Data. Please Try Again');

      }).add(() => {

        this.isLoaded = true;

      });

  }

  /**
   * Edit Post
   * if updatedPostId has a value then it means a new live version exist. Copy has to be created from the new live version.
   * if updatedPostId is not passed then it means the version used at the time of load is the latest version. Copy has to be created from that
   * version.
   */
  editPost(updatedPostId: number = 0): void {

    let postId = this.postId;

    if (updatedPostId != 0) {

      postId = updatedPostId;  // get the post details of the new version

    }

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', this.getApiURL, '/' + postId).subscribe((res: any) => {

      console.log('Post Response', res, postId);

      const post = res as Post;

      this.postDetails = post;

      if (post.locked && this.loggedInUserId !== post.lockedByUser?.userId) {

        this.toastService.createMessage('warning', 'You cannot edit a locked Post');

        this.showBanner = true;

      } else {

        if (post.postVersionState === 'Archived') {

          this.toastService.createMessage('warning', 'You cannot edit an Archived Post. Redirecting to Live version');

          this.showBanner = true;

          this.reRoute.navigate(['ncx/view-post/:' + post.livePostId]);

        } else if (post.postVersionState === 'Live') {

          this.createNewDraftVersionToEdit(res.createUser.userId);
          this.dataService.setData('Live');

        } else {

          this.dataService.setData('Draft');
          this.reRoute.navigate(['ncx/post/:' + postId]);

        }

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Delete Post
   */
  deletePost(event: any) {

    this.cService.serviceRequestCommon('get', this.getApiURL, '/' + this.postId).subscribe((res: any) => {

      console.log('Post Response', res, this.postId);

      const post: Post = res as Post;

      if (post.locked && this.loggedInUserId !== post.lockedByUser?.userId) {

        this.toastService.createMessage('warning', 'You cannot edit a locked Post');
        this.postDetails = post;
        this.isLoaded = true;
        return;

      } else {

        if (event === 'true') {

          this.isLoaded = false;
          let queryStr = `/${this.postDetails.postVersionGroupId}`; // need to pass postversiongroupid

          // NRT-11981 fixes
          if (this.postDetails.postVersionState && this.postDetails.postVersionState === 'Draft') {
            queryStr = `/${this.postDetails.postId}`;
            queryStr = queryStr + '?draft=true';

          }
          this.cService.serviceRequestCommon('delete', this.getApiURL, queryStr).subscribe(() => {

            this.toastService.createMessage('success', 'The post has been successfully deleted');
            this.reRoute.navigate(['ncx/stories-dashboard']);
            this.isLoaded = true;

          }, () => {

            this.toastService.createMessage('error', 'Error While deleting please try again');
            this.isLoaded = true;

          });

        }

      }

    });

  }

  /**
   * Send Post to MediaCentral
   *
   */
  sendPostToMediaCentral() {
    this.gaService.sendEvent('Send to PAM Click', 'Post Landing Page', '' + this.postDetails.postId, 1);
    this.isLoaded = false;
    const queryStr = `/${this.postDetails.postId}`;

    this.cService.serviceRequestCommon('post', environment.sentToMediaCentral, queryStr).subscribe(() => {

      this.toastService.createMessage('success', 'This item was sent to WG01/06');
      this.getPostDetails(this.postDetails.postId);

    },
      (err: any) => {

        console.error(err);
        this.toastService.createMessage('error', 'Error in sent to WG01/06. Please try again');

      }).add(() => {

        this.isLoaded = true;

      });

  }

  /**
   * View Post Versions page
   *
   */
  goToPostVersions() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', this.getApiURL, '/' + this.postId).subscribe((res: any) => {

      console.log('Post Response', res, this.postId);

      const post: Post = res as Post;

      if (post.locked && this.loggedInUserId !== post.lockedByUser?.userId) {

        this.toastService.createMessage('warning', 'You cannot edit a locked Post');
        this.postDetails = post;

      } else {

        this.reRoute.navigate(['ncx/post-versions/:' + post.postVersionGroupId]);

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Open Move Post modal
   *
   */
  openMovePost() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', this.getApiURL, '/' + this.postId).subscribe((res: any) => {

      console.log('Post Response', res, this.postId);

      const post: Post = res as Post;

      if (post.locked && this.loggedInUserId !== post.lockedByUser?.userId) {

        this.toastService.createMessage('warning', 'You cannot edit a locked Post');
        this.postDetails = post;

      } else {

        this.isMovePostVisible = true;

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Close Move Post modal
   *
   */
  closeMovePost() {

    this.isMovePostVisible = false;

  }

  updateRelatedStory(relatedStories) {

    if (relatedStories && relatedStories.length) {

      this.relatedStoryTitles = JSON.parse(JSON.stringify(relatedStories));
      this.originalPostDetails.relatedStories = relatedStories;
      this.postDetails.relatedStories = relatedStories;

    }

  }

  bindTitleDetails() {

    if (!(this.postDetails && this.postDetails.relatedStories)) {

      return;

    }
    if (!(this.listofStories && this.listofStories.length)) {

      return;

    }
    const updatedRelatedStories = [];

    this.postDetails.relatedStories.forEach(story => {

      const temp = this.listofStories.filter(a => a.storyId === story.storyId)[0];

      if (temp === null || temp === undefined) {

        return;

      }
      updatedRelatedStories.push({
        storyId: story.storyId,
        storyTitle: (temp && temp.storyTitle) ? temp.storyTitle : '',
        isPrimary: story.isPrimary,
        isPinnedOnTop: story.isPinnedOnTop
      });

    });
    this.relatedStoryTitles = [...updatedRelatedStories];
    console.log('ngOnChanges view post', this.listofStories, updatedRelatedStories, this.relatedStoryTitles);

  }

  /**
   * Is the Post private
   *
   */
  isPostPrivate(post: Post): boolean {

    const primaryStory: RelatedStory[] = post.relatedStories.filter((related: RelatedStory) => related.isPrimary);

    if (!primaryStory || !primaryStory.length) {

      return false;

    }

    const story = primaryStory[0];

    return story.storyAccess === 'private' &&
      !this.permissions.hasPermissionTo(PERMISSIONS.ACCESS_PRIVATE_STORY, { createUser: { userId: +localStorage.getItem('userId') } as User });

  }

  /**
   * When post is updated through the Metadata in the sidebar
   *
   */
  updatePostDetails(post: Post) {

    console.log('Post Updated', post);

    this.postId = post.postId;
    this.postDetails = post;
    this.initPostContentHTML();

  }

  /**
   * Build the Post HTML
   *
   */
  initPostContentHTML() {

    if (this.postDetails && this.postDetails.postContent) {

      setTimeout(() => {

        this.postDetails.postContent = this.authService.addJWTTokenToLink(this.postDetails.postContent, 'img'); //JWT Token has to be added to the
        // img S3
        // link to download the file from S3

        /**
         * .fr-deletable class added to the description if a profile is added to the froala editor - @username
         */
        const part = this.postcontent.nativeElement.querySelectorAll('.fr-deletable');

        part.forEach((anchor: HTMLAnchorElement) => {

          anchor.addEventListener('mouseenter', this.handleAnchorClick.bind(this));
          anchor.addEventListener('click', this.handleAnchorClick.bind(this));

        });

      }, 500);

    }

  }

  /**
   * Listens for click events on HTML content in Post Description
   *
   */
  handleAnchorClick(event) {

    this.customPopOver = false;
    this.showpop = true;
    if (event && event.srcElement && event.srcElement.id && event.srcElement.id.startsWith('user-')) {

      const userId = event.srcElement.id.split('-')[1];

      this.profileInDescription.userId = userId;

    } else {

      return;

    }
    setTimeout(() => {

      const element: HTMLElement = document.getElementById('triggerClick') as HTMLElement;

      element.style.left = (event.layerX + 25) + 'px';
      element.style.top = (event.layerY + 110) + 'px';
      this.customPopOver = true;

      element.click();

    });

  }

  /**
   * Get all versions of a Post
   *
   */
  getVersions(postVersionGroupId: number) {

    this.isLoaded = false;

    const getVersionsURL = `${environment.getPostApi}/${postVersionGroupId}/version`;

    this.cService.serviceRequestCommon('get', getVersionsURL).subscribe((data: any) => {

      const postVersions = data.posts;

      for (let i = 0; i < postVersions.length; i++) {

        if (postVersions && postVersions[i].postId === postVersions[i].postVersionGroupId) {

          this.postDetails.createDateTime = postVersions[i].createDateTime;

        }

      }

    }, (err) => {

      this.toastService.createMessage('error', 'Unable to load Post versions');
      console.error('getVersionss', err);

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Get all filters for metadata sidebar (passed to child component)
   *
   */
  getFilterList() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', environment.getPostFiltersURL).subscribe((res: any) => {

      this.filterList = JSON.parse(JSON.stringify(res));

    }, (err: any) => {

      this.toastService.createMessage('error', 'Unable to load Post metadata');
      console.error('getFilterList', err);

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * View attachment from WG01/06 component
   *
   */
  viewAttachment(index: number) {

    if (this.viewAttachmentsComponent && typeof this.viewAttachmentsComponent.openlightbox === 'function') {
      this.viewAttachmentsComponent.openlightbox(index, '');
    }

  }

  /**
   * Unlock Post
   *
   */
  unlockPost() {

    this.isLoaded = false;
    const queryStr = `/${this.postDetails.postVersionGroupId}/lock/delete`; // need to pass postversiongroupid

    this.cService.serviceRequestCommon('post', this.getApiURL, queryStr).subscribe(() => {

      this.toastService.createMessage('success', 'The post has been successfully unlocked');
      this.getPostDetails(this.postDetails.livePostId);
      this.isLoaded = true;

    }, () => {

      this.toastService.createMessage('error', 'Error While unlocking Post please try again');
      this.isLoaded = true;

    });

  }

  /**
   * Download PDF version of Post
   *
   */
  downloadPdf(event) {

    if (event === true) {

      const ncxjwttoken = localStorage.getItem('ncxjwttoken');

      const messageId = this.commonFunctionsHelper.generateUUID();

      const headers = new HttpHeaders({
        messageId,
        ncxjwttoken,
        Accept: 'application/pdf',

        // // responseType: 'blob' as 'pdf',
        'Content-Type': 'application/json'
      });

      this.isLoaded = false;
      const queryStr = `/${this.postDetails.postId}/viewaspdf/testing.pdf?serverTimeZone=${Time.localTimeZone()}`; // need to pass postversiongroupid

      const url = this.cService.getAPIEndpoint() + `/ncx-gateway${this.getApiURL}` + queryStr;

      this.http.get(url, { headers, responseType: 'blob' as const }).subscribe((val) => {

        console.log('downloadPdf : ', val);
        const blob = new Blob([val], {
          type: 'application/pdf'

          // type: 'application/octet-stream'
        });

        //  link.href = window.URL.createObjectURL(blob);

        const file = new Blob([blob], { type: 'application/pdf' });

        const fileURL = URL.createObjectURL(file);

        const browser = Common.getBrowser();

        if (browser.name === 'safari') {

          const link = document.createElement('a');

          link.href = fileURL;
          link.target = '_blank';
          link.download = `${this.postDetails.postTitle}.pdf`;
          link.click();
          window.open(`${this.postDetails.postTitle}.pdf`, '_target');
          window.URL.revokeObjectURL(link.href);

        } else {

          window.open(fileURL, '_target');

        }
        this.isLoaded = true;

      },
        (err) => {

          this.toastService.createMessage('error', 'Error While Downloading please try again');
          console.log('downloadPdf err : ', err);
          this.isLoaded = true;

        });

    }

  }

  /**
   * Media Central details
   *
   */
  isMediaCentralRecord() {

    this.getSendToMediaCentralDetails = [];
    if (this.postDetails.postAttachments?.length) {

      for (const attachment of this.postDetails.postAttachments) {

        const MediaCentralDetails = attachment.pamDetails;

        if (MediaCentralDetails) {

          this.getSendToMediaCentralDetails = [...this.getSendToMediaCentralDetails, ...MediaCentralDetails];

        }

      }

      // console.log("Is MediaCentral Detail =",this.getSendToMediaCentralDetails);
      if (this.getSendToMediaCentralDetails.length) {

        for (const details of this.getSendToMediaCentralDetails) {

          const isSendToMediaCentral = details.sentToPam;

          if (isSendToMediaCentral) {

            this.isMediaCentralDetails = true;
            return false;

          }

        }

      }

    }

  }

  /**
   * View Post Versions
   *
   */
  showPostVersions() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', this.getApiURL, `/${this.postDetails.postId}`).subscribe((res: any) => {

      const post: Post = res as Post;

      if (post.locked && this.loggedInUserId !== post.lockedByUser?.userId) {

        this.toastService.createMessage('warning', 'You cannot view the versions of locked Post');
        this.postDetails = post;

      } else {

        this.goToPostVersions();

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  /**
   * Does the post contain a video element?
   *
   */
  get doesPostHasVideo(): boolean {

    if (!Array.isArray(this.postDetails?.postAttachments) || !this.postDetails.postAttachments?.length) {

      return false;

    }

    return this.postDetails.postAttachments.filter((attachment: PostAttachment) => {

      return Common.isVideoMimeType(attachment.attachmentMIMEType);

    }).length > 0;

  }

  /**
   * Does post have the "CLEARED" label from legal
   *
   */
  get isPostClearedByLegal(): boolean {

    if (!Array.isArray(this.postDetails?.postLegal) || !this.postDetails.postLegal?.length) {

      return false;

    }

    return this.postDetails.postLegal.includes('CLEARED');

  }

  editRedirect(): void {

    this.isLoaded = false;

    this.showBanner = false;
    this.gaService.sendEvent('Edit Post Click', 'Post Landing Page', "" + this.postId, 1);
    this.cService.serviceRequestCommon('get', this.getApiURL, `/${this.postDetails.postId}`).subscribe((response: any) => {

      /**
       * if the post was already deleted then show the msg and redirect to stories dashboard
       */
      if (response.isDeleted === true) {

        this.toastService.createMessage('error', 'Requested post does not exist. Redirecting to stories dashboard');
        this.redirectToStoriesDashboard();

      } else if (response.livePostId != null && response.livePostId !== response.postId &&
        (response.postType === 'Update' || response.postType === 'Draft') && response.postVersionState === 'Draft') {

        /**
         *  Live Post Id is not null , Live Post Id is not the current Post Id , version state is Draft and Post Type is Update then it is restored
         * version. Restored version should be edited directly without checking whether any live version exists or not Just for clarity : Regular
         * Draft post will have the Post Type as 'Draft' and Post Version State as 'Draft'. These posts will be created when we click 'Save Draft'
         * option in Create Post. Live Post Id is not null , Live Post Id is not the current Post Id , version state is Draft and Post Type is Draft
         * then it is draft version created using 'Save Draft' option Draft version should be edited directly without checking whether any live
         * version exists or not
         */
        this.editPost();

      } else if (response.livePostId != null && response.livePostId !== response.postId && response.postType === 'Update' &&
        response.postVersionState === 'Archived') {

        /**
         *  Live Post Id is not null , Live Post Id is not the current Post Id , version state is Archive and Post Type is Update then it is an
         * archive version. It cannot be edited with the current version. This means someone else has edited and saved the post before the current
         * user clicks 'Edit'. New live post is available now. Content from the live post should be used to edit. A new draft will be created from
         * the live version and it will be used to edit (Draft version will have a new post id). This draft is different from the one which can be
         * created using 'Save Draft' option. Refer previous condition to get better idea on this. No msg will be shown to user that it is an Archive
         * version and the live version will be used to edit. These mappings will happen silently and will be redirected to edit page.
         */
        this.editPost(response.livePostId);

      } else if (response.livePostId != null && response.livePostId !== response.postId && response.postVersionState !== "Draft") {

        /**
         *  Live Post Id is not null , Live Post Id is not the current Post Id and not 'Draft' or 'Archive' verion with 'Update' PostType then the
         * Live Post Id should be used to edit the post Just for clarity : Regular Draft post will have the Post Type as 'Draft' and Post Version
         * State as 'Draft'. These posts will be created when we click 'Save Draft' option in Create Post.
         */
        this.cService.serviceRequestCommon('get', this.getApiURL, `/${response.livePostId}`).subscribe((response1: any) => {

          response = response1;
          if (response.locked && (response.lockedByUser !== null && this.loggedInUserId !== response.lockedByUser.userId)) {

            this.postDetails = response; // this update will disable the Edit button via html since the Post was locked by some one else
            this.showBanner = true;

          } else {

            this.postDetails = response;
            this.editPost(response.livePostId); // edit the post from the new version

          }

        });

      } else {

        if (response.locked && (response.lockedByUser !== null && this.loggedInUserId !== response.lockedByUser.userId)) {

          this.postDetails = response; // this update will disable the Edit button via html since the Post was locked by some one else
          this.showBanner = true;

        } else {

          this.editPost(); // edit the post from the existing version which is latest

        }

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Redirect to Stories Dashboard
   */
  redirectToStoriesDashboard() {

    setTimeout(() => {

      this.reRoute.navigate(['ncx/stories-dashboard']);

    }, 500);

  }

  linkToAngle() {
    this.gaService.sendEvent('Link to Angle Click', 'Post Landing Page', '' + this.postDetails.postId, 1);
    this.modalService.create({
      nzContent: AddAngleComponent,
      nzData: {
        postId: this.postDetails.postId,
        postTitle: this.postDetails.postTitle
      },
      nzWidth: 605,
      nzBodyStyle: {
        padding: '16px'
      },
      nzCloseIcon: this.closeIconTpl,
      nzFooter: null
    }).afterClose.subscribe(_ => {

      this.getPostDetails(this.postDetails.postId);

    });

  }

  showConfirm(): void {

    this.cService.serviceRequestCommon('get', this.getApiURL, `/${this.postDetails.postId}`).subscribe((response: any) => {

      if (response.locked && (response.lockedByUser !== null && this.loggedInUserId !== response.lockedByUser.userId)) {

        this.postDetails = response;

      } else {

        this.modalService.confirm({
          nzTitle: 'Are you sure you want to delete this post?',
          nzOkText: 'Delete',

          // nzOkType: 'danger',
          nzOkDanger: true,
          nzCancelText: 'Cancel',
          nzOnOk: () => {

            this.deletePost('true');

          },
          nzOnCancel: () => {
          }
        });

      }

    });

  }

  windowResizeEvent() {

    const metadataTabBody = (this.metadataBodyElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    const postDetailsTabBody = (this.postDetailsBodyElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    this.postDetailsTabBodyHeight = window.innerHeight - postDetailsTabBody?.top - this.footerHeight;

    this.metadataTabBodyHeight = window.innerHeight - metadataTabBody?.top - this.footerHeight;

    document.documentElement.style.setProperty('--bodyTabBodyHeightInPx', this.postDetailsTabBodyHeight + 'px');

    document.documentElement.style.setProperty('--rightSectionBodyHeightInPx', this.metadataTabBodyHeight + 'px');

  }

  viewAsPdf() {

    this.downloadPdf(true);

  }

  loader(event) {

    this.isLoaded = event;

  }

  /**
   * Can edit post
   *
   */
  canEditThePost(): boolean {

    return this.permissions.hasPermissionTo(PERMISSIONS.EDIT_POST, { createUser: this.postDetails.createUser });

  }

  /**
   * 'Add To Angle' option should not be available to read only user.
   * Editor and Jounalist can add only the angles where they have rights. Either the Creator of the Angle or as a Collaborator in the angle.
   * Angle filtration based on role is happening in the backend
   */
  canAddAngleToThePost(): boolean {

    return !this.permissions.isReadOnlyRole;

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
