<nz-collapse
   nzExpandIconPosition="right"
   nzBordered="false">
   <nz-collapse-panel
      nzHeader="{{ header }}"
      nzActive="true">
      <div *ngFor="let story of relatedStoryTitles; last as isLast">
         <div class="story-flex">
            <div
               *ngIf="story.autoGeneratedStoryId"
               class="story-id">
               {{ story.autoGeneratedStoryId }}
            </div>
            <div>
               <nz-divider
                  *ngIf="story.autoGeneratedStoryId"
                  class="vertical-line"
                  nzType="vertical"></nz-divider>
            </div>
            <div>
               <a
                  href="/#/ncx/landing-story/:{{ story.storyId }}"
                  class="story-text"
                  (click)="redirectTo(story.storyId)"
                  [innerHTML]="story.storyTitle | safeHtml"></a>
            </div>
         </div>
         <div
            *ngIf="!isLast"
            class="divider"></div>
      </div>
   </nz-collapse-panel>
</nz-collapse>
