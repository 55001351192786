<ul
   nz-menu
   class="app-bento-box">
   <li
      nz-menu-item
      [ngClass]="disabled.includes('ncx') ? 'disabled' : ''"
      *ngIf="links.includes('ncx')">
      <a
         (click)="openNBCApp(ncx_Url, 'ncx')"
         [href]="ncx_Url">
         <icon-news-connect
            class="icon"
            [useDefaultColors]="false"></icon-news-connect>
         NewsConnect
      </a>
   </li>
   <li
      nz-menu-item
      [ngClass]="disabled.includes('producer-dashboard') ? 'disabled' : ''"
      *ngIf="links.includes('producer-dashboard')">
      <a
         (click)="openNBCApp(producer_dashboard_Url, 'producer-dashboard')"
         [href]="producer_dashboard_Url">
         <icon-nbc class="icon"></icon-nbc>
         Producer Dashboard
      </a>
   </li>
   <li
      nz-menu-item
      [ngClass]="disabled.includes('guest-tracker') ? 'disabled' : ''"
      *ngIf="links.includes('guest-tracker')">
      <a
         (click)="openNBCApp(guest_tracker_Url, 'guest-tracker')"
         [href]="guest_tracker_Url">
         <icon-guest-tracker class="icon guest-tracker"></icon-guest-tracker>
         Guest Tracker
      </a>
   </li>
   <li
      nz-menu-item
      [ngClass]="disabled.includes('news-search') ? 'disabled' : ''"
      *ngIf="links.includes('news-search')">
      <a
         (click)="openNBCApp(news_search_Url, 'news-search')"
         [href]="news_search_Url">
         <icon-nbc class="icon"></icon-nbc>
         Search
      </a>
   </li>
</ul>
