<nz-modal
   [(nzVisible)]="isVisible"
   (nzOnCancel)="handleLightBoxCancel()"
   [nzNoAnimation]="true"
   [nzCentered]="true"
   [nzClosable]="true"
   [nzFooter]="null"
   [nzWrapClassName]="'modal-lightbox'">
   <ng-container *nzModalContent>
      <div class="lightbox-content">
         <!-- LEFT ARROW -->
         <button
            class="arrow-wrapper left-arrow"
            *ngIf="lightboxAttachments?.length > 1"
            (click)="pre()">
            <i class="arrow"></i>
         </button>

      <!-- IMAGES -->
      <img *ngIf="currentAttachment.isImage && !currentAttachment.unsupport"
           class="image"
           (load)="onLoadImage($event)"
           [src]="currentAttachment.fileUrl"
           [alt]="currentAttachment.name"/>

      <!-- ICONS -->
      <div *ngIf="!currentAttachment.isImage && !currentAttachment.isVideo && !currentAttachment.unsupport">
        <ngx-doc-viewer (loaded)="contentLoaded()" [url]="currentAttachment.fileUrl" [viewer]="'google'" style="width:100%;height:80vh;"></ngx-doc-viewer>
      </div>

      <!-- VIDEO -->
      <video #vid
             class="video"
             *ngIf="currentAttachment.isVideo && !currentAttachment.unsupport"
             (loadedmetadata)="onLoadVideo($event)"
             preload="auto"
             muted
             controls>
        <source [src]="currentAttachment.fileUrl"
                type="video/mp4"/>
      </video>

      <!-- UNSUPPORTED VIDEO -->
      <a *ngIf="currentAttachment.isVideo && currentAttachment.unsupport" href="{{currentAttachment.fileUrl}}" target="_blank"></a>

         <!-- RIGHT ARROW -->
         <button
            class="arrow-wrapper right-arrow"
            *ngIf="lightboxAttachments?.length > 1"
            (click)="next()">
            <i class="arrow"></i>
         </button>

         <!-- LOADER -->
         <app-loader-component
            loaderType="SECTION_LOADER"
            [isLoaded]="!isLoading"></app-loader-component>
      </div>
   </ng-container>
</nz-modal>
