<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout [attr.data-component]="'ncx/following/following.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon
      [nzGhost]="false">
      <nz-page-header-title>
         <div *ngIf="isLoaded">
            <nz-button-group>
               <button
                  (click)="changeSortOrder(isSortingAscending ? 'desc' : 'asc')"
                  nz-button>
                  <i
                     nz-icon
                     [nzType]="isSortingAscending ? 'sort-ascending' : 'sort-descending'"></i>
                  <span class="sort-order-btn">Order</span>
               </button>
               <button
                  nz-button
                  nz-dropdown
                  class="sort-order-dropdown"
                  [nzDropdownMenu]="sortOrder">
                  {{ sortOrderSelected }}
                  <i
                     nz-icon
                     nzType="down"></i>
               </button>
               <nz-dropdown-menu #sortOrder="nzDropdownMenu">
                  <ul nz-menu>
                     <li
                        nz-menu-item
                        (click)="changeSortOrder('asc')">
                        <label>A - Z</label>
                     </li>
                     <li
                        nz-menu-item
                        (click)="changeSortOrder('desc')">
                        <label>Z - A</label>
                     </li>
                  </ul>
               </nz-dropdown-menu>
            </nz-button-group>
         </div>
      </nz-page-header-title>

      <nz-page-header-extra class="page-header-extra">
         <nz-input-group [nzPrefix]="searchIcon">
            <input
               type="text"
               nz-input
               #searchFollower
               [(ngModel)]="searchInput"
               placeholder="Search Followers..."
               (keydown)="filterFollowers($event, searchFollower.value, 'other')" />
            <ng-template #searchIcon>
               <i
                  nz-icon
                  nzType="search"
                  (click)="filterFollowers($event, searchFollower.value, 'click')"></i>
            </ng-template>
         </nz-input-group>
         <button (click)="toggleStoryDetail()">
            <i
               nz-icon
               nzType="setting"
               nzTheme="outline"></i>
         </button>
      </nz-page-header-extra>
   </nz-page-header>

   <app-scroll-container [footerHeight]="0">
      <ng-template bodyTemplate>
         <nz-layout class="layout-background">
            <nz-content>
               <div
                  *ngIf="isSticky && storyDetails && storyDetails.storyState"
                  class="stickyHeader"
                  id="storycontainerDiv">
                  <app-story-status [status]="storyDetails.storyState"></app-story-status>
                  <span *ngIf="storyDetails && storyDetails.storyTitle">
                     {{ storyDetails.storyTitle }}
                  </span>
               </div>

               <nz-card
                  nzSize="small"
                  class="story-header">
                  <div
                     class="storyState"
                     *ngIf="storyDetails && storyDetails.storyState">
                     <app-story-status [status]="storyDetails.storyState"></app-story-status>
                     <span
                        class="followerIcon"
                        *ngIf="storyDetails">
                        <app-follow-story
                           type="text"
                           [storyId]="storyDetails.storyId"
                           [isFollowing]="storyDetails.isUserFollowing"
                           [followers]="storyDetails.storyFollowerCount"
                           [returnDetailedFollowerList]="true"
                           [showFollowCount]="true"
                           [showFollowStatus]="false"
                           (updatedFollower)="updateFollowers($event)"></app-follow-story>
                     </span>
                  </div>
                  <div class="storyTitle">
                     <div>
                        <span *ngIf="storyDetails && storyDetails.storyTitle">
                           {{ storyDetails.storyTitle }}
                        </span>
                     </div>
                  </div>

                  <!-- Story Description. Profile view on mouse over. If the description has the format @username then the profile will be shown -->
                  <span
                     id="triggerClick"
                     nzPopoverTrigger="click"
                     nz-popover
                     [nzPopoverContent]="profileCardTemplate"
                     [nzPopoverPlacement]="'topLeft'"></span>
                  <ng-template
                     cdkConnectedOverlay
                     #profileCardTemplate>
                     <app-profile-overlay [profileData]="profileInDescription"></app-profile-overlay>
                  </ng-template>

                  <div
                     class="storyDescription fr-element fr-view"
                     #storyDetailsContent
                     id="storyContent"
                     *ngIf="storyDetails && storyDetails.storyContent">
                     <span [innerHtml]="storyDetails?.storyContent | safeHtml"></span>
                  </div>

                  <div
                     class="storyDescription"
                     *ngIf="storyDetails && storyDetails.storyTitle">
                     <button
                        nz-button
                        *ngIf="storyDetails && storyDetails.storyTitle"
                        nzType="link"
                        nzSize="small"
                        class="moreStories"
                        (click)="viewStory(storyDetails)">
                        See Full Story
                     </button>
                  </div>
               </nz-card>

               <nz-card
                  class="following"
                  [nzBordered]="false"
                  [ngStyle]="checkScreen()">
                  <app-stories-following
                     [storyFollowingDetails]="storyFollowers"
                     [filterApplied]="followerFilter"
                     (isFollowingCollapsed)="closeDrawer()"></app-stories-following>
               </nz-card>
            </nz-content>

            <nz-sider
               *ngIf="!isMobile"
               nzCollapsible
               nzCollapsedWidth="0"
               [nzWidth]="295"
               [nzCollapsed]="!isStoriesLandingVisible"
               [nzTrigger]="null">
               <app-toggle-section
                  [isStoryLanding]="isStoryLanding"
                  [isFilter]="isFilter"
                  [isCollapsed]="!isStoriesLandingVisible"
                  [storyLandingDetails]="storyDetails"
                  (openinfoCenter)="openInfoCenter()"></app-toggle-section>
            </nz-sider>

            <nz-drawer
               *ngIf="isMobile"
               nzPlacement="right"
               [nzWidth]="320"
               [nzClosable]="false"
               [nzWrapClassName]="'no-padding'"
               (nzOnClose)="toggleStoryDetail()"
               [nzVisible]="isStoriesLandingVisible">
               <ng-container *nzDrawerContent>
                  <app-toggle-section
                     [isStoryLanding]="isStoryLanding"
                     [isFilter]="isFilter"
                     [isDrawer]="true"
                     [isCollapsed]="!isStoriesLandingVisible || isFilter"
                     [storyLandingDetails]="storyDetails"
                     (openinfoCenter)="openInfoCenter()"></app-toggle-section>
               </ng-container>
            </nz-drawer>
         </nz-layout>
      </ng-template>
   </app-scroll-container>
</nz-layout>

<app-infocenter-research
   [isinfocenterVisible]="isinfocenterVisible"
   [storyTitle]="storyTitle"
   [Id]="storyId"
   (closeinfoCenter)="closeInfoCenter()"></app-infocenter-research>
