import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IFunctionAbility } from '@models/users';
import { BreakpointService } from '@services/breakpoint.service';
import { CSSThemeService } from '@services/css-theme.service';
import { DebounceService } from '@services/debounce.service';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { environment } from 'src/environments/environment';
import { TransferInformationComponent } from '../TransferInformation/TransferInformation.component';

type StoryParams = {
  contentType: 'Story',
  startIndex: number,
  endIndex: number,
  sortField: string,
  sortOrder: string,
  topicsInfo: boolean
};

@Component({
  selector: 'app-linked-stories',
  templateUrl: './linked-stories.component.html',
  styleUrls: ['./linked-stories.component.scss']
})
export class LinkedStoriesComponent implements OnInit, OnChanges, AfterViewChecked {

  value = '';

  options: string[] = [];

  @Input() postRelatedStories = [];

  @Input() isPinnedOnTop;

  @Output() addedStories = new EventEmitter();

  @Input() showCrown: boolean = true;

  @Input() showPin: boolean = true;

  @Input() disableSearch: boolean = false;

  @Input() showStoryChangeInfo: boolean = true;

  @Input() tooltip: string = '';

  @Input() showMandatoryAsterisk: boolean = true;

  @Input() highlightMandatoryText: boolean = true;

  @Input() mandatoryMsg: string = '';

  getStoryTitlesAPI;

  listofStories = [];

  emptyStories = ['No Stories Found'];

  relatedStoryTitles = [];

  filteredOptions: string[] = [];

  filteredOptionsInit: any[];

  isLightMode: boolean = false;

  isMobile: boolean = false;

  showToolTip: boolean = false;

  tooltipTrigger = 'manual';

  storySearchSubject: Subject<string> = new Subject();

  public functionAbility = {} as IFunctionAbility;

  autoCompleteWidth: number = 0;

  autoCompleteWidthpx: string = '0px';

  page: number = 1;

  limit: number = 50;

  total: number = 0;

  searchText: string = '';

  debounceTimeout: any;

  isSearchLoaded: boolean = true;

  isScrollLoaded: boolean = true;

  previousSearchText: string = '';

  // Cancel API request
  cancelRequest: Subject<boolean> = new Subject<boolean>();

  @ViewChild('storyInput', { static: false }) storyInputElement: ElementRef | any;

  constructor(
    private cService: CommonService,
    private tI: TransferInformationComponent,
    private themeService: CSSThemeService,
    private breakpointService: BreakpointService,
    private debounceService: DebounceService,
    private ngZone: NgZone
  ) {

    this.storySearchSubject.pipe(
      debounceTime(700)
    ).subscribe((text) => {

      this.getStoryTitlesList();

    });

  }

  ngOnInit() {

    this.getStoryTitlesAPI = environment.globalSearch;
    this.functionAbility = { ...this.tI.userFunctionAbility } as IFunctionAbility;
    console.log('related stories', this.postRelatedStories);

    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });

  }

  ngAfterViewInit() {
    // Add resize event listener with debounce
    // Event will be called only after the user interaction is completed
    //   window.addEventListener('resize', this.debounceService.debounce(() => {
    //     this.updateDropdownWidth();
    //   }, 200));
  }

  ngOnDestroy() {
    // Unsubscribe from the resize event when the component is destroyed
    // window.removeEventListener('resize', this.debounceService.debounce(() => {
    //   this.updateDropdownWidth();
    // }, 200));
  }

  updateDropdownWidth() {
    const dropdown = document.querySelector('.cdk-overlay-container .ant-select-dropdown');
    if (dropdown) {
      // Add the custom class to the parent of the dropdown
      const parentElement = dropdown.parentElement;
      if (parentElement) {
        parentElement.classList.add('custom-autocomplete-dropdown');
      }
    }

    this.autoCompleteWidth = (this.storyInputElement?.nativeElement as HTMLElement)?.clientWidth;
    if (this.autoCompleteWidth) {
      this.autoCompleteWidthpx = this.autoCompleteWidth + 44 + 'px';
      document.documentElement.style.setProperty('--dropdown-width', this.autoCompleteWidthpx); // Adjust the width as needed
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateDropdownWidth();
  }


  ngAfterViewChecked() {

    this.isLightMode = this.themeService.currentTheme !== 'dark';

  }

  ngOnChanges(changes: SimpleChanges) {

    if ('postRelatedStories' in changes) {

      const newValue = changes.postRelatedStories.currentValue || [];

      this.relatedStoryTitles = newValue.map((story) => {

        return {
          storyId: story.storyId,
          storyTitle: (story && story.storyTitle) ? story.storyTitle : '',
          storyIdTitle: story.autoGeneratedStoryId ? `${story.autoGeneratedStoryId} - ${story.storyTitle}` : story.storyTitle,
          autoGeneratedStoryId: story.autoGeneratedStoryId,
          isPrimary: story.isPrimary,
          isPinnedOnTop: this.isPinnedOnTop,
          topicDetails: story.topicDetails
        };

      });

    }

  }

  resetOptions() {

    this.filteredOptions = [];
    this.filteredOptionsInit = [];
    this.listofStories = [];

  }

  getStoryTitlesList() {

    try {

      if (this.searchText === '') {
        console.log('Empty search text just before the api call');
        this.isSearchLoaded = this.isScrollLoaded = true;
        return;
      }
      //params = `?searchString=${queryStr}&contentType=Story&startIndex=1&endIndex=50&sortField=modificationDate&sortOrder=descending`;

      const queryParams = this.buildParams();

      const queryString = Object.keys(queryParams).map((key: string) => `${key}=${queryParams[key]}`).join('&');

      const params = `?searchString=${this.searchText}&` + queryString;


      this.cService.serviceRequestCommon('post', this.getStoryTitlesAPI, params).pipe(takeUntil(this.cancelRequest.pipe(filter(value => value === true)))).subscribe({

        next: (data: any) => {

          if (!this.isSearchValid()) {
            console.log('Empty search text just after the api call');
            return;
          }

          this.total = data.storyCount;

          const additionalStoriesFromAPI = data.storySearchResult.map((story: any) => {

            return {
              storyId: story.storyID,
              storyTitle: story.storyTitle,
              storyIdTitle: story.autoGeneratedStoryId ? `${story.autoGeneratedStoryId} - ${story.storyTitle}` : story.storyTitle,
              autoGeneratedStoryId: story.autoGeneratedStoryId,
              isPrimary: false,
              isPinnedOnTop: this.isPinnedOnTop,
              topicDetails: story.topicDetails,
              storyAccess: story.storyVisibility ? "public" : "private",
              createUserId: story.createUserId
            };

          });

          const additionalStories = JSON.parse(JSON.stringify(additionalStoriesFromAPI));

          this.listofStories = [...this.listofStories, ...additionalStories];
          this.filteredOptions = this.listofStories.length ? this.listofStories.map((story) => story.storyIdTitle) : this.emptyStories;
          this.filteredOptionsInit = this.listofStories.length ? this.listofStories.map((story) => story.storyIdTitle) : this.emptyStories;
        },
        error: (error: any) => {

          console.error('getPost', error);
        }
      }).add(() => {

        this.isSearchLoaded = this.isScrollLoaded = true;
      });
    } catch (e) {
      console.error('getStoryTitlesList event : ', e);
      this.isSearchLoaded = this.isScrollLoaded = true;
    }
  }

  titleMatch(value) {

    const availableStory = this.listofStories.filter(story => story.storyIdTitle === value)[0];

    const relatedStory = this.relatedStoryTitles.filter(story => story.storyIdTitle === value)[0];

    if ((!(availableStory === null || availableStory === undefined) && (relatedStory === null || relatedStory === undefined))) {

      if (!(this.relatedStoryTitles && this.relatedStoryTitles.length)) {

        availableStory.isPrimary = true;

      }
      availableStory.isPinnedOnTop = this.isPinnedOnTop;
      this.relatedStoryTitles.push(availableStory);
      this.mapAdditionalStoryToPost();
      setTimeout(() => {

        this.value = '';

      }, 100);

    }
    if (this.filteredOptionsInit && this.filteredOptionsInit.length) {

      this.filteredOptions = [...this.filteredOptionsInit];

    }

  }

  isSearchValid() {

    //list of stories must be cleared when the input is empty

    if (this.searchText.trim() === '') {

      this.listofStories = [];

      this.filteredOptions = [];

      this.filteredOptionsInit = [];

      this.previousSearchText = '';

      return false;
    }

    return true;

  }

  onChange(event: any): void {

    this.searchText = event.target.value;

    if (!this.isSearchValid())
      return;

    console.log('Empty search text in onChange event');

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || this.searchText === this.previousSearchText) {
      return;
    }

    this.previousSearchText = this.searchText;

    //Also list must be cleared when there is a new input

    this.listofStories = [];

    this.filteredOptions = [];

    this.filteredOptionsInit = [];

    this.page = 1;

    this.isSearchLoaded = false;

    this.cancelRequest.next(true);

    this.storySearchSubject.next(this.searchText);

  }

  assignValues(val) {

    if (!val) {

      this.filteredOptions = this.filteredOptionsInit;

    }
    this.filteredOptions = this.filteredOptionsInit.filter((item) => {

      if (item !== null && item !== undefined) {

        const storyTitle = item.toLowerCase();

        // console.log('storyTitle ', value, event);
        if (storyTitle.includes(val.toLowerCase())) {

          return item;

        }

      }

    });

  }

  mapAdditionalStoryToPost() {

    this.addedStories.emit({ relatedStories: this.relatedStoryTitles, isPinnedOnTop: this.isPinnedOnTop });
    this.resetOptions();

  }

  pinStory(storyId, value) {

    this.isPinnedOnTop = !value;
    this.relatedStoryTitles = this.relatedStoryTitles.map(element => {

      element.isPinnedOnTop = !value;
      return element;

    });
    this.mapAdditionalStoryToPost();

  }

  postAsPrimaryStory(storyId) {

    this.relatedStoryTitles.map((element) => {

      if (element.storyId === storyId) {

        element.isPrimary = true;

        //(document.getElementsByClassName('primaryStory')[index] as HTMLElement).style.backgroundColor = '#1890FF';

      } else {

        element.isPrimary = false;

        //(document.getElementsByClassName('primaryStory')[index] as HTMLElement).style.backgroundColor = '#fff';

      }

    });
    this.mapAdditionalStoryToPost();

  }

  deleteRelatedStories(index, storyId) {

    this.relatedStoryTitles.forEach((element, itemindex) => {

      if (storyId === element.storyId) {

        this.relatedStoryTitles.splice(itemindex, 1);

      }

    });
    if (index.isPrimary) {

      if (this.relatedStoryTitles && this.relatedStoryTitles.length) {

        this.relatedStoryTitles[0].isPrimary = true;

      }

    }
    if (this.relatedStoryTitles.length == 0) {

      this.isPinnedOnTop = false;

    }

    this.addedStories.emit({ relatedStories: this.relatedStoryTitles, isPinnedOnTop: this.isPinnedOnTop });
    this.resetOptions();

  }

  toggleTooltip() {

    if (this.isMobile) {

      this.showToolTip = !this.showToolTip;

    }

  }

  showTooltipEvent() {

    if (!this.isMobile && !this.showToolTip) {

      setTimeout(() => {

        this.showToolTip = true;

      }, 300);

    }

  }

  hideTooltip() {

    if (!this.isMobile && this.showToolTip) {

      setTimeout(() => {

        this.showToolTip = false;

      }, 300);

    }

  }


  /**
  * On page scroll, load next page
  *
  */
  onScrollDown() {

    const { end } = this.startEndIndex;

    console.log('onScrollDown (Stories)');

    // If the end index is >= number of results
    if (end >= this.total) {

      console.log('No More Results');
      return;

    }

    if (this.isSearchValid()) {

      this.page += 1;

      this.isScrollLoaded = false;
      this.cancelRequest.next(false);
      this.storySearchSubject.next(this.searchText);
    }

  }

  get startEndIndex(): { start: number, end: number } {

    const end = this.page * this.limit;

    const start = (end - this.limit) + 1;

    return { start, end };

  }

  /**
   * 
   * Build the query string portion of the API call
   */
  buildParams(): StoryParams {

    // API Params
    const params: StoryParams = {
      contentType: 'Story',
      startIndex: 0,
      endIndex: this.limit,
      sortField: 'modificationdate',
      sortOrder: 'descending',
      topicsInfo: true
    };

    try {

      // Page
      const { start: startIndex, end: endIndex } = this.startEndIndex;

      params.startIndex = startIndex;
      params.endIndex = endIndex;

      console.log('buildParams', params);

      return params;

    } catch (error) {

      console.error('buildParams', error);

      return {} as StoryParams;

    }
  }

  onScroll(event: any): void {
    // if (this.debounceTimeout) {
    //   clearTimeout(this.debounceTimeout);
    // }

    // this.debounceTimeout = setTimeout(() => {
    //   this.ngZone.run(() => {
    //     this.scrollDown(event);
    //   });
    // }, 200); // Adjust the debounce delay as needed   
    // const element = event.target;
    // console.log('scrollHeight in on scroll', element.scrollHeight);
    // console.log('current scrollHeight in on scroll', element.scrollTop);
    this.debounceService.debounce(() => {
      this.scrollDown(event);
    }, 200);
  }



  scrollDown(event: any) {
    const element = event.target;
    // console.log('scrollHeight in on scroll', element.scrollHeight);
    // console.log('current scrollHeight in on scroll', element.scrollTop);
    if (element.scrollHeight < element.scrollTop + 260) {
      // console.log('Entered Scroll Down');
      this.onScrollDown();
    }
  }

}
