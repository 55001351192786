<app-loader-component
  [isLoaded]='!loading'
  loaderType='APP_LOADER'></app-loader-component>

<div
  class='app-page-wrapper'
  data-component='src/app/app.component.html'
  *ngIf='!loading'>
  <nz-layout>
    <!-- DESKTOP NAVIGATION DRAWER -->
    <nz-sider
      *ngIf='!isMobile'
      class='menu-sidebar'
      nzCollapsible
      [nzWidth]='255'
      [nzCollapsedWidth]='80'
      (nzCollapsedChange)='onToggleNavigation($event)'
      [nzCollapsed]='isNavigationClosed'
      [nzTrigger]='null'>
      <app-navigation
        *ngIf='loadSideMenu'></app-navigation>
    </nz-sider>

    <!-- MOBILE NAVIGATION DRAWER -->
    <nz-drawer
      *ngIf='isMobile'
      nzPlacement='left'
      nzWrapClassName='mobile-drawer'
      [nzWidth]='255'
      [nzClosable]='false'
      [nzVisible]='!isNavigationClosed'
      (nzOnClose)='onCloseNavigation()'>
      <ng-container *nzDrawerContent>
        <app-navigation
          *ngIf='loadSideMenu'></app-navigation>
      </ng-container>
    </nz-drawer>

    <nz-layout>
      <!-- SESSION EXPIRING BANNER -->
      <app-session-banner></app-session-banner>

      <!-- MAIN HEADER -->
      <nz-header class='app-header'>
        <!-- LEFT SIDE OF HEADER -->
        <div class='left-side'>
          <button
            (click)='toggleNavigation()'
            class='trigger'>
            <i
              nz-icon
              [nzType]="isMobile ? 'menu-unfold' : isNavigationClosed ? 'menu-unfold' : 'menu-fold'"></i>
          </button>

          <img
            class='logo'
            alt='NBC News'
            src='/assets/icons/ncx-logo.svg' />

          <span class='title'>{{ pageHeader }}</span>
        </div>

        <!-- RIGHT SIDE OF HEADER -->
        <div
          class='toolbar right-side'
          id='toolbarItems'>

          <!-- GLOBAL SEARCH COMPONENT -->
          <app-global-search></app-global-search>

          <!-- CREATE STORY -->
          <!-- <button
       nz-button
       *ngIf="canCreateStory"
       (click)="createStoryOrPost()"
       nzSize="small"
       nzShape="circle"
       nzType="primary"
       class="plusIcon">
       <i nz-icon
          nzType="plus"></i>
     </button> -->

          <button
            class='plusIcon'
            *ngIf='canCreateStory'
            (click)='createStoryOrPost()'>
            <span
              nz-icon
              nzType='plus-circle'
              nzTheme='fill'></span>
          </button>

          <!-- ALERTS -->
          <nz-badge
            [nzCount]='alertCount'
            class='alertIcon'
            [nzOverflowCount]='99'>
            <button
              nz-button
              nz-popover
              nzShape='circle'
              (click)='viewAlerts()'
              [ngStyle]='checkScreen()'
              [nzPopoverOverlayClassName]="'alerts'"
              [nzPopoverContent]='alertsTemplate'
              [nzPopoverTrigger]='popType'
              nzPopoverPlacement='bottomRight'>
              <i
                nz-icon
                nzType='bell'></i>
            </button>
          </nz-badge>

          <!-- BENTO BOX -->
          <span class='action-button'>
            <button nz-button
                    nz-dropdown
                    [ngClass]='{bentoIcon: true, hasAlerts: alertCount > 0}'
                    (nzVisibleChange)='onBentoBoxVisibilityChange($event)'
                    [nzDropdownMenu]='appTemplate'>
              <i nz-icon
                 nzType='appstore'
                 nzTheme='fill'></i>
              <nz-dropdown-menu #appTemplate>
                <app-bento-box [disabled]="['ncx']"></app-bento-box>
              </nz-dropdown-menu>
            </button>
          </span>

          <!-- PROFILE ICON -->
          <nz-avatar
            *ngIf='avtarImgUrl'
            class='profileIcon'
            nz-popover
            [nzPopoverContent]='contentTemplate'
            [nzSize]='32'
            [nzSrc]='avtarImgUrlWithToken'
            routerLink='ncx/profile'
            nzPopoverPlacement='bottomRight'></nz-avatar>

          <nz-avatar
            *ngIf='!avtarImgUrl'
            [nzSize]='32'
            [nzPopoverContent]='contentTemplate'
            class='user-icon'
            nzIcon='user'
            routerLink='ncx/profile'
            nzPopoverPlacement='bottomRight'
            nz-popover></nz-avatar>

          <ng-template #contentTemplate>
            <div class='profile'>
              <a href='/#/ncx/profile'>
                <app-svg-icon
                  icon='avatar'
                  class='profileIcon'
                  size='16'></app-svg-icon>
                {{ username }}
              </a>
              <button (click)='logout()'>
                <span>
                  <i
                    nz-icon
                    nzType='logout'
                    nzTheme='outline'></i>
                </span>
                Logout
              </button>
            </div>
          </ng-template>

          <ng-template #alertsTemplate>
            <app-alerts-popup-component></app-alerts-popup-component>
          </ng-template>
        </div>
      </nz-header>

      <!-- HOT / ANNOUNCEMENTS BANNER -->
      <app-banners></app-banners>

      <!-- PAGE CONTENT -->
      <nz-content style='margin: 0'>
        <div class='inner-content'>
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
</div>
