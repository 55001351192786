import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConstants } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { Profile } from '@models/users';
import { UrlRedirectService } from '@services/url-redirect.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import { Subject } from 'rxjs';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';
import { CommonService } from '../common-service';
import { IOSShellAppService } from '../iosShellApp.service';
import { WebSocketService } from '../websocket.service';
import { AuthService } from './auth.service';
import { CommonFunctionsHelper } from './comon.functions.helper';

@Injectable()
export class AuthManager {

  public indexAccessToken: number;

  public indexIdToken: number;

  public indexTokenType: number;

  public stateStr: number;

  public accessTokenStr: string;

  public idTokenStr: string;

  public expiresIn: number;

  public indexExpiresIn: number;

  public expiresInStr = '';

  public userSSO = '-1';

  public userEmail = '';

  public userFirstName = '';

  public userLastName = '';

  public userRole = '';

  public userModules = {};

  public userStatus = '';

  public userCategories = '';

  public tokenExpired = false;

  public idToken = '-1';

  public accessTokenIssueDate: Date;

  public currentDate = new Date();

  public baseurl = window.location.origin;

  public referenceUrl: string;


  // buffer for timing out
  public authenticationSubject: Subject<{}> = new Subject<{}>();

  public authenticationObj;

  public accessToken;

  intialLoggedIn = false;

  private previousUrl: string = undefined;

  private currentUrl: string = undefined;

  constructor(
    private router: Router,
    private authService: AuthService,
    private wS: WebSocketService,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private userInfo: TransferInformationComponent,
    private cS: CommonService,
    private http: HttpClient,
    private shellService: IOSShellAppService,
    private urlRedirectService: UrlRedirectService
  ) {

    this.authenticationSubject.subscribe((value) => {

      this.authenticationObj = value;

    });
    this.indexAccessToken = window.location.href.indexOf('access_token');
    this.indexTokenType = window.location.href.indexOf('&token_type');
    this.indexIdToken = window.location.href.indexOf('id_token');
    this.stateStr = window.location.href.indexOf('&state');
    this.indexExpiresIn = window.location.href.indexOf('expires_in');
    this.accessTokenStr = 'access_token=';
    this.idTokenStr = 'id_token=';
    this.expiresInStr = 'expires_in=';

    // this.isTokenExpired();
    this.currentUrl = this.router.url;

    router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        this.previousUrl = this.currentUrl;

        // console.log("prevURL--",this.previousUrl);
        this.currentUrl = event.url;

        //  console.log("currentURL--",this.currentUrl);

      }

    });

  }

  public getPreviousUrl() {


    // console.log("getPreviousUrl--"+this.previousUrl);
    return this.previousUrl;

  }

  /* NCX-2175 TEST CODE START*/
  validateMediaLinks(url) {

    const mediaExtensions = [...AppConstants.videoExtensions, ...AppConstants.audioExtensions];

    let isMediaLink = false;

    mediaExtensions.forEach((ext) => {

      if (url.includes(ext)) {

        isMediaLink = true;

      }

    });
    return isMediaLink;

  }

  /**
   *  When an attachment is added to the post, S3URLs will be created by the API and will be added to the Post object.(postAttachments - property)
   *  Ex: attachmentPath : "https://qa-api.newsconnect.nbcuni.com/ncx-gateway/api/v1/docs/post/1709669965365/1709669986201-20_national_park.jpg"
   *  Emails will be delivered when a post is published. If the post has attachments then the attachments will be delivered with the S3URLs in the
   * email. When the user clicks the attached images in the email then the S3URLs will be opened in a browser S3URLs with the api domains can't be
   * accessed without JWTToken. JWTToken can't be generated at run time from the browser. Have to access NCX UI to generate the JWTToken and append
   * it to the URL to get the image. In order to open the NCX UI from the S3URL in email, the API domain name of the attachment path will be
   * delivered as UI domain name in the email When we click on the image in the email, the UI URL will be opened and will be redirected to API URL in
   * code with JWTToken. Domain dev.newsconnect.nbcuni.com will to be redirected to dev-api.newsconnect.nbcuni.com Domain qa.newsconnect.nbcuni.com
   * will to be redirected to qa-api.newsconnect.nbcuni.com Domain stg.newsconnect.nbcuni.com will to be redirected to stg-api.newsconnect.nbcuni.com
   * Domain prod.newsconnect.nbcuni.com will to be redirected to prod-api.newsconnect.nbcuni.com
   */
  makeS3GETCall(url) {

    const ncxjwttoken = localStorage.getItem('ncxjwttoken');

    const Authorization = localStorage.getItem('id_token');

    const messageId = this.commonFunctionsHelper.generateUUID();

    let headers;

    if (ncxjwttoken) {

      headers = new HttpHeaders({
        messageId,
        ncxjwttoken,
        Accept: 'application/pdf',
        'Content-Type': 'application/json'
      });

    } else {

      headers = new HttpHeaders({
        messageId,
        Authorization,
        Accept: 'application/pdf',
        'Content-Type': 'application/json'
      });

    }

    const pathWithAttachmentParam = url.split('&attachmentName=');
    
    const isAttachmentParamPresent = pathWithAttachmentParam.length > 1;

    const urlWithoutAttachmentName = pathWithAttachmentParam[0];

    let fullPath = this.urlRedirectService.getCorrespondingAPIDomain(window.location.origin, this.cS.getAPIEndpoint()) + '/ncx-gateway' + urlWithoutAttachmentName;

    fullPath = decodeURIComponent(fullPath);
    
    if (this.validateMediaLinks(fullPath)) {
      
      let pathToRedirect = fullPath;
      
      const token = localStorage.getItem('ncxjwttoken') || localStorage.getItem('id_token');
      
      const tokenType = localStorage.getItem('ncxjwttoken') ? 'ncxjwttoken' : 'id_token';

      pathToRedirect += `?${tokenType}=${token}`;
      pathToRedirect = isAttachmentParamPresent ? `${pathToRedirect}&attachmentName=${pathWithAttachmentParam[1]}` : pathToRedirect;

      this.commonFunctionsHelper.redirectTo(pathToRedirect);
      
      return;

    } else {

      this.http.get(fullPath, { headers, responseType: 'blob' as const }).subscribe((val) => {

        const blob = new Blob([val], {
          type: val.type
        });

        const file = new Blob([blob], { type: val.type });

        const fileURL = URL.createObjectURL(file);

        const browser = Common.getBrowser();

        if (browser.name === 'safari') {

          const encodedURL = encodeURIComponent(`${fileURL}`); // encode and decode added just to skip the vulnerability reported in snyk. No specific

          // use of that here.
          this.commonFunctionsHelper.redirectTo(decodeURIComponent(`${encodedURL}`));
          const link = document.createElement('a');

          link.href = fileURL;
          link.target = '_blank';
          link.download = fileURL.substring(fileURL.lastIndexOf('/') + 1);
          link.click();
          window.URL.revokeObjectURL(link.href);

        } else {

          window.open(fileURL, '_blank');

        }

      }, (err) => {

        console.error('Error in makeS3GETCall event ', err);

      });

    }

  }

  openS3Links(url) {

    let relativeURL = url;

    const search = window.location.href.split('#');

    if (search.length > 2) {

      relativeURL = search[1];

    }
    relativeURL = relativeURL.split('docURL=')[1];
    if (relativeURL) {

      this.makeS3GETCall(relativeURL);

    }

  }

  /* NCX-2175 TEST CODE END*/

  /* Device Registeration Code Start */
  registerIOSDevice(userObj) {

    console.log('registerIOSDevice :::', localStorage.hasOwnProperty('deviceId'));
    const dummystr = '["deviceModel": "Simulator", "deviceToken": "testtoken", "location" : "India" ]';

    if (!localStorage.hasOwnProperty('deviceId')) {


      // // localStorage.setItem('deviceId', dummystr);
      return;

    }
    const deviceObj = JSON.parse(localStorage.getItem('deviceId').replace('[', '{').replace(']', '}'));

    console.log('IOS Device Registered -  ', deviceObj, userObj);
    if (deviceObj && deviceObj.deviceToken) {

      this.shellService.registerIOSDevice(deviceObj, userObj);

    }

  }

  /* Device Registeration Code End */
  public resolve() { // next?: ActivatedRouteSnapshot

    // this.commonFunctionsHelper.clearLocalStorageForAuth();
    // if (environment.environmentName === '' || environment.environmentName === 'qa') {
    //     this.userSSO = '206659955';
    //     this.getUserInfo();
    //     return;
    // }
    this.intialLoggedIn = false;
    this.openS3Links(window.location.href);
    if (this.wS.stompClient !== null) {

      // this.wS.disconnect();
    }

    this.userInfo.getLogingErrorStatus(false, 0, ''); // default value

    // NBC SSO Access Token passed
    if (window.location.href.includes('#access_token')) {

      console.log('AuthManager', 'New Access Token Detected');

      // Split URL on the 'access_token=' in the URL query string because
      // the url could be /#/#access_token= or /#access_token=
      const search = window.location.href.split('access_token=');

      // Get the parameters from the URL query string, appending access_token
      // because it is the first value in the query string payload
      const params = new URLSearchParams(`access_token=${search[1]}`);

      const accessToken = params.get('access_token');

      const idToken = params.get('id_token');

      const expiresIn = params.get('expires_in');

      console.log('AuthManager', { accessToken, idToken, expiresIn });

      this.idToken = idToken;
      this.accessToken = accessToken;
      this.expiresIn = +expiresIn;

      localStorage.setItem('id_token', this.idToken);
      localStorage.setItem('access_token_issuedate', this.currentDate.toString());
      localStorage.setItem('expires_in', this.expiresIn.toString());
      localStorage.setItem('access_token', this.accessToken);
      this.openS3Links(window.location.href);
      this.intialLoggedIn = true;

    }

    if ((!localStorage.getItem('id_token') || localStorage.getItem('id_token') === 'undefined') &&
      (!localStorage.getItem('ncxjwttoken') || localStorage.getItem('ncxjwttoken') === 'undefined')) {

      localStorage.setItem('referenceUrl', window.location.href);
      this.router.navigate(['/disabled-user']);
      this.authService.getOpenIdToken();
      return;

      // this.getUserInfo();

    } else if (localStorage.getItem('ncxjwttoken') && this.userSSO === '-1') {


      // if valid access token exist and user info is not there then get user details only
      const result = this.authService.getDecodedAccessToken(localStorage.getItem('ncxjwttoken'));

      if (!this.isValidIDToken(result)) {

        this.commonFunctionsHelper.clearLocalStorageForAuth();
        this.userInfo.getLogingErrorStatus(true, 10001, 'Error in loading data Not a valid token.');
        this.userInfo.getLogingStatus(true);
        this.router.navigate(['/error']);

        // setTimeout(() => {
        //     this.authService.getOpenIdToken();
        // }, 1000);
        return;

      } else {

        this.userSSO = result.ssoId;
        this.getUserInfo();
        return;

      }

    } else if (this.userSSO === '-1' && !localStorage.getItem('id_token')) {

      this.router.navigate(['']);

    } else {


      // this.router.navigate(['']);
      this.authenticationSubject.next(this.authenticationObj);

    }
    if (this.userInfo.linkSelectedDetailsSource.value === '') {

      this.getUserInfo();

    } else {


      // console.log('this.userInfo.getSelectedLinkDetails ', this.userInfo.linkSelectedDetailsSource.value);
      this.userInfo.getLogingStatus(true);

    }

  }

  private isValidIDToken(result: any) {

    let isValidIdToken = false;

    const oAuthClientId = environment.oAuthClientId;

    if (result && result.jwtClientId.includes(oAuthClientId)) {

      isValidIdToken = true;

    }

    console.log('AuthManager (isValidToken)', { result, isValidIdToken });

    return isValidIdToken;

  }

  public isTokenExpired() {

    if (localStorage.getItem('id_token')) {

      this.accessTokenIssueDate = new Date(localStorage.getItem('access_token_issuedate'));
      const accessTokenExpiryDateTime = new Date(this.accessTokenIssueDate);

      accessTokenExpiryDateTime.setSeconds(this.accessTokenIssueDate.getSeconds() +
        parseInt(localStorage.getItem('expires_in'), 10));
      if (this.currentDate > accessTokenExpiryDateTime) {

        this.tokenExpired = true;

      } else {

        this.tokenExpired = false;

      }

    }

  }

  /**
   * Load user's profile and all permissions
   *
   */
  public getUserInfo() {

    console.log('getUserInfo', this.userSSO);
    localStorage.setItem('ssoId', this.userSSO);

    this.userSSO = (localStorage.hasOwnProperty('userId')) ? localStorage.getItem('userId') : localStorage.getItem('ssoId');

    this.getUserDetails(this.userSSO).subscribe((data: any) => {

      const result: Profile = data as Profile;

      if (result && result.name && result.name.preferredName) {

        let name = '';

        if (result.name.preferredName.split(',').length > 1) {

          name = result.name.preferredName.split(',')[1].trim() + ' ' + result.name.preferredName.split(',')[0];
        
        } else {

          name = result.name.preferredName.split(',')[0];
        
        }

        this.userInfo.getSelectedLinkDetails(name);
        this.userInfo.getAvtarLinkUrl(result.profilePictureURL);

        // result.userAccess need to this one below method
        this.userInfo.setUserFunctionAbility(result.userAccess);
        this.userInfo.userInfoDetails = {
          userId: result.userId.toString(),
          role: result.role
        };

        if (result.role) {

          this.userInfo.getUserRole(result.role);
          this.userInfo.userRole = result.role;
          this.userInfo.getUserDetails(result);
        
        } else {

          this.userInfo.getUserRole('VISITOR'); // for testing only
        
        }

        this.userInfo.getLogingStatus(true);
        const userId = result.userId.toString();

        localStorage.setItem('userId', userId);
        this.userInfo.userLogged.next(userId);
        this.registerIOSDevice(result);
        this.updateLastLoggedInDetails(result);

      }
    
    },
    error => {

      console.log('Error :: ', error);

      // this.userInfo.getLogingErrorStatus(true);
      this.userInfo.getLogingStatus(true);

      // this.router.navigate(['error']);
      //   this.toastService.createMessage('error', error);
    
    }
    );

  }

  public getUserDetails(sso: string) {

    const profileUrl = environment.getProfileAPIURL; // + sso;

    const data: any = {};

    data.sso = sso;
    console.log('profileURL : ', profileUrl);
    return this.cS.serviceRequestCommon('get', profileUrl);

  }

  updateLastLoggedInDetails(userObj) {

    const loggedInFlag = localStorage.getItem('lastLoggedInFlag');

    const profileUrl = environment.getProfileAPIURL;

    const queryStr = `/${userObj.userId}`;

    console.log('updateLastLoggedInDetails Object : ', userObj, this.intialLoggedIn, loggedInFlag);
    if (this.intialLoggedIn || loggedInFlag) {

      const currentUTCDateTime = Time.convertLocalToUTC(new Date());

      userObj.lastLogin = currentUTCDateTime;
      this.cS.serviceRequestCommon('put', profileUrl, queryStr, userObj).subscribe((res) => {

        console.log('Succesfully Update updateLastLoggedInDetails');
        localStorage.removeItem('lastLoggedInFlag');

      }, (err) => {

        console.log('Error in  Updating updateLastLoggedInDetails');

      });

    }

  }

}
