<div class="linked-stories-section">
   <div class="link-story">
      <div class="link-story-label">
         <span>
            <span
               *ngIf="showMandatoryAsterisk"
               class="mandatory">
               *
            </span>
            Add to Story
            <i
               nz-icon
               nzType="question-circle"
               nzTheme="outline"
               nz-tooltip
               nzTooltipPlacement="topRight"
               (click)="toggleTooltip()"
               (mouseover)="showTooltipEvent()"
               (mouseout)="hideTooltip()"
               [nzTooltipTrigger]="tooltipTrigger"
               [nzTooltipVisible]="showToolTip"
               [nzTooltipTitle]="addToStoryToolTipTemplate"
               class="info-icon ml-4"></i>
         </span>
      </div>
      <div [ngClass]="highlightMandatoryText ? 'highlight-story-border' : 'normal-story-border'">
         <nz-input-group
            [nzSuffix]="suffixIconSearch"
            class="input-group">
            <input
               placeholder="Search Story Name"
               nz-input
               [disabled]="disableSearch"
               [(ngModel)]="value"
               class="search-text"
               (keyup)="onChange($event)"
               (ngModelChange)="titleMatch($event)"
               [nzAutocomplete]="auto"
               #storyInput />
            <app-loader-component
               loaderType="SECTION_LOADER"
               [isLoaded]="isSearchLoaded"></app-loader-component>
         </nz-input-group>
         <ng-template #suffixIconSearch>
            <span
               nz-icon
               nzType="search"
               class="search-icon"></span>
         </ng-template>
         <nz-autocomplete #auto>
            <app-loader-component
               loaderType="SECTION_LOADER"
               [isLoaded]="isScrollLoaded"></app-loader-component>
            <div
               class="scrollable-container"
               (scroll)="onScroll($event)">
               <ng-container *ngFor="let option of filteredOptions; let index = index">
                  <nz-auto-option [nzValue]="option">
                     {{ option }}
                  </nz-auto-option>
               </ng-container>
            </div>
         </nz-autocomplete>
      </div>
      <div
         *ngIf="highlightMandatoryText"
         class="mandatory-text">
         <span>{{ mandatoryMsg }}</span>
      </div>
   </div>

   <div
      *ngIf="relatedStoryTitles?.length > 0"
      class="linked-stories">
      <ng-container *ngFor="let index of relatedStoryTitles">
         <div class="story">
            <span
               (click)="postAsPrimaryStory(index.storyId)"
               *ngIf="showCrown"
               [ngClass]="index.isPrimary ? 'primary-crown' : 'crown'">
               <span
                  nz-icon
                  nzType="crown"
                  class="crown-font"></span>
            </span>

            <span class="story-text">
               <div [innerHTML]="index.storyIdTitle | safeHtml"></div>
            </span>

            <span
               class="story-pin"
               [ngClass]="index.isPinnedOnTop && !isLightMode ? 'have-btn-border' : ''">
               <button
                  *ngIf="functionAbility.fa_pin_post && showPin"
                  nz-button
                  nzType="default"
                  nzShape="circle"
                  [ngClass]="
                     index.isPinnedOnTop && isLightMode
                        ? 'push-pin'
                        : index.isPinnedOnTop && !isLightMode
                          ? 'push-pin-dark'
                          : ''
                  "
                  (click)="pinStory(index.storyId, isPinnedOnTop)"
                  nz-icon
                  nzType="pushpin"
                  [nzTheme]="index.isPinnedOnTop ? 'fill' : 'outline'"></button>
            </span>

            <span
               class="ml-8"
               [ngClass]="!functionAbility.fa_pin_post ? 'story-delete' : ''">
               <button
                  nz-button
                  (click)="deleteRelatedStories(index, index.storyId)">
                  <span
                     nz-icon
                     nzType="delete"
                     nzTheme="outline"
                     class="del-color"></span>
               </button>
            </span>
         </div>
      </ng-container>
   </div>

   <div *ngIf="showStoryChangeInfo">
      <div class="crown-info">
         <span>
            <i
               nz-icon
               nzType="info-circle"
               class="crown-icon"
               nzTheme="fill"></i>
            To change the “Main” Story click the crown
         </span>
      </div>
   </div>
</div>

<ng-template #addToStoryToolTipTemplate>
   <span class="tool-tip">{{ tooltip }}</span>
</ng-template>
