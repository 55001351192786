import { HttpEvent, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { CSSThemeService } from '@services/css-theme.service';
import FroalaEditor from 'froala-editor';
import * as lodash from 'lodash';
import { Subject, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { environment } from 'src/environments/environment';
import Tribute from 'tributejs';

@Component({
  selector: 'app-rteditor',
  templateUrl: './rteditor.component.html',
  styleUrls: ['./rteditor.component.scss']
})
export class RteditorComponent implements OnInit, OnChanges {

  // tslint:disable-next-line:no-input-rename
  @Input('rteValue') rteDescription: string;

  @Input() googleDocs;

  @Input() rteHeight = 300;

  @Input() summary = false;

  @Input() isSavedAsDraft;

  @Input() setAutoFocus;

  @Input() isrepliesScreen;

  @Input() operationType;

  @Input() isEmitted = false;

  @Input() input = '';

  @Output() rteFocus: EventEmitter<void> = new EventEmitter<void>();

  @Output() rteUpdated = new EventEmitter();

  @Output() rteEdited = new EventEmitter();

  @Output() rteContentChanged = new EventEmitter();

  @Output() rtePasteContent = new EventEmitter();

  @Output() gFlag = new EventEmitter();

  content: any;

  rteEditor;

  mentionValues: { key: string; value: string; }[];

  getUsers: any;

  userList: any[];

  userListNames: any[];

  toolbarButtons: any;

  toolbarButtonsXSmoreMisc: any;

  toolbarButtonsXSmoreButtons: string[];

  summaryAPIURL: string;

  summaryTemplates: any;

  subject: Subject<any> = new Subject();

  mentionsStr: any;

  id: number;

  editor: any;

  isDarkMode: any;

  constructor(
    private cService: CommonService,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private themeService: CSSThemeService
  ) {

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(value => {

      this.rteUpdated.emit(value);

    });
    this.id = new Date().getMilliseconds();

  }

  options;

  isConfirmLoading = false;

  summaryOptions: any = {
    SM001: 'BREAKING NEWS',
    SM002: 'ELECTION', SM003: 'FIRE',

    SM004: 'MILITARY CONFLICT',
    SM005: 'NATURAL DISASTER',
    SM006: 'TERRORISM',
    SM007: 'WEATHER',
    SM008: 'SNG IN PROGRESS NOTE',
    SM009: 'SNG DIGEST',
    SM010: 'VIDEO PLANNER NOTES',
    SM011: 'IN THE WORKS NOTE',
    SM012: 'NEWS TODAY NOTE'
  };

  ngOnInit() {

    this.getUsers = environment.mentionsAPI;
    this.summaryAPIURL = environment.getSummaryTemplatesURL;
    this.cService.serviceRequestCommon('get', environment.getSettingsAPIURL + localStorage.getItem('userId') + '/settings').
      subscribe((res: any) => {

        this.isDarkMode = res?.enableDarkMode || false;

        if (this.summary) {

          this.fetchSummaryTemplates();

        }
        this.initToolbarOptions();
        this.initCustomButtons();
        this.initialiseEditor();

      });

  }

  ngOnChanges() {

    if ((this.rteDescription === null || this.rteDescription === undefined) && this.rteEditor) {

      this.content = '';
      this.rteEditor.el.innerHTML = '';
      this.rteUpdated.emit(this.rteEditor.el.innerHTML);

    }

    /* Adding Google Doc Link in RTE */
    if (this.googleDocs) {

      this.rteEditor.el.innerHTML = this.rteDescription;
      this.rteUpdated.emit(this.rteEditor.el.innerHTML);
      this.setFocus();

    }
    if (this.rteDescription && this.rteEditor && (!this.isEmitted && !(this.isEmitted === null || this.isEmitted === undefined))) {

      this.rteEditor.el.innerHTML = this.rteDescription;
      this.rteUpdated.emit(this.rteEditor.el.innerHTML); // content to storydesc
      this.isEmitted = true;

    }
    if (this.isrepliesScreen && !(this.isrepliesScreen === null || this.isrepliesScreen === undefined)) {

      if (this.operationType === 'ADD_REPLY') {

        // do nothing

      } else if (this.operationType === 'UPDATE_REPLY') {

        this.rteEditor.el.innerHTML = this.rteDescription;
        this.rteUpdated.emit(this.rteEditor.el.innerHTML);
        this.isEmitted = true;

      }

    }
    if (this.setAutoFocus) {

      this.setFocus();

    }

  }

  initToolbarOptions() {

    if (this.input == 'storyDesc') {

      this.toolbarButtons = [
        'bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'lineHeight', 'textColor',
        'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons', 'spellChecker',
        'insertImage', `googleDoc${this.id}`, 'undo', 'redo'
      ];
      this.toolbarButtonsXSmoreButtons = ['bold', 'italic', 'underline', 'strikeThrough', `googleDoc${this.id}`];
      this.toolbarButtonsXSmoreMisc = [
        'fontFamily', 'fontSize', 'lineHeight', 'textColor', 'backgroundColor',
        'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons',
        'spellChecker', 'insertImage', 'undo', 'redo'
      ];

    } else if (this.input == 'postDesc') {

      this.toolbarButtons = [
        'bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'lineHeight', 'textColor',
        'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons', 'spellChecker',
        'insertImage', `googleDoc${this.id}`, `summaryTemplates${this.id}`, 'undo', 'redo'
      ];
      this.toolbarButtonsXSmoreButtons = ['bold', 'italic', 'underline', 'strikeThrough', `googleDoc${this.id}`];
      this.toolbarButtonsXSmoreMisc = [
        `summaryTemplates${this.id}`, 'fontFamily', 'fontSize', 'lineHeight', 'textColor',
        'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'insertLink',
        'emoticons', 'spellChecker', 'insertImage', 'undo', 'redo'
      ];

    } else if (this.input == 'discussionDesc') {

      this.toolbarButtons = [
        'bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'lineHeight', 'textColor',
        'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons', 'spellChecker',
        'insertImage', `googleDoc${this.id}`, `summaryTemplates${this.id}`, 'undo', 'redo'
      ];
      this.toolbarButtonsXSmoreButtons = ['bold', 'italic', 'underline', 'strikeThrough', `googleDoc${this.id}`];
      this.toolbarButtonsXSmoreMisc = [
        `summaryTemplates${this.id}`, 'fontFamily', 'fontSize', 'lineHeight', 'textColor',
        'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'insertLink',
        'emoticons', 'spellChecker', 'insertImage', 'undo', 'redo'
      ];

    } else if (this.input == 'postStandardGuidance') {

      this.toolbarButtons = [
        'bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'lineHeight', 'textColor',
        'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons', 'spellChecker',
        'undo', 'redo'
      ];
      this.toolbarButtonsXSmoreButtons = ['bold', 'italic', 'underline', 'strikeThrough'];
      this.toolbarButtonsXSmoreMisc = [
        'fontFamily', 'fontSize', 'lineHeight', 'textColor', 'backgroundColor',
        'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons',
        'spellChecker', 'undo', 'redo'
      ];

    } else if (this.input == 'angleDesc') {

      this.toolbarButtons = [
        'bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'lineHeight', 'textColor',
        'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons', 'spellChecker',
        `googleDoc${this.id}`, 'undo', 'redo'
      ];
      this.toolbarButtonsXSmoreButtons = ['bold', 'italic', 'underline', 'strikeThrough'];
      this.toolbarButtonsXSmoreMisc = [
        'fontFamily', 'fontSize', 'lineHeight', 'textColor', 'backgroundColor',
        'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons',
        'spellChecker', `googleDoc${this.id}`, 'undo', 'redo'
      ];

    } else if (this.input == 'topicNotes') {

      this.toolbarButtons = [
        'bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'lineHeight', 'textColor',
        'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'emoticons', 'spellChecker',
        'undo', 'redo'
      ];
      this.toolbarButtonsXSmoreButtons = ['bold', 'italic', 'underline', 'strikeThrough'];
      this.toolbarButtonsXSmoreMisc = [
        'fontFamily', 'fontSize', 'lineHeight', 'textColor', 'backgroundColor',
        'formatOL', 'formatUL', 'clearFormatting', 'emoticons',
        'spellChecker', 'undo', 'redo'
      ];

    } else {

      this.toolbarButtons = [
        'bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'lineHeight', 'textColor',
        'backgroundColor', 'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons', 'spellChecker',
        'insertImage', `googleDoc${this.id}`, 'undo', 'redo'
      ];
      this.toolbarButtonsXSmoreButtons = ['bold', 'italic', 'underline', 'strikeThrough', `googleDoc${this.id}`];
      this.toolbarButtonsXSmoreMisc = [
        'fontFamily', 'fontSize', 'lineHeight', 'textColor', 'backgroundColor',
        'formatOL', 'formatUL', 'clearFormatting', 'insertLink', 'emoticons',
        'spellChecker', 'insertImage', 'undo', 'redo'
      ];

    }

  }

  initTributeForMentions(newRef) {

    // Initialise Tribute to attach to RTE
    const tribute = new Tribute({
      allowSpaces: true,
      menuShowMinLength: 2,

      // values: (text, cb) => {
      //   if (text.length > 1) {
      //   newRef.fetchResultForMentions(text, users => cb(users));
      //   }
      // },
      values: lodash.debounce(newRef.fetchResultForMentions.bind(newRef), 200),
      lookup: (user: any) => {

        return user.value1;

      },
      selectTemplate(item: any) {

        if (item.original.key.startsWith('$$$$')) {

          return '';

        }
        if (typeof item === 'undefined') {

          return null;

        }
        return `<span class="fr-deletable fr-tribute" id="${item.original.key}" >@${item.original.value}</a></span>`;

      },
      menuItemTemplate(item: any) {

        if (item.original.key.startsWith('$$$$')) {

          // tslint:disable-next-line:max-line-length
          return '<div class=\'customMentions\'>' + item.original.value + '</div>';

        } else {

          return item.original.value;

        }

        // return '<img src="' + item.original.avatar_url + '">' + item.string;

      }
    });

    return tribute;

  }

  initCustomButtons() {

    const value = `googleIcon${this.id}`;

    console.log({ value });
    const newRef = this;

    /* Google Doc Custom Link */
    // tslint:disable-next-line:max-line-length
    FroalaEditor.DefineIconTemplate(`googleAntDesignTemplate${this.id}`,
      '<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="google" aria-hidden="true"><path d="M881 442.4H519.7v148.5h206.4c-8.9 48-35.9 88.6-76.6 115.8-34.4 23-78.3 36.6-129.9 36.6-99.9 0-184.4-67.5-214.6-158.2-7.6-23-12-47.6-12-72.9s4.4-49.9 12-72.9c30.3-90.6 114.8-158.1 214.7-158.1 56.3 0 106.8 19.4 146.6 57.4l110-110.1c-66.5-62-153.2-100-256.6-100-149.9 0-279.6 86-342.7 211.4-26 51.8-40.8 110.4-40.8 172.4S151 632.8 177 684.6C240.1 810 369.8 896 519.7 896c103.6 0 190.4-34.4 253.8-93 72.5-66.8 114.4-165.2 114.4-282.1 0-27.2-2.4-53.3-6.9-78.5z"></path></svg>');
    FroalaEditor.DefineIcon(`googleIcon${this.id}`, { NAME: 'smartphone', template: `googleAntDesignTemplate${this.id}` });
    FroalaEditor.DefineIcon(`googleDocIcn${this.id}`, { NAME: 'info' });
    FroalaEditor.RegisterCommand(`googleDoc${this.id}`, {
      title: 'Google Doc',
      focus: false,
      undo: false,
      icon: `googleIcon${this.id}`,
      callback: (event) => {

        console.log('callback custom icon ', event, this.options);

        // this.setFocus();
        this.rteUpdated.emit(this.rteEditor.el.innerHTML);
        setTimeout(() => {

          this.gFlag.emit('true');

        }, 500);

      }
    });

    /* Summary Template Custom Button */
    FroalaEditor.DefineIconTemplate(`summaryIconTemplate${this.id}`, '<label style="padding:12px;font-weight:bold">Summary</label>');
    FroalaEditor.DefineIcon(`summaryIcon${this.id}`, { NAME: 'dropdown', template: `summaryIconTemplate${this.id}` });
    FroalaEditor.RegisterCommand(`summaryTemplates${this.id}`, {
      title: 'Advanced options',
      type: 'dropdown',
      focus: false,
      undo: false,
      icon: `summaryIcon${this.id}`,
      refreshAfterCallback: true,
      options: newRef.summaryOptions,
      callback: (cmd, val) => {

        console.log(cmd, val);
        newRef.bindSummaryTemplate(val);

      },

      // Callback on refresh.
      refresh: (_$btn) => {

        // console.log ('do refresh');
      },

      // Callback on dropdown show.
      refreshOnShow: (_$btn, _$dropdown) => {

        // console.log ('do refresh when show');
        // this.fetchSummaryTemplates();
      }
    });

  }

  setFocus() {

    setTimeout(() => {

      this.rteEditor.selection.setAtEnd(this.rteEditor.$el.get(0));
      this.rteEditor.selection.restore();

      // this.rteUpdated.emit(this.rteEditor.el.innerHTML);

    }, 200);

  }

  bindMentionsDropdown(userList) {

    console.log('TEST  MENTIONS', userList);
    const users = [];

    if (userList && userList.length === 0) {

      return;

    }
    userList.forEach((user: any) => {

      //   users.push({value: user.name.firstName + ' ' + user.name.middleName + ' ' +  user.name.lastName
      // , key: user.userId});
      users.push({ key: user.key, value: user.value });

    });
    users.map(user => user.value1 = this.mentionsStr); // for custom headers
    this.userListNames = users;
    return users;

  }

  bindSummaryOptions(options) {

    let obj = {};

    const optins = Array.from(options);

    optins.forEach((summary: any) => {

      const pair = {};

      pair[summary.summaryId] = summary.summaryName;
      if (!obj || obj === null || obj === undefined) {

        obj = pair;

      } else {

        Object.assign(obj, pair);

      }

    });
    this.summaryOptions = obj;
    this.initToolbarOptions();
    this.initCustomButtons();
    this.initialiseEditor();

  }

  bindSummaryTemplate(summaryId) {

    const obj = this.summaryTemplates.filter(summary => summary.summaryId === summaryId)[0];

    this.rteEditor.el.innerHTML = obj.summaryValue + this.rteEditor.el.innerHTML;
    this.rteUpdated.emit(this.rteEditor.el.innerHTML);
    this.rteEdited.emit(this.rteEditor.el.innerHTML);

  }

  fetchResultForMentions(searchTextValue, cb) {

    this.userList = [];
    const queryStr = `?name=${searchTextValue}`;

    this.mentionsStr = searchTextValue;
    this.cService.serviceRequestCommon('get', this.getUsers, queryStr).subscribe((res: any) => {

      // console.log('fetchUserList success: ', res);
      this.userList = [...res];
      const users = this.bindMentionsDropdown(this.userList);

      console.log('MENTIONS TEST 2 : ', users);
      cb(users);

    }, () => {

      cb([]);

    });

  }

  fetchSummaryTemplates() {

    const queryStr = '/summaryTemplate';

    const templateQueryStr = '/summaryDetails';

    const api1 = this.cService.serviceRequestCommon('get', this.summaryAPIURL, queryStr);

    const api2 = this.cService.serviceRequestCommon('get', this.summaryAPIURL, templateQueryStr);

    forkJoin([api1, api2]).subscribe(([res1, res2]) => {

      this.bindSummaryOptions(res1);
      this.summaryTemplates = JSON.parse(JSON.stringify(res2));

    }, () => {

      this.isConfirmLoading = true;

    });

  }

  showModal(): void {

    this.gFlag.emit('true');

  }

  getKey() {

    return environment.froalaKey;

  }

  uploadToS3Bucket(item, editor, img) {

    const userId = localStorage.getItem('userId');

    const messageId = this.commonFunctionsHelper.generateUUID();

    const Authorization = localStorage.getItem('ncxjwttoken');

    const headers = new HttpHeaders({
      messageId,
      userId,
      ncxjwttoken: Authorization
    });

    const queryStr = '/ncx-gateway/api/v1/gateway/post/' + new Date().getTime() + '/inlineImage';

    const formData = new FormData();

    formData.append('attachmentFile', item as any);
    console.log('uploadToS3Bucket : ', item, queryStr, formData, headers);
    this.cService.post(queryStr, formData, headers).subscribe((event: HttpEvent<{}>) => {

      console.log('TEST SUCCESS ', event);

      // tslint:disable-next-line:no-string-literal
      if (event && event['attachmentPath']) {

        // tslint:disable-next-line:no-string-literal
        const url = event['attachmentPath'].split('/ncx-gateway')[1];

        setTimeout(() => {

          if (img) {

            // this.rteEditor.selection.setBefore(img);
            img.setAttribute('src', event['attachmentPath'] + '?ncxjwttoken=' + localStorage.getItem('ncxjwttoken'));

          } else {

            // tslint:disable-next-line:max-line-length
            editor.image.insert(event['attachmentPath'] + '?ncxjwttoken=' + localStorage.getItem('ncxjwttoken'), false, null, null,
              editor.image.get());

          }

        }, 200);

      }

    });

  }

  public initialiseEditor() {

    const userId = localStorage.getItem('userId');

    const messageId = this.commonFunctionsHelper.generateUUID();

    const Authorization = localStorage.getItem('ncxjwttoken');

    const newRef = this;

    const tribute = this.initTributeForMentions(newRef);

    newRef.isConfirmLoading = true;
    this.options = {
      height: newRef.rteHeight,
      theme: this.isDarkMode === true ? 'dark' : '',
      imageDefaultWidth: 0,
      placeholderText: '',
      charCounterCount: true,
      key: newRef.getKey(),
      attribution: false,

      // tslint:disable-next-line:max-line-length
      imageEditButtons: [
        'imageAlign',
        'imageCaption',
        'imageRemove',
        '|',
        'imageLink',
        'linkOpen',
        'linkEdit',
        'linkRemove',
        '-',
        'imageDisplay',
        'imageStyle',
        'imageAlt',
        'imageSize'
      ],
      toolbarButtons: newRef.toolbarButtons,
      linkAlwaysBlank: true,
      imageUpload: true,
      imageTextNear: true,
      fontSize: ['8', '10', '12', '14', '18', '30', '60', '96'],
      fontSizeDefaultSelection: '12',
      fontSizeUnit: 'px',

      // imageDefaultDisplay: 'inline',
      requestHeaders: {
        messageId,
        userId,
        ncxjwttoken: Authorization,
        Authorization
      },
      lineHeights: {
        '1.0': '1.0',
        '1.15': '1.15',
        '1.5': '1.5',
        '2.0': '2.0',
        '2.5': '2.5',
        '3.0': '3.0'
      },
      paragraphFormatSelection: true,
      paragraphMultipleStyles: true,

      // enter: FroalaEditor.ENTER_BR,
      colorsStep: 7,

      // tslint:disable-next-line:max-line-length
      // wordAllowedStyleProps : ['font-family', 'font-size', 'background', 'color', 'width', 'text-align', 'vertical-align', 'background-color',
      // 'padding', 'margin', 'height', 'margin-top', 'margin-left', 'margin-right', 'margin-bottom', 'text-decoration', 'font-weight', 'font-style',
      // 'text-indent', 'border', 'border-.*'],
      wordPasteKeepFormatting: true,
      wordPasteModal: false,
      pasteAllowedStyleProps: ['font-family', 'font-size', 'color'],
      htmlDoNotWrapTags: ['script', 'style', 'link', 'a', 'p'],

      // wordPasteModal: false,
      toolbarButtonsSM: newRef.toolbarButtons,
      toolbarButtonsMD: newRef.toolbarButtons,
      toolbarButtonsXS: {
        moreText: {
          buttons: newRef.toolbarButtonsXSmoreButtons,
          buttonsVisible: 6
        },
        moreMisc: {
          buttons: newRef.toolbarButtonsXSmoreMisc,
          align: 'right',
          buttonsVisible: 0
        }
      },
      events: {
        initialized() {

          newRef.rteEditor = this;
          setTimeout(() => {

            tribute.attach(this.el);
            newRef.rteEditor.events.on('keydown', (e) => {

              if (e.which === FroalaEditor.KEYCODE.ENTER && tribute.isActive) {

                return false;

              }

            }, true);

            // if (newRef.rteDescription !== undefined && newRef.rteDescription !== null) {
            //   newRef.rteEditor.el.innerHTML = newRef.rteDescription;
            //   newRef.content = newRef.rteDescription;
            //   newRef.rteUpdated.emit(newRef.content);
            //  }
            // newRef.rteEditor.el.blur();

          }, 1000);
          if (newRef.rteDescription !== undefined && newRef.rteDescription !== null) {

            newRef.rteEditor.el.innerHTML = newRef.rteDescription;
            newRef.content = newRef.rteDescription;
            newRef.rteUpdated.emit(newRef.content);

          }

        },
        blur(html) {

          newRef.rteUpdated.emit(newRef.rteEditor.el.innerHTML);

          // console.log('angular testing 1', this.tribute, html.target.innerHTML);

        },
        focus() {

          newRef.rteFocus.emit();

        },
        keydown($event) {

          if ($event && $event.keyCode === 13) {

            console.log('ENTER', newRef.rteEditor.el.innerHTML, $event);
            $event.preventDefault();
            $event.stopPropagation();
            $event.stopImmediatePropagation();

            // newRef.rteUpdated.emit(newRef.rteEditor.el.innerHTML);

          }
          if ($event && $event.keyCode === 8) {

            newRef.rteEdited.emit(newRef.rteEditor.el.innerHTML);

          }

        },

        // enter() {
        // },
        contentChanged() {


          //newRef.rteUpdated.emit(newRef.rteEditor.el.innerHTML);
          newRef.rteEdited.emit(newRef.rteEditor.el.innerHTML);
        }, 'commands.after': function (cmd, param1, param2) {
          newRef.rteEdited.emit(param1);
        },
        'image.beforePasteUpload'(img) {

          console.log('Inside paste method');
          const editor = this;

          const atobSrc = img.currentSrc ? img.currentSrc : img.src;
          //console.log('atobSrc::: '+atobSrc);

          let byteString: string;

          try {

            byteString = window.atob(atobSrc.split(',')[1]);
            //console.log('byteString::: '+byteString);
          } catch (e) {
            console.log('Inside catch :: ' + e);
            let blobString: string;

            if (atobSrc === img.currentSrc) {

              blobString = img.src;

            } else if (atobSrc === img.src) {

              blobString = img.currentSrc;

            }
            //console.log('blobString::: '+blobString);
            byteString = window.atob(blobString.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));

          }
          const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);

          const int8Array: Uint8Array = new Uint8Array(arrayBuffer);

          for (let i = 0; i < byteString.length; i++) {

            int8Array[i] = byteString.charCodeAt(i);

          }
          const blob = new Blob([int8Array], { type: 'image/jpeg' });

          if (blob) {

            const reader = new FileReader();

            newRef.uploadToS3Bucket(blob, editor, img);

          }
          editor.popups.hideAll();
          return false;

        },
        'image.beforeUpload'(files) {

          const editor = this;

          if (files.length) {

            const reader = new FileReader();

            console.log('image.beforeUpload : ', reader, editor);
            newRef.uploadToS3Bucket(files[0], editor, null);

          }
          editor.popups.hideAll();
          return false;

        },
        'image.removed'($img) {

          // this is the editor instance.
          console.log($img, this);
          $img.remove();
          newRef.rteEdited.emit(newRef.rteEditor.el.innerHTML);

        },
        'image.error': (e, editor, error, response) => {

          console.log('image.error ', error);
          if (error) {

            console.log(error);

          }

        },
        'paste.before'() {

          console.log('paste.before ', newRef.rteEditor.el.innerHTML);
          if (newRef.rteEditor.el.innerHTML === '<br>' || newRef.rteEditor.el.innerHTML === '<p><br></p>') {

            newRef.rteEditor.el.innerHTML = '';

          }
          newRef.rteEdited.emit(newRef.rteEditor.el.innerHTML);

        },
        'paste.after'() {

          if (!newRef.isSavedAsDraft) {

            newRef.rtePasteContent.emit(newRef.rteEditor.el.innerHTML);

          }

        }
      }
    };

  }


}
