import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SearchType } from '@models/ncx/global-search';
import { CommonService } from '@services/common-service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  // Close global search box
  public readonly close: Subject<void> = new Subject<void>();

  public close$: Observable<void> = this.close.asObservable();

  // Reset search results
  public readonly reset: Subject<void> = new Subject<void>();

  public reset$: Observable<void> = this.reset.asObservable();

  // Search term changes on the Search page
  public readonly searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public searchTerm$: Observable<string> = this.searchTerm.asObservable();

  // When filters changed on the Search page
  public readonly filterChanged: Subject<void> = new Subject<void>();

  public filterChanged$: Observable<void> = this.filterChanged.asObservable();

  // Search suggestion for all tabs
  suggestedSearch: { show: boolean, keyword: string } = {
    show: false,
    keyword: ''
  };

  // See constructor for initialization
  // Default filters for all tabs (Stories, Angles, People...etc)
  filters = this.getdefaultFilters();


  // Original filters saved in order to more easily reset to default filters
  originalFilters = {
    STORIES: {},
    POSTS: {},
    ANGLES: {},
    GROUPS: {},
    PEOPLE: {}
  };

  constructor(
    private cService: CommonService
  ) { }

  /**
   * Common API call that each tab uses to retrieve search results
   *
   */
  loadSearchResults(queryString: string = '', payload: any = {}) {

    // Add the search term
    let searchQuery = `?searchString=${encodeURIComponent(this.searchTerm.value)}`;

    // Add any additional query parameters
    if (queryString) {

      searchQuery += `&${queryString}`;

    }

    return this.cService.serviceRequestCommon('post', environment.globalSearch, searchQuery, payload);

  }

  /**
   * Store original filters in order to easily reset the filters when necessary
   *
   */
  saveOriginalFilters() {

    Object.keys(this.filters).map((key: string) => {

      this.originalFilters[key] = JSON.parse(JSON.stringify(this.filters[key]));

    });

  }

  /**
   * Reset/Clear specific filters or ALL filters if void/undefined is passed
   *
   */
  clearFilters(tab: SearchType | void) {

    // Clear filters on one tab
    if (tab) {

      this.filters[tab] = JSON.parse(JSON.stringify(this.getdefaultFilters()[tab]));

    } else {

      // Clear filters on all tabs
      // Object.keys(this.filters).map((key: string) => {

      //   this.filters[key] = JSON.parse(JSON.stringify(this.getdefaultFilters()[key]));

      // });

      this.filters = this.getdefaultFilters();

    }

  }

  /**
   * Set the state of the 'Suggested Search' content for each tab
   *
   */
  setSuggestedSearch(suggestion: string) {

    if (!suggestion) {
      this.suggestedSearch.show = false;
      return;

    }

    const suggestedSearch = (suggestion || '').replace(/['"]+/g, '');

    // Remove any quotes from the suggested phrase and convert to lower case to compare to the user's search term
    // e.g. compare 'wildfires' to '"wildfires"'
    const hasSuggestion = suggestedSearch.toLowerCase().trim() !== (this.searchTerm.value || '').toLowerCase().trim();

    this.suggestedSearch.show = hasSuggestion;
    this.suggestedSearch.keyword = suggestedSearch;

  }

  /**
   * Clear suggested search
   *
   */
  clearSuggestedSearch() {

    this.suggestedSearch.show = false;
    this.suggestedSearch.keyword = '';

  }

  /**
   * When user clicks the "Suggested Search" option
   *
   */
  performSuggestedSearch() {

    this.searchTerm.next(this.suggestedSearch.keyword);

    this.clearSuggestedSearch();

  }

  /**
   * Close global search box
   *
   */
  closeSearch() {

    this.close.next();

  }

  getdefaultFilters() {

    return {
      STORIES: {
        following: 'All',
        modificationDate: [],
        sort: 'newestFirst'
      },
      POSTS: {
        modificationDate: [],
        sort: 'newestFirst',
        authorSSO: 0,
        authorName: '',
        isAllMediaChecked: false,
        media: [
          { label: 'Audio', value: 'Audio', checked: false },
          { label: 'Image', value: 'Image', checked: false },
          { label: 'Video', value: 'Video', checked: false },
          { label: 'Other', value: 'Other', checked: false },
          { label: 'PDF', value: 'Pdf', checked: false },
          { label: 'Word', value: 'Word', checked: false }
        ],

        // populated after initFilters() in SeachFiltersComponent
        legal: [],

        // populated after initFilters() in SeachFiltersComponent
        editorial: []
      },
      ANGLES: {
        authorSSO: 0,
        authorName: '',
        sort: 'descending',
        privacy: 'all'
      },
      GROUPS: {
        sort: 'descending'
      },
      PEOPLE: {
        sort: 'ascending'
      }
    };

  }

}
