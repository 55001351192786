<!-- THIS IS THE LEFT-SIDE NAVIGATION CONTENT THAT IS USED FOR BOTH THE DRAWER
			AND THE SIDER NAVIGATION FLY-OUT MENUS -->

<section [ngClass]="{'navigation': true, 'closed': !isNavDrawerOpen}"
         data-component='layout/page-navigation'>

  <div class='header'>
    <h1>
      <a [routerLink]="['/']"
         title='NewsConnect'>
        <img src='/assets/icons/ncx-logo.svg'
             alt='NBC News' />
        <span>NewsConnect</span>
      </a>
    </h1>
  </div>

  <ul nz-menu
      class='menu-list'
      [nzInlineCollapsed]='!isNavDrawerOpen'
      [nzMode]="'inline'"
      [nzTheme]="'dark'">

    <ng-container *ngTemplateOutlet='menuTpl; context: { $implicit: navDisplay }'></ng-container>

    <ng-template #menuTpl
                 let-navDisplay>

      <!-- LOOP OVER ALL MAIN NAVIGATION ITEMS -->
      <ng-container *ngFor='let nav of navDisplay'>

        <li nz-menu-item
            *ngIf="!nav['submenu'] && canViewLinkByRole(nav) && canViewLinkByPage(nav)"
            nz-tooltip
            nzTooltipPlacement='right'
            [ngClass]="{'active': isActiveLink(nav) && !isNavDrawerOpen}"
            [nzTooltipTitle]="nav['tooltip'] || null"
            [nzTooltipMouseEnterDelay]='0.75'
            [nzTooltipTrigger]="isNavDrawerOpen ? null : 'hover'"
            [nzDisabled]='nav.disabled'
            [nzSelected]='isActiveLink(nav)'>

          <i nz-icon
             [nzType]='nav.icon'
             *ngIf='nav.icon'></i>
          <span>

            <!-- Angular Routes -->
            <a *ngIf="nav['routerLink']"
               [routerLink]="nav['routerLink']"
               [target]="nav['target'] || '_self'">{{nav.title}}</a>

            <!-- Standard HREF links -->
            <a *ngIf="nav['href']"
               [href]="nav['href']"
               [target]="nav['target'] || '_self'">{{nav.title}}</a>

            <!-- Custom onClick events -->
            <a *ngIf="nav['onClick']"
               (click)='navOnClick(nav)'>{{nav.title}}</a>

          </span>
        </li>

        <!-- IF SUBMENU, LOOP OVER ALL NAV SUBMENU ITEMS -->
        <li nz-submenu
            *ngIf="nav['submenu'] && canViewLinkByRole(nav)"
            [ngClass]="{'active': isActiveParent(nav) && !isNavDrawerOpen}"
            [nzTitle]='nav.title'
            [nzOpen]='nav.open'
            [nzIcon]='nav.icon'>
          <ul>
            <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: nav['submenu'] }"></ng-container>
          </ul>
        </li>

      </ng-container>

    </ng-template>

    <!-- THIS LINK IS ONLY VISIBLE WHEN IN DEV MODE -->

    <li *ngIf='isLocal'
        nz-menu-item>
      <i nz-icon
         nzType='appstore'></i>
      <span>
        <a [routerLink]="'ncx-dev'">Dev Resources</a>
      </span>
    </li>

  </ul>

</section>
