import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { environment } from '@environments/environment';
import { Stories } from '@models/ncx/story';
import { IFunctionAbility, PERMISSIONS, User } from '@models/users';
import { AuthService } from '@services/auth/auth.service';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { NavigationService } from '@services/navigation-service';
import { PermissionsService } from '@services/profile/permissions.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { UtilityService } from '@services/utility.service';
import { WebSocketService } from '@services/websocket.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-story',
  templateUrl: './view-story.component.html',
  styleUrls: ['./view-story.component.scss']
})
export class ViewStoryComponent implements OnInit, AfterViewChecked, OnDestroy {

  getApiURL: string;

  isMergeStoryVisible = false;

  mergedStory = false;

  followerApi: string;

  customPopOver: boolean;

  showpop: boolean;

  footerHeight = 80;

  profileInDescription: User = {} as User;

  userInfoDetails: any;

  visible = false;

  isCollapsed = true;

  storyId;

  viewStoryEmailTo;

  story;

  isLoaded = false;

  isTokenReplaced = false;

  storyDetails = null as Stories;

  slugDetails = null;

  isinfocenterVisible = false;

  topics = '';

  tags = '';

  draftMessage = 'This document is a draft and will not be visible to others until you Publish it.';

  storyTitle = 'Example title for a story that would extend past here and would keep going to the edge of blah, blah, blah';

  storyDescription = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare massa at nunc egestas bibendum. Suspendisse potenti. Donec tristique dolor mi, quis porttitor sem auctor eget. In magna ipsum, euismod sed bibendum nec, consectetur quis elit. Vivamus pulvinar eu purus eget mattis. Praesent vulputate rhoncus erat in fringilla. Aliquam ultricies libero elit, ac congue nisl hendrerit luctus. Praesent non odio eu tellus consectetur porta. Pellentesque et dui scelerisque, tristique erat nec, sagittis massa. Nam vel sollicitudin est. Phasellus in tortor consequat, vulputate nibh ut, pretium orci. Vivamus efficitur quam odio, nec eleifend ante euismod sed. Aenean auctor mattis blandit. Sed facilisis justo vel urna mollis, id molestie diam mollis. Vivamus iaculis pharetra urna, ut pellentesque augue. Praesent vitae tristique arcu. Fusce tellus nisl, ultricies in ultrices vel, porta at orci. Aenean facilisis leo pretium sapien tincidunt, nec euismod lacus dapibus. Maecenas egestas est sit amet hendrerit malesuada.';

  public functionAbility = {} as IFunctionAbility;

  subscriptionsWs: Subscription = new Subscription();

  breakPointSubscription: Subscription = new Subscription();

  routerSubscription: Subscription = new Subscription();

  @ViewChild('storyDetailsContent') storyDetailsContent: ElementRef;

  @ViewChild('storyDescContent', { static: false }) storyDescContent: ElementRef;

  @ViewChild('standardguidance') standardGuidance: ElementRef;

  storyDetailsContentBodyHeight: number = 0;

  @HostListener('window:resize', ['$event'])
  onResize() {

    this.windowResizeEvent();

  }

  deleteFlag: boolean;

  isSlackBlockedSubscription: boolean;

  domainName: any;

  constructor(
    private router: ActivatedRoute,
    private modal: NzModalService,
    private cService: CommonService,
    private authService: AuthService,
    private permissions: PermissionsService,
    private reRoute: Router,
    private uS: UtilityService,
    private toastService: ToastService,
    private navigationService: NavigationService,
    private tI: TransferInformationComponent,
    private titleService: Title,
    private breakpointService: BreakpointService,
    private wS: WebSocketService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.getApiURL = environment.getStoriesAPI;
    this.followerApi = environment.storyFollower;
    this.routerSubscription = this.router.params.subscribe(
      (params: Params) => {

        const id = params.storyId;

        this.storyId = id.substr(1);
        this.getStory(this.storyId);

      });
    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });
    this.userInfoDetails = {
      userId: this.tI.userInfoDetails.userId,
      role: this.tI.userInfoDetails.role
    };

    // Close the right-hand drawer if the browser is minimized to handset and the drawer is open
    this.breakPointSubscription = this.breakpointService.isMobile.subscribe((isMobile: boolean) => {

      if (isMobile && !this.isCollapsed) {

        this.isCollapsed = true;

      }

    });
    this.domainName = this.getDomainName();

    //After the story is merged the page will be redirected to Story-Landing. Web Scoket update need not to be executed.
    this.subscriptionsWs = this.wS.doSocketData$.subscribe((data) => {

      if (!this.mergedStory) {

        console.log('Web socket notification received. Calling refreshStoryDetails event in view story');
        this.refreshStoryDetails(data);

      }

    });

  }

  windowResizeEvent() {

    const storyDescContent = (this.storyDescContent?.nativeElement as HTMLElement)?.getBoundingClientRect();

    this.storyDetailsContentBodyHeight = window.innerHeight - storyDescContent?.top - this.footerHeight;

    document.documentElement.style.setProperty('--story-desc-height', this.storyDetailsContentBodyHeight + 'px');

  }

  ngAfterViewChecked() {

    this.windowResizeEvent();
    this.changeDetector.detectChanges();

  }


  getDomainName() {

    return this.uS.getMailURL();

  }


  warning(): void {

    this.deleteFlag = true;
    this.modal.warning({
      nzTitle: 'Do you want to delete this story? ',
      nzContent: 'The story and all associated posts will be removed.',
      nzClosable: true,
      nzMaskClosable: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {

        this.deleteFlag = false;
        this.deleteStory('true');

      },
      nzOnCancel: () => {

        this.deleteFlag = false;

      }
    });

  }

  blockFromSlack() {

    if (this.storyDetails) {

      this.storyDetails.isSlackBlockedSubscription = !this.isSlackBlockedSubscription;
      const queryStr = `/update/${this.storyDetails.storyId}`;

      const payload = {
        storyUpdate: [
          {
            key: 'isSlackBlockedSubscription',
            value: !this.isSlackBlockedSubscription
          }
        ]
      };

      this.cService.serviceRequestCommon('put', this.getApiURL + queryStr, '', payload).subscribe((val: any) => {

        console.log(`SUCCESS: ${this.getApiURL}`, val);
        this.isLoaded = true;
        if (val.isStoryPublished) {

          this.toastService.createMessage('success', 'The Story has Been Successfully Updated');

        } else {

          this.toastService.createMessage('success', 'The Draft has Been Successfully Updated');

        }

      },
        (err) => {

          this.isLoaded = true;
          this.toastService.createMessage('error', err);

        }
      );

    }

  }

  get canEditStory(): boolean {

    return this.permissions.hasPermissionTo(PERMISSIONS.EDIT_STORY, { createUser: this.storyDetails?.createUser });

  }

  isEdit(_event: any): void {

    this.reRoute.navigate(['ncx/create-story/:' + this.storyId]);

  }

  viewMergeStory() {

    this.isMergeStoryVisible = true;

  }

  openInfoCenter() {

    this.isinfocenterVisible = true;

  }

  closeMergeStory() {

    this.isMergeStoryVisible = false;
    this.mergedStory = true;

  }

  closeInfoCenter() {

    this.isinfocenterVisible = false;

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  bindStoryDetails(res) {

    if (res && res.isDeleted) {

      this.toastService.createMessage('error', `Requested Story doesn't exist. Redirecting to stories dashboard.`);

      setTimeout(() => {

        this.reRoute.navigate(['ncx/stories-dashboard']);

      }, 500);

      return;

    }

    if ((res && res.storyAccess === 'private') && !(((res.createUser && res.createUser.userId === Number(localStorage.getItem('userId')))
      || (this.functionAbility.fa_access_private_story)))) {

      this.toastService.createMessage('error', 'You dont have access to the story');
      setTimeout(() => {

        this.reRoute.navigate(['ncx/stories-dashboard']);

      }, 500);
      return;

    }

  }

  getStory(storyId) {

    this.isLoaded = false;

    const queryStr = `/${storyId}`;

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      this.bindStory(res);

    }, err => {

      if (err === 'STORY-003') {

        this.toastService.createMessage('error', 'You dont have access to the story');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/stories-dashboard']);

        }, 500);

      } else {

        this.toastService.createMessage('error', err);

      }

    }).add(() => {

      this.isLoaded = true;

    });

  }

  bindStory(res) {

    this.storyDetails = res;

    this.viewStoryEmailTo = 'mailto:story+' + (this.storyDetails.storyId).toString().trim() + '@mail.' +
      this.domainName.toString().trim();
    this.titleService.setTitle(res.storyTitle);

    setTimeout(() => {

      this.storyDetails.storyContent = this.authService.addJWTTokenToLink(this.storyDetails.storyContent, 'img'); //JWT Token has to be added to the
      // img S3 link to download the file from S3

      /**
       * .fr-deletable class added to the description if a profile is added to the froala editor - @username
       */
      const part = this.storyDetailsContent?.nativeElement?.querySelectorAll('.fr-deletable');

      part?.forEach((anchor: HTMLAnchorElement) => {

        anchor.addEventListener('mouseenter', this.handleAnchorClick.bind(this));
        anchor.addEventListener('click', this.handleAnchorClick.bind(this));

      });

    }, 500);

    this.topics = res.topics && Object.keys(res.topics).length ? Object.values(res.topics).join(',') : '';
    this.tags = Array.isArray(res.storyContentTags) ? res.storyContentTags.join(', ') : '';

    this.slugDetails = this.storyDetails.storySlug;
    this.storyTitle = this.storyDetails.storyTitle;

    this.isSlackBlockedSubscription = this.storyDetails?.isSlackBlockedSubscription;


    this.navigationService.overridePageTitle(this.storyTitle);

    if (this.slugDetails === null) {

      this.slugDetails = {
        who: '',
        what: '',
        where: '',
        when: ''
      };

    }

    if (!this.breakpointService.isMobile.value) {

      this.isCollapsed = false;

    }

    this.bindStoryDetails(res);

  }

  /**
   *
   * Listens for click events on HTML content in Story Description
   */
  handleAnchorClick(event) {

    this.customPopOver = false;
    this.showpop = true;
    if (event && event.srcElement && event.srcElement.id && event.srcElement.id.startsWith('user-')) {

      const userId = event.srcElement.id.split('-')[1];

      this.profileInDescription.userId = userId;

    } else {

      return;

    }
    setTimeout(() => {

      const element: HTMLElement = document.getElementById('triggerClick') as HTMLElement;

      element.style.left = (event.layerX + 25) + 'px';
      element.style.top = (event.layerY + 110) + 'px';
      this.customPopOver = true;

      element.click();

    });

  }

  deleteStory(event: any) {

    if (event === 'true') {

      this.isLoaded = false;

      const queryStr = `/${this.storyId}`;

      this.cService.serviceRequestCommon('delete', this.getApiURL, queryStr).subscribe((val) => {

        if (val === null) {

          this.toastService.createMessage('success', 'The story has been successfully deleted.', { nzDuration: 5000 });

          this.reRoute.navigate(['ncx/stories-dashboard']);
        }

      },
        () => {

          this.toastService.createMessage('error', 'Error while deleting the story. Please try again.');

        }).add(() => {

          this.isLoaded = true;

        });

    }

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

  get isXsmall(): boolean {

    return this.breakpointService.breakpoints.value['xsmall'];

  }

  get isSmall(): boolean {

    return this.breakpointService.isSmall.value;

  }

  get name(): string {

    return Common.formatName(this.storyDetails.createUser);

  }

  refreshStoryDetails(data) {

    if (data.story && data.story.storyId == this.storyId && data.story.isDeleted == false) {

      this.bindStory(data.story);

    } else if (data.story && data.story.storyId == this.storyId && data.story.isDeleted == true) {

      this.reRoute.navigate(['ncx/stories-dashboard']);

    }

  }

  ngOnDestroy(): void {

    this.subscriptionsWs.unsubscribe();
    this.breakPointSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();

  }

}
