
type UserRole = 'ADMIN' | 'STANDARDS' | 'SR_EDITOR' | 'EDITOR' | 'JOURNALIST' | 'READ_ONLY';

export const UserRoles = {
  Admin: 'ADMIN' as UserRole,
  Standard : 'STANDARDS' as UserRole,
  SeniorEditor: 'SR_EDITOR' as UserRole,
  Editor: 'EDITOR' as UserRole,
  Journalist: 'JOURNALIST' as UserRole,
  ReadOnly: 'READ_ONLY' as UserRole
};