<!-- RESULT COUNT -->
<div
   *ngIf="isLoaded || (total > 0 && !isLoaded)"
   class="result-count">
   {{ resultCount }}
   <button
      *ngIf="searchService.suggestedSearch.show"
      (click)="searchService.performSuggestedSearch()"
      class="suggested-search">
      Did you mean {{ searchService.suggestedSearch.keyword }}?
   </button>
</div>

<app-scroll-container>
   <ng-template bodyTemplate>
      <div
         [ngClass]="{
            'search-result-container': true,
            'no-results': !searchResults.length,
         }"
         infiniteScroll
         [infiniteScrollDistance]="scrollDistance"
         (scrolled)="onScrollDown()"
         [scrollWindow]="false"
         data-scrollable-container
         [attr.data-component]="'ncx/search/page/results/posts'">
         <div class="search-result-body">
            <!-- LOADER -->
            <app-loader-component
               loaderType="SECTION_LOADER"
               [isLoaded]="isLoaded"></app-loader-component>

            <!-- CARD TO DISPLAY SEARCH RESULTS -->
            <ncx-post-search
               *ngFor="let post of searchResults; let index = index"
               [post]="post"
               (sharePost)="sharePost($event)"
               (viewPost)="viewPost($event, index)"
               (viewStory)="viewStory($event, index)"></ncx-post-search>

            <div *ngIf="!searchResults.length && isLoaded">
               <nz-empty [nzNotFoundContent]="contentTpl">
                  <ng-template #contentTpl>
                     <span>No Posts Found</span>
                  </ng-template>
               </nz-empty>
            </div>
         </div>
      </div>
   </ng-template>
</app-scroll-container>

<!-- SHARE POST MODAL -->
<app-share-post
   [isVisible]="showShareModal"
   [postId]="sharePostId"
   [message]="shareMessage"
   (closedSharedContent)="closeShareModal()"></app-share-post>
