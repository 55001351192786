import { DOCUMENT } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { AppConstants } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { IFunctionAbility, PERMISSIONS } from '@models/users';
import { TranslateService } from '@ngx-translate/core';
import { AppJsonDataService } from '@services/app-json.service';
import { AppSingletonService } from '@services/app-singleton.service';
import { AuthService } from '@services/auth/auth.service';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { CSSThemeService } from '@services/css-theme.service';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { NavigationService } from '@services/navigation-service';
import { PermissionsService } from '@services/profile/permissions.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { TranslationService } from '@services/translation-service.service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { WebSocketService } from '@services/websocket.service';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { GenericDashboardComponent } from './ncx/stories/dashboard/generic-dashboard/generic-template-section.component';
import { TransferInformationComponent } from './shared/components/TransferInformation/TransferInformation.component';

declare let gtag: (...args: any) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {

  public loadSideMenu = false;

  // public menuList = [];

  username;

  loading = false;

  title = 'NewsConnect';

  timing: any;

  isCollapsed = false;

  storyId: any;

  avtarImgUrl: any = '';

  avtarImgUrlWithToken: any = '';

  alertList: any = [];

  ga4TrackingId;

  popType = 'hover';

  errorDetails: any;

  subscription: Subscription;

  alertCount: any = 0;

  pageCode: any;

  // Main title in the page header
  pageHeader: string = 'NewsConnect';

  public settingResponseData: any = {};

  public functionAbility = {} as IFunctionAbility;

  private subs = new SubSink();

  @ViewChild(GenericDashboardComponent) child1: GenericDashboardComponent;

  public constructor(
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private routerModule: RouterModule,
    private route: ActivatedRoute,
    private userInfo: TransferInformationComponent,
    private jsonService: AppJsonDataService,
    private aSS: AppSingletonService,
    private changeDetector: ChangeDetectorRef,
    private tService: TranslationService,
    private Translate: TranslateService,
    private cService: CommonService,
    private wS: WebSocketService,
    private toastService: ToastService,
    private gaService: GoogleAnalyticsEventService,
    private authService: AuthService,
    private themeService: CSSThemeService,
    private titleService: Title,
    private globalSearchService: GlobalSearchService,
    private permissionsService: PermissionsService,
    private breakpointService: BreakpointService,
    private navigationService: NavigationService,
    private urlRedirectService: UrlRedirectService,
    @Inject(DOCUMENT) private document: Document,
  ) {

    this.Translate.use(this.tService.setDefaultLanguage());

    this.ga4TrackingId = this.getGa4TrackingId();
    this.gaService.loadGoogleAnalytics(this.ga4TrackingId);

    this.subs.sink = this.userInfo.currentLinkDetails.subscribe(username => {

      this.username = username;

      // Set the user ID using signed-in user_id
      gtag('set', { user_id: localStorage.getItem('ssoId') });
      gtag('config', this.ga4TrackingId);

    });

    //Page title logic is now located in NavigationService
    this.navigationService.pageTitle.subscribe((title: string) => {

      this.pageHeader = title;

    });

    this.subs.sink = this.userInfo.avatarPicUrlDetails.subscribe(avtarLink => {

      const ncxjwttoken = localStorage.getItem('ncxjwttoken');

      if (avtarLink) {

        this.avtarImgUrl = avtarLink;
        this.avtarImgUrlWithToken = this.urlRedirectService.getRedirectAPIURL(
          avtarLink + '?random+\=' + Math.random() + `&ncxjwttoken=${ncxjwttoken}`);
      }

    });

    this.subs.sink = this.userInfo.currentLoginDetails.subscribe(status => {

      this.loading = status !== true;

    });

    this.subs.sink = this.userInfo.linkLoggedInErrSource.subscribe(err => {

      this.errorDetails = err;

    });

    this.subs.sink = this.userInfo.alertCount.subscribe(count => {

      this.alertCount = (count) ? count : 0;

    });

    this.userInfo.getSelectedLinkDetails(this.username);

    this.subs.sink = this.router.events.subscribe(event => {

      if (event instanceof NavigationStart) {

        // Close search drawer whenever navigating
        this.globalSearchService.closeSearch();

        console.log('Clearing lock...');
        if (window.location.href.includes('/ncx/post/:')) {

          this.cService.serviceRequestCommon('get', environment.getPostApi, '/' + window.location.href.split('/ncx/post/:')[1]).
            subscribe((response: any) => {

              const queryStr = `/${response.postVersionGroupId}/lock/delete`;

              this.cService.serviceRequestCommon('post', environment.getPostApi, queryStr).subscribe((res: any) => {

                console.log('post lock released: ' + res);

              });

            });

        } else if (window.location.href.includes('/ncx/create-discussion?groupId')) {

          const queryStr = `/${window.location.href.split('=')[2]}`;

          this.cService.serviceRequestCommon('get', environment.getDiscussionURL, queryStr).subscribe((res: any) => {

            const str = `/${res.discussionVersionGroupId}/lock/delete`;

            this.cService.serviceRequestCommon('post', environment.getDiscussionURL, str).subscribe((resp: any) => {

              console.log('discussion lock released: ' + resp);

            });

          });

        }

      }

    });

    this.subs.sink = this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        gtag('config', this.ga4TrackingId, { page_path: event.urlAfterRedirects });
        gtag('set', { user_id: localStorage.getItem('ssoId') });
        gtag('event', 'page_view', { page_path: event.urlAfterRedirects, send_to: this.ga4TrackingId });

      }

    });

    this.subs.sink = this.router.events.subscribe(() => {

      this.router.events.subscribe(() => {

        this.cService.clearAddColumnData.next('true');

      });

    });

    this.viewContainerRef = viewContainerRef;

    this.subs.sink = this.wS.doSocketData$.subscribe(data => {
      
      if (data.pageCode === 'STORY' || data.pageCode.indexOf('GROUPS_') === 0) {
        this.updateDashBoard(data);
        this.getAlertCount(); // to get the latest alert count when there is a web socket notification
      }
    });

  }

  public getJsonData() {

    this.jsonService.getNcxMenuJson().subscribe(
      (data: any) => {

        console.log('Json Data', data);
        this.loadSideMenu = true;
        this.aSS.nccXJsonStore = data;
        this.userInfo.getSettingsDetails(data);

      },
      (error: any) => {

        console.log(error);

      },
    );

  }

  logout() {

    localStorage.clear();

    localStorage.setItem('isCollapsed', String(this.cService.isNavDrawerClosed));

    const oAuthLogoutUrl = environment.oAuthLogoutUrl;

    window.location.replace(oAuthLogoutUrl);

  }

  ngOnInit() {

    this.getJsonData();

    this.userInfo.userLogged$.subscribe(userId => {

      if (userId != '') {

        this.getUserSettings();

        this.getAlertCount();

      }

    });

  }

  getUserSettings() {

    this.cService.serviceRequestCommon('get', environment.getSettingsAPIURL + localStorage.getItem('userId') + '/settings').
      subscribe((res: any) => {

        this.settingResponseData = res;
        const isDarkMode = res?.enableDarkMode || false;

        this.themeService.loadTheme(isDarkMode ? 'dark' : 'default');

      }, err => {

        this.toastService.createMessage('error', err);

      });

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  ngAfterContentChecked() {

    this.changeDetector.detectChanges();

  }

  registerIOSDevice(deviceId) {

    console.log('IOS Device Registered ', deviceId);
    this.toastService.createMessage('success', 'The Device Has Been Succesfully registered ' + deviceId);

  }

  getGa4TrackingId() {

    return environment.ga4TrackingId;

  }

  checkScreen() {

    if (window.innerWidth >= 280 && window.innerWidth <= 1024) {

      this.popType = 'click';
      return {
        popType: 'click',
      };

    } else {

      this.popType = 'hover';
      return {
        popType: 'hover',
      };

    }

  }

  /**
   * Get the latest number of Alerts
   *
   */
  getAlertCount() {

    const url = '/api/v1/gateway/alert/unread/count';

    this.cService.serviceRequestCommon('get', url, '', null).subscribe((res: any) => {

      if (res) {

        this.userInfo.setAlertCount(res);

      }

    }, err => {

      console.log(`ERR: ${url}`, err);

    });

  }

  /**
   * View Alerts page
   *
   */
  viewAlerts() {

    this.router.navigate(['ncx/alerts']);

  }

  createStoryOrPost() {

    // tslint:disable-next-line:max-line-length
    if (this.router.url.startsWith(AppConstants.local.Url_storyLanding) ||
      this.router.url.startsWith(AppConstants.local.Url_viewStory)) {

      const storyId = this.router.url.split(':');

      this.router.navigateByUrl(`/ncx/post?storyId=${storyId[1].replace('/standards', '')}`);

    } else {

      this.router.navigateByUrl('/ncx/create-story');

    }

  }

  /* API's for GET and PUT Notification Alerts Start*/
  private setWebSocketConnectionPage() {

    this.pageCode = 'GENERIC';
    switch (this.router.url) {

      case '/':
      case '/ncx/activity/:All':
      case '/ncx/feeds':
      case '/ncx/stories-dashboard':
        this.pageCode = 'STORY';
        break;
      case '/ncx/activity/:Hot':
        this.pageCode = 'STORY_HOT';
        break;
      case '/ncx/activity/:Standards':
        this.pageCode = 'STORY_STANDARDS';
        break;
      case '/ncx/alerts':

        // pageCode = 'ALERTS';
        break;
      case '/ncx/group-landing':
        this.pageCode = 'GROUPS';
        break;
      default:
        this.pageCode = this.checkForLanding();

    }
    return this.pageCode;

  }

  private checkForLanding() {

    if (this.router.url.includes('/ncx/landing-story/')) {

      const id = this.router.url.split(':');

      return 'STORY_' + id[1];

    } else if (this.router.url.includes('/group-details/')) {

      const id = this.router.url.split(':');

      return 'GROUPS_' + id[1];

    }
    return 'GENERIC';

  }

  updateDashBoard(data) {

    const userId = localStorage.getItem('userId');

    if (data && data.alert && data.alert.alertId && data.notificationTarget && Number(data.notificationTarget) === Number(userId)) {

      if (this.alertList && this.alertList.length) {

        this.alertList.splice(-1, 1);
        this.alertList = (this.alertList && this.alertList.length >= 1) ? [data.alert, ...this.alertList] : data.alert;

      } else {

        this.alertList = data.alert;

      }

    }

  }

  /**
   * Toggle open/close of left-side navigation
   *
   */
  toggleNavigation(): void {

    const collapsed = this.cService.isNavDrawerClosed.value;

    this.cService.isNavDrawerClosed.next(!collapsed);

  }

  onCloseNavigation() {

    this.cService.isNavDrawerClosed.next(true);

  }

  onToggleNavigation(event: any) {

    console.log('ON TOGGLE NAV: ', event);

  }

  /**
   * When BentoBox is visible
   *
   * @param isVisible
   */
  onBentoBoxVisibilityChange(isVisible: boolean) {

    if (isVisible) {
      this.gaService.eventEmitter('Bento Box', 'Open Bento Box', '');
    }

  }

  get canCreateStory(): boolean {

    return this.permissionsService.hasPermissionTo(PERMISSIONS.CREATE_PUBLIC_STORY) ||
      this.permissionsService.hasPermissionTo(PERMISSIONS.CREATE_PRIVATE_STORY);

  }

  get isNavigationClosed(): boolean {

    return this.cService.isNavDrawerClosed.value;

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
