<section class='support-tab-body'
         [attr.data-component]="'ncx/support/training'">

  <app-collapsable title='Getting Started with NewsConnect'
                   iconTheme='outline'
                   icon='star'>

    <ul>
      <li>This lesson will teach you how to get started with NewsConnect (NCX) – the newsgathering tool for
          journalists
          across this news organization.
      </li>
      <li>We'll cover the basic terms and functions of NCX. You will learn how to sign into NCX on your desktop, set
          up
          the email alerts you get from NCX and load the app onto your mobile device.
      </li>
      <li>You'll learn about news alerts and how to follow and contribute to stories – from your desktop, from email
          and
          on the go.
      </li>
      <li>By the time you're finished, you should understand how NCX works. You should also feel comfortable using NCX
          to keep informed and contribute to stories.
      </li>
    </ul>

    <p>Get started with NBCU Academy: <a href='https://nbcuacademyaccess.nbcuni.com/lesson/38319-get-connected-getting-started-with-newsconnect/login'
                                         (click)='trackOutboundLink("https://nbcuacademyaccess.nbcuni.com/lesson/38319-get-connected-getting-started-with-newsconnect/login")'
                                         rel='noopener noreferrer'
                                         target='_blank'>Visit now to begin your lessons</a></p>

  </app-collapsable>

  <app-collapsable title='NewsConnect Training Videos'
                   iconTheme='outline'
                   icon='video-camera'>

    <p><span class='bold'>NewsConnect Custom Homepage</span></p>

    <div class='ml-16'>
      <p>How to customize your homepage presentation. <a href='https://nbcuni-my.sharepoint.com/:v:/g/personal/206545249_tfayd_com/EYjKBnvgn1RIqiX_-0RWsyQBLLdwsi2vW0KJ0-uQJ9j7_g?e=enKwtJ'
                                                         (click)='trackOutboundLink("https://nbcuni-my.sharepoint.com/:v:/g/personal/206545249_tfayd_com/EYjKBnvgn1RIqiX_-0RWsyQBLLdwsi2vW0KJ0-uQJ9j7_g?e=enKwtJ")'
                                                         rel='noopener noreferrer'
                                                         target='_blank'>Watch video</a>.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>Send NewsConnect Video to WG01/06</span></p>

    <div class='ml-16'>
      <p>How to send videos from NewsConnect to WG's. <a href='https://nbcuni-my.sharepoint.com/:v:/g/personal/206545249_tfayd_com/EQAG7H4wPy5KvH5EiCFS3OsBxGFSL7RmEkpOtT1Lcc3PJg'
                                                         (click)='trackOutboundLink("https://nbcuni-my.sharepoint.com/:v:/g/personal/206545249_tfayd_com/EQAG7H4wPy5KvH5EiCFS3OsBxGFSL7RmEkpOtT1Lcc3PJg")'
                                                         rel='noopener noreferrer'
                                                         target='_blank'>Watch video</a>.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>Darkmode & Relevance Search</span></p>

    <div class='ml-16'>
      <p>How to configure your NewsConnect settings for Darkmode and Relevance. <a href='https://nbcuni-my.sharepoint.com/:v:/g/personal/206545249_tfayd_com/EfGdyeGYTyhBtT_Phmpv_CUBnXyQoziLaV1tWEzBAQkf3Q'
                                                                                   (click)='trackOutboundLink("https://nbcuni-my.sharepoint.com/:v:/g/personal/206545249_tfayd_com/EfGdyeGYTyhBtT_Phmpv_CUBnXyQoziLaV1tWEzBAQkf3Q")'
                                                                                   rel='noopener noreferrer'
                                                                                   target='_blank'>Watch video</a>.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>NewsConnect Stories/Slack Integration</span></p>

    <div class='ml-16'>
      <p>Slack integration within NewsConnect stories. <a href='https://nbcuni.sharepoint.com/:v:/s/news-group-newsroom-product/EYwhYx_vA95Bq4pZEjSVCogBGY-yjVtewWnHL1zcWMCLog?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D&e=fNmQzy'
                                                          (click)='trackOutboundLink("https://nbcuni.sharepoint.com/:v:/s/news-group-newsroom-product/EYwhYx_vA95Bq4pZEjSVCogBGY-yjVtewWnHL1zcWMCLog?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D&e=fNmQzy")'
                                                          rel='noopener noreferrer'
                                                          target='_blank'>Watch video</a>.</p>
    </div>

    <nz-divider></nz-divider>

    <p><span class='bold'>NewsConnect Groups/Slack Integration</span></p>

    <div class='ml-16'>
      <p>Slack integration within NewsConnect Groups. <a href='https://nbcuni-my.sharepoint.com/:v:/g/personal/206545249_tfayd_com/Eav9htFMKNZNpj0Q0P_aPQQBF4h7fC-8YAVeXKR7NOg0xw?e=eATgM6'
                                                         rel='noopener noreferrer'
                                                         (click)='trackOutboundLink("https://nbcuni-my.sharepoint.com/:v:/g/personal/206545249_tfayd_com/Eav9htFMKNZNpj0Q0P_aPQQBF4h7fC-8YAVeXKR7NOg0xw?e=eATgM6")'
                                                         target='_blank'>Watch video</a>.</p>
    </div>

  </app-collapsable>

  <app-collapsable title='Training Signup'
                   iconTheme='outline'
                   icon='solution'>

    <p>Register for upcoming trainings and receive communications related to your selections as those sessions become available. <a href='https://forms.office.com/pages/responsepage.aspx?id=-SY1T9aXLUGTOk4wpzEQ9G3smpXJB2dAgDe6ksKYDYhUQ0Y2NTZWVk1OMEZZNEw3V0cxRVVBUDBJOS4u'
                                                                                                                                    (click)="trackOutboundLink('https://forms.office.com/pages/responsepage.aspx?id=-SY1T9aXLUGTOk4wpzEQ9G3smpXJB2dAgDe6ksKYDYhUQ0Y2NTZWVk1OMEZZNEw3V0cxRVVBUDBJOS4u')"
                                                                                                                                    rel='noopener noreferrer'
                                                                                                                                    target='_blank'>Register here</a>.</p>

  </app-collapsable>

  <app-collapsable title='DEI Words Guide'
                   iconTheme='outline'
                   icon='sort-ascending'>

    <p>NBCU News Group Diversity, Equity & Inclusion Words Usage Guide. <a href='https://nbcuacademyaccess.nbcuni.com/lesson/472784-nbcu-news-group-diversity-equity-amp-inclusion'
                                                                           (click)="trackOutboundLink('https://nbcuacademyaccess.nbcuni.com/lesson/472784-nbcu-news-group-diversity-equity-amp-inclusion')"
                                                                           rel='noopener noreferrer'
                                                                           target='_blank'>View guide.</a></p>

  </app-collapsable>

  <app-collapsable title='NBCU News Workflows & Tools Training Guide'
                   iconTheme='outline'
                   icon='coffee'>

    <p>NBCU News Group Workflows & Tools Training Guide. <a href='https://nbcuacademyaccess.nbcuni.com/lesson/391153-nbcu-news-group-workflows-amp-tools-training-guide'
                                                            (click)="trackOutboundLink('https://nbcuacademyaccess.nbcuni.com/lesson/391153-nbcu-news-group-workflows-amp-tools-training-guide')"
                                                            rel='noopener noreferrer'
                                                            target='_blank'>View guide.</a></p>

  </app-collapsable>

</section>

