<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout
   class="group-landing"
   [attr.data-component]="'ncx/Groups/group-landing.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon>
      <nz-page-header-title class="header-title">
         <button
            nz-button
            *ngIf="functionAbility.fa_create_group"
            nzType="primary"
            (click)="createGroup()">
            <i
               nz-icon
               nzType="plus"></i>
         </button>

         <nz-divider
            nzType="vertical"
            class="divider"></nz-divider>

         <nz-button-group>
            <button
               nz-button
               class="filterBtn"
               (click)="toggleSider()">
               <i
                  nz-icon
                  nzType="filter"></i>
            </button>

            <div class="buttonGroup">
               <button
                  class="headerButtons"
                  nz-button>
                  <i
                     nz-icon
                     nzType="team"></i>
                  Groups
               </button>

               <button
                  nz-button
                  nz-dropdown
                  [nzDropdownMenu]="menu">
                  {{ groupsFilter }}
                  <i
                     nz-icon
                     nzType="down"></i>
               </button>

               <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu>
                     <li
                        nz-menu-item
                        (click)="changeGroupStatus('filterType', 'ALL GROUPS', 'All Groups')">
                        All Groups
                     </li>
                     <li
                        nz-menu-item
                        (click)="changeGroupStatus('filterType', 'MY GROUPS', 'My Groups')">
                        My Groups
                     </li>
                     <li
                        nz-menu-item
                        (click)="changeGroupStatus('filterType', 'FOLLOWING', 'Following')">
                        Following
                     </li>
                  </ul>
               </nz-dropdown-menu>
            </div>
         </nz-button-group>
      </nz-page-header-title>

      <nz-page-header-extra class="extra-header">
         <nz-input-group
            [nzPrefix]="prefixTemplateSearch"
            [nzSuffix]="searchInput ? suffixTemplateSearch : null">
            <input
               type="text"
               #inputText
               nz-input
               placeholder="Search Groups here..."
               [(ngModel)]="searchInput"
               (keyup)="onChange($event)" />
            <ng-template #prefixTemplateSearch>
               <button (click)="changeGroupStatus('searchText', 'FOLLOWING', 'search')">
                  <i
                     nz-icon
                     nzType="search"></i>
               </button>
            </ng-template>
            <ng-template #suffixTemplateSearch>
               <button (click)="clearGroupStatus()">
                  <i
                     nz-icon
                     nzTheme="fill"
                     nzType="close-circle"></i>
               </button>
            </ng-template>
         </nz-input-group>
      </nz-page-header-extra>
   </nz-page-header>

   <nz-layout>
      <nz-content>
         <div
            class="groupLanding groupContainer"
            infiniteScroll
            [infiniteScrollDistance]="2"
            (scrolled)="onScrollDown()"
            [scrollWindow]="false"
            #panel
            [ngStyle]="checkScreen()">
            <nz-table
               #nzTable
               [nzData]="listOfData"
               [nzFrontPagination]="false"
               [nzTotal]="total"
               [(nzPageIndex)]="pageIndex"
               (nzPageIndexChange)="searchData()">
               <thead (nzSortOrderChange)="sort($event)">
                  <tr>
                     <th class="expandable"></th>
                     <th
                        (click)="toggleFlag()"
                        nzWidth="250px">
                        Group Name
                        <i
                           class="trigger"
                           nz-icon
                           [nzType]="isToggle ? 'sort-ascending' : 'sort-descending'"
                           nzTheme="outline"></i>
                     </th>
                     <th class="groupLandingPrivacy">Privacy</th>
                     <th
                        class="groupLandingMembers"
                        nzShowSort
                        nzSortFn="true"
                        nzColumnKey="MEMBERS">
                        Members
                     </th>
                     <th
                        class="groupLandingFollowing"
                        nzShowSort
                        nzSortFn="true"
                        nzColumnKey="FOLLOWERS">
                        Following
                     </th>
                     <th
                        class="groupLandingCreated"
                        nzShowSort
                        nzSortFn="true"
                        nzColumnKey="CREATEDATE">
                        Created
                     </th>
                     <th
                        class="groupLandingMore"
                        *ngIf="formatDataGrid()">
                        &nbsp;
                     </th>
                     <th
                        class="leaveJoin"
                        *ngIf="!formatDataGrid()">
                        &nbsp;
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <ng-template
                     ngFor
                     let-data
                     [ngForOf]="nzTable.data">
                     <tr>
                        <td
                           class="expandable"
                           nzShowExpand
                           [(nzExpand)]="mapOfExpandData[data.groupId]"
                           (nzExpandChange)="showMoreData(data.groupId)"></td>
                        <td
                           [ngClass]="{
                              groupLandingGroupName:
                                 data.clickable ||
                                 data.isUserMember ||
                                 userInfoDetails.role === userRoles.Admin,
                           }"
                           (click)="redirectTo(data)"
                           nzWidth="250px">
                           {{ data.groupName }}
                        </td>
                        <td class="groupLandingPrivacy">{{ data.groupAccess }}</td>
                        <td class="groupLandingMembers">{{ data.groupMembersCount }}</td>
                        <td class="groupLandingFollowing">{{ data.groupFollowerCount }}</td>
                        <td class="groupLandingCreated">{{ data.createDateTime | date: 'M/d/yyyy' }}</td>
                        <td *ngIf="formatDataGrid()">
                           <i
                              nz-popover
                              [nzPopoverContent]="contentTemplate"
                              nzPopoverPlacement="left"
                              [nzPopoverTrigger]="'click'"
                              nz-icon
                              nzType="more"
                              nzTheme="outline"></i>
                           <ng-template #contentTemplate>
                              <div
                                 class="popoverLinks popoverjoin"
                                 *ngIf="!data.isUserMember"
                                 (click)="sendRequest(data, 'REQUEST')">
                                 Join
                              </div>
                              <div
                                 class="popoverLinks popoverleave"
                                 *ngIf="data.isUserMember"
                                 (click)="deleteRequest(data, 'REQUEST')">
                                 Leave
                              </div>
                              <!-- <div class="popoverLinks">Join</div>
                    <div class="popoverLinks">Leave</div> -->
                           </ng-template>
                        </td>
                        <td *ngIf="!formatDataGrid()">
                           <div
                              class="join"
                              *ngIf="!data.isUserMember"
                              (click)="sendRequest(data, 'REQUEST')">
                              Join
                           </div>
                           <div
                              class="leave"
                              *ngIf="data.isUserMember"
                              (click)="deleteRequest(data, 'REQUEST')">
                              Leave
                           </div>
                        </td>
                     </tr>
                     <tr
                        [nzExpand]="mapOfExpandData[data.groupId]"
                        *ngFor="let item of itemName">
                        <td
                           colspan="24"
                           *ngIf="item === 'Privacy'">
                           Privacy: {{ data?.groupAccess }}
                        </td>
                        <td
                           colspan="24"
                           *ngIf="item === 'Members'">
                           Members: {{ data.groupMembersCount }}
                        </td>
                        <td
                           colspan="24"
                           *ngIf="item === 'Following'">
                           Following: {{ data?.groupFollowerCount }}
                        </td>
                        <td
                           colspan="24"
                           *ngIf="item === 'created'">
                           Created: {{ data?.createDateTime | date: 'M/d/yyyy' }}
                        </td>
                     </tr>
                  </ng-template>
               </tbody>
            </nz-table>
         </div>
      </nz-content>
   </nz-layout>
</nz-layout>

<nz-drawer
   [nzClosable]="false"
   [nzVisible]="!groupLandingIsCollapsed"
   [nzWrapClassName]="'no-padding'"
   [nzMaskClosable]="true"
   nzPlacement="right"
   [nzWidth]="320"
   (nzOnClose)="closeSlider()">
   <ng-container *nzDrawerContent>
      <app-toggle-section
         (glCloseSider)="toggleSider()"
         [glCollapsed]="glCollapsed"
         (filtersApplied)="sortGroupByType($event)"></app-toggle-section>
   </ng-container>
</nz-drawer>
