import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DebounceService } from '@services/debounce.service';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from 'src/environments/environment';

type StoryParams = {
  contentType: 'Story',
  startIndex: number,
  endIndex: number,
  sortField: string,
  sortOrder: string,
  topicsInfo: boolean
};

@Component({
  selector: 'app-merge-stories',
  templateUrl: './merge-stories.component.html'

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class MergeStoriesComponent implements OnInit, OnChanges {

  @Input() isMergeStoryVisible;

  @Input() sourceStoryId;

  @Output() closeMergeStory: EventEmitter<any> = new EventEmitter<any>();

  destinationStory = '';

  destinationObject;

  value = '';

  mergeStoryApi;

  getStoryTitlesAPI;

  listofStories = [];

  filteredOptions: string[] = [];

  filteredOptionsInit: any[];

  subject: Subject<string> = new Subject();

  isLoaded = true;

  page: number = 1;

  limit: number = 50;

  total: number = 0;

  searchText: string = '';

  debounceTimeout: any;

  isSearchLoaded: boolean = true;

  isScrollLoaded: boolean = true;

  previousSearchText: string = '';

  // Cancel API request
  cancelRequest: Subject<boolean> = new Subject<boolean>();


  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private debounceService: DebounceService,
    private router: Router
  ) {

    this.subject.pipe(
      debounceTime(700)
    ).subscribe(searchTextValue => {

      this.getStoryTitlesList(searchTextValue);

    });

  }

  ngOnInit() {

    this.mergeStoryApi = environment.getStoriesAPI;
    this.getStoryTitlesAPI = environment.globalSearch;

  }

  ngOnChanges() {

    console.log('Source Story Id ', this.sourceStoryId);

  }

  titleMatch(value) {

    const match = this.listofStories.filter(story => story.storyTitle === value);

    if (!match.length) {

      return;

    }

    const obj = match[0];

    if ((!(obj === null || obj === undefined) || (this.sourceStoryId === obj.storyId))) {

      this.destinationStory = obj.storyTitle;
      this.destinationObject = obj;
      setTimeout(() => {

        this.value = '';

      }, 100);

    }
    if (this.filteredOptionsInit && this.filteredOptionsInit.length) {

      this.filteredOptions = [...this.filteredOptionsInit];

    }

  }

  getStoryTitlesList(queryStr?: string) {

    if (!queryStr) {
      this.filteredOptions = [];
      return;
    }
    // let params: string;

    // if (queryStr) {

    //   //params = `?storyTitle=${queryStr}`;
    //   params = `?searchString=${queryStr}&contentType=Story&startIndex=1&endIndex=50&sortField=modificationDate&sortOrder=descending`;

    // } else {

    //   this.filteredOptions = [];
    //   return;

    // }
    const queryParams = this.buildParams();

    const queryString = Object.keys(queryParams).map((key: string) => `${key}=${queryParams[key]}`).join('&');

    const params = `?searchString=${this.searchText}&` + queryString;


    this.cService.serviceRequestCommon('post', this.getStoryTitlesAPI, params).pipe(takeUntil(this.cancelRequest.pipe(filter(value => value === true)))).subscribe({

      next: (data: any) => {

        if (!this.isSearchValid()) {
          console.log('Empty search text just after the api call');
          return;
        }

        this.total = data.storyCount;

        const additionalStoriesFromAPI = data.storySearchResult.map((story: any) => {

          return {
            storyId: story.storyID,
            storyTitle: story.storyTitle,
            isPrimary: null,
            isPinnedOnTop: null
          };

        });

        // this.listofStories = JSON.parse(JSON.stringify(additionalStoriesFromAPI));


        const additionalStories = JSON.parse(JSON.stringify(additionalStoriesFromAPI));

        this.listofStories = [...this.listofStories, ...additionalStories];

        this.filteredOptions = this.listofStories.map((story) => story.storyTitle); //
        this.filteredOptionsInit = this.listofStories.map((story) => story.storyTitle);

      },
      error: (error: any) => {

        console.error('Merge Stories ', error);
      }
    }).add(() => {

      this.isSearchLoaded = this.isScrollLoaded = true;
    });
  } catch(e) {
    console.error('Merge Stories event : ', e);
    this.isSearchLoaded = this.isScrollLoaded = true;
  };



  handleCancel() {

    this.destinationStory = '';
    this.value = '';
    this.destinationObject = null;
    this.closeMergeStory.emit(true);

  }

  delete() {

    this.destinationStory = '';
    this.destinationObject = null;

  }

  handleOk() {

    if (!this.destinationObject) {

      this.toastService.createMessage('warning', 'Please select the destination story.');

    }
    if (Number(this.sourceStoryId) === this.destinationObject.storyId) {

      this.toastService.createMessage('warning', 'Destination story cannot be same as originating story.');
      return false;

    }
    this.isLoaded = false;
    const queryStr = `/${this.sourceStoryId}/` + 'merge/' + this.destinationObject.storyId;

    this.cService.serviceRequestCommon('patch', this.mergeStoryApi, queryStr).subscribe(() => {

      this.toastService.createMessage('success', 'The story has been successfully merged.');
      this.closeMergeStory.emit(true);
      this.destinationStory = '';
      this.router.navigate(['ncx/landing-story/:' + this.destinationObject.storyId]);

    }, () => {

      this.toastService.createMessage('error', 'Error in merging story. Please try again');
      this.closeMergeStory.emit(true);
      this.destinationStory = '';

    }).add(() => {

      this.isLoaded = true;

    });

  }

  onChange(event: any): void {

    this.searchText = event.target.value;

    if (!this.isSearchValid())
      return;

    console.log('Empty search text in onChange event');

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || this.searchText === this.previousSearchText) {
      return;
    }

    this.previousSearchText = this.searchText;

    //Also list must be cleared when there is a new input

    this.listofStories = [];

    this.filteredOptions = [];

    this.filteredOptionsInit = [];

    this.page = 1;

    this.isSearchLoaded = false;

    this.cancelRequest.next(true);

    this.subject.next(this.searchText);

  }

  onScroll(event: any): void {
    // console.log('Scroll Event', event);
    this.debounceService.debounce(() => {
      this.scrollDown(event);
    }, 200);
  }



  scrollDown(event: any) {
    const element = event.target;
    // console.log('Available Scroll Height', element.scrollHeight);
    // console.log('Current Scroll Height', element.scrollTop + 260);
    if (element.scrollHeight < element.scrollTop + 260) {
      this.onScrollDown();
    }
  }


  /**
  * On page scroll, load next page
  *
  */
  onScrollDown() {

    const { end } = this.startEndIndex;

    console.log('onScrollDown (Stories)');

    // If the end index is >= number of results
    if (end >= this.total) {

      console.log('No More Results');
      return;

    }

    if (this.isSearchValid()) {

      this.page += 1;

      this.isScrollLoaded = false;
      this.cancelRequest.next(false);
      this.subject.next(this.searchText);
    }

  }

  get startEndIndex(): { start: number, end: number } {

    const end = this.page * this.limit;

    const start = (end - this.limit) + 1;

    return { start, end };

  }

  /**
   * 
   * Build the query string portion of the API call
   */
  buildParams(): StoryParams {

    // API Params
    const params: StoryParams = {
      contentType: 'Story',
      startIndex: 0,
      endIndex: this.limit,
      sortField: 'modificationdate',
      sortOrder: 'descending',
      topicsInfo: true
    };

    try {

      // Page
      const { start: startIndex, end: endIndex } = this.startEndIndex;

      params.startIndex = startIndex;
      params.endIndex = endIndex;

      console.log('buildParams', params);

      return params;

    } catch (error) {

      console.error('buildParams', error);

      return {} as StoryParams;

    }
  }

  isSearchValid() {

    //list of stories must be cleared when the input is empty

    if (this.searchText.trim() === '') {

      this.listofStories = [];

      this.filteredOptions = [];

      this.filteredOptionsInit = [];

      this.previousSearchText = '';

      return false;
    }

    return true;

  }

}
