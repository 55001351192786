<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<div class="view-story display-story">
   <div *ngIf="storyDetails">
      <div class="story-card-container">
         <app-draft-banner
            message="This document is a draft and will not be visible to others until you publish it."
            *ngIf="!storyDetails.isStoryPublished"></app-draft-banner>

         <span *ngFor="let topic of storyDetails.topicDetails; let last = last">
            <app-topic-banner
               [message]="topic.notes?.length > 0 ? topic.notes : ''"
               *ngIf="topic.deleted"></app-topic-banner>
         </span>

         <!-- STORY INFO -->
         <div class="story-details">
            <div
               class="story-status"
               *ngIf="!isMobile">
               <!-- STORY STATUS -->
               <ng-container *ngTemplateOutlet="statusTemplate"></ng-container>

               <nz-divider
                  class="vertical-line"
                  nzType="vertical"></nz-divider>

               <ng-container *ngTemplateOutlet="privacyTemplate"></ng-container>

               <ng-container *ngTemplateOutlet="autoGeneratedStoryId"></ng-container>
            </div>

            <div *ngIf="isMobile">
               <!-- STORY STATUS -->
               <ng-container *ngTemplateOutlet="statusTemplate"></ng-container>

               <div class="story-status mt-8">
                  <ng-container *ngTemplateOutlet="privacyTemplate"></ng-container>

                  <ng-container *ngTemplateOutlet="autoGeneratedStoryId"></ng-container>
               </div>
            </div>

            <!-- TOPICS -->
            <div
               class="topics"
               *ngIf="topics; else noTopicsTemplate">
               Topics:
               <span
                  *ngFor="let topic of storyDetails.topicDetails; let last = last"
                  [ngClass]="{ strikethrough: topic.deleted }">
                  {{ topic.topicName }}
                  <span *ngIf="!last">,</span>
               </span>
            </div>

            <ng-template #noTopicsTemplate>
               <div class="topics">Topics will be displayed here</div>
            </ng-template>

            <!-- TITLE -->
            <div
               class="title"
               *ngIf="storyDetails.storyTitle; else noStoryTitle">
               <a
                  *ngIf="storyId"
                  href="/#/ncx/view-story/:{{ storyId }}"
                  target="_blank">
                  {{ storyDetails.storyTitle }}
               </a>
               <span *ngIf="!storyId">{{ storyDetails.storyTitle }}</span>
            </div>

            <ng-template #noStoryTitle>
               <div class="title">Story Name Will be Displayed Here</div>
            </ng-template>

            <!-- Story Description. Profile view on mouse over. If the description has the format @username then the profile will be shown -->
            <span
               id="triggerClick"
               nzPopoverTrigger="click"
               nz-popover
               [nzPopoverContent]="profileCardTemplate"
               [nzPopoverPlacement]="'topLeft'"></span>
            <ng-template
               cdkConnectedOverlay
               #profileCardTemplate>
               <app-profile-overlay [profileData]="profileInDescription"></app-profile-overlay>
            </ng-template>

            <div
               *ngIf="storyDetails?.storyContent; else noDescriptionTemplate"
               class="description fr-element fr-view"
               #storyDetailsContent
               id="storyContent">
               <span [innerHtml]="storyDetails?.storyContent | safeHtml"></span>
            </div>

            <ng-template #noDescriptionTemplate>
               <div class="description">Your story description will be displayed here</div>
            </ng-template>

            <!-- STORY TAGS -->
            <div class="tags">
               <i
                  class="tag-icon"
                  nz-icon
                  nzType="tags"
                  nzTheme="fill"></i>
               <span class="tag-name">Tags:</span>
               <span
                  class="tag-content"
                  *ngIf="storyDetails.storyContentTags?.length > 0">
                  {{ tags | lowercase }}
               </span>
            </div>

            <div class="slack">
               <i
                  class="slack-icon"
                  nz-icon
                  nzType="slack"
                  nzTheme="outline"></i>
               <span class="slack-name">Slack Connections:</span>

               <ng-container
                  class="slack-selected"
                  *ngIf="storyDetails && storyDetails.slackChannelName?.length > 0">
                  <span *ngFor="let slack of storyDetails.slackChannelName; let last = last">
                     <a
                        target="#"
                        *ngIf="slack.length > 11 || !slack.startsWith('C0')"
                        href="https://slack.com/app_redirect?channel={{ slack }}"
                        class="nameLinks">
                        #{{ slack }}
                     </a>
                     <a
                        *ngIf="slack.length <= 11 && slack.startsWith('C0')"
                        target="#"
                        href="{{ slackUrl }}{{ slack }}"
                        class="nameLinks">
                        #{{ slack }}
                     </a>
                     <!-- <span *ngIf="!last">,</span> -->
                  </span>
               </ng-container>
            </div>

            <div class="divider"></div>

            <!-- STORY CREATED BY -->
            <div
               class="createdDetails"
               *ngIf="storyDetails.createUser && storyDetails.createUser.firstName && storyDetails.createDateTime">
               <ng-container *ngTemplateOutlet="createdBy"></ng-container>

               <span *ngIf="!isXSmall"><ng-container *ngTemplateOutlet="createdOn"></ng-container></span>

               <div
                  class="mt-8"
                  *ngIf="isXSmall">
                  <ng-container *ngTemplateOutlet="createdOn"></ng-container>
               </div>
            </div>

            <!-- FOLLOWER COUNT -->
            <span
               class="follower"
               *ngIf="storyDetails.createUser && storyDetails.createUser.firstName && storyDetails.createDateTime">
               <app-follow-story
                  type="text"
                  [storyId]="storyDetails.storyId"
                  [isFollowing]="storyDetails.isUserFollowing"
                  [followers]="storyDetails.storyFollowerCount"
                  [showFollowCount]="true"
                  [showFollowStatus]="false"
                  (updatedFollower)="updateFollower($event)"></app-follow-story>
            </span>
         </div>
      </div>
   </div>
</div>

<ng-template #statusTemplate>
   <app-story-status
      *ngIf="storyDetails.storyState"
      [status]="storyDetails.storyState"></app-story-status>
</ng-template>

<ng-template #privacyTemplate>
   <div class="eye-invisible-icon">
      <i
         nz-icon
         [nzType]="storyDetails.storyAccess === 'private' ? 'eye-invisible' : 'eye'"
         nzTheme="outline"></i>
   </div>
   <div class="privacy">Privacy:</div>
   <div class="story-access">{{ storyDetails.storyAccess === 'private' ? 'Private' : 'Public' }}</div>
</ng-template>

<ng-template #autoGeneratedStoryId>
   <div
      class="push-right"
      *ngIf="storyDetails.autoGeneratedStoryId">
      {{ storyDetails.autoGeneratedStoryId }}
      <i
         class="copy-icon"
         nz-icon
         nzType="copy"
         nzTheme="outline"
         (click)="copyStoryIdInClipboard(storyDetails.autoGeneratedStoryId)"></i>
   </div>
</ng-template>

<ng-template #createdOn>
   <span
      class="date-time"
      *ngIf="storyDetails.createDateTime">
      Created On: {{ utcToLocal(storyDetails.createDateTime, 'DATE') }},
      {{ utcToLocal(storyDetails.createDateTime, 'TIME') }}
   </span>
</ng-template>

<ng-template #createdBy>
   <span class="creator">
      Created By:
      <button
         *ngIf="storyDetails.createUser && storyDetails.createUser.firstName"
         class="name">
         <span
            nz-popover
            [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="'bottomLeft'">
            {{ storyDetails.createUser.displayName.trim() }}
         </span>

         <ng-template #profileCardTemplate>
            <app-profile-overlay [profileData]="storyDetails.createUser"></app-profile-overlay>
         </ng-template>
      </button>
   </span>
</ng-template>
