import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-group-versions',
  templateUrl: './group-versions.component.html',
  styleUrls: ['./group-versions.component.scss']
})
export class GroupVersionsComponent implements OnInit {

  discussionVersions: any;

  discussionAPI: string;

  discussionVersionGroupId: any;

  expandedId: any;

  isLoaded = false;

  constructor(
    private cService: CommonService,
    private router: ActivatedRoute,
    private reRoute: Router,
    private toastService: ToastService
  ) {

    /** Nothing to do */
  }

  // 991 to 768 : no need , 576 to 767 : timestamp, 300px 575px : createdby and timestamp
  expanded = false;

  itemName = [];

  mapOfExpandData: { [key: string]: boolean } = {};

  ngOnInit() {

    this.isLoaded = false;
    this.discussionAPI = environment.getDiscussionURL;
    this.router.params.subscribe(
      (params: Params) => {

        this.setParams(params);

      });

  }

  setParams(params) {

    if (params && params.discussionVersionGroupId) {

      const id = params.discussionVersionGroupId;

      this.discussionVersionGroupId = id.substr(1);
      this.getVersions(this.discussionVersionGroupId);

    } else {

      this.isLoaded = true;

    }

  }

  showMoreData(val, versionNo) {

    this.expanded = !this.expanded;
    this.expandedId = versionNo;
    this.itemName = val;

  }

  formatDataGrid() {

    this.itemName = [];
    if (window.innerWidth >= 1200) {

      return false;

      // return (index < (length - 1)) ? true : false;

    } else if (window.innerWidth >= 576 && window.innerWidth <= 767) {

      this.itemName.push('timestamp');
      return true;

      // return (index < (length - 2)) ? true : false;

    } else if (window.innerWidth >= 300 && window.innerWidth <= 575) {

      this.itemName.push('changedBy');
      this.itemName.push('timestamp');
      return true;

      // return (index < (length - 2)) ? true : false;

    } else {

      return true;

    }

  }

  openDiscussion(groupId, discussionId) {


    // this.reRoute.navigate(['ncx/discussion-details/:' + discussionId]);
    this.reRoute.navigate(['/discussion-details'], { queryParams: { groupId, discussionId } });

  }

  back() {


    // console.log('back buttom clicked');
    const liveDiscussion = this.discussionVersions.filter((discussion) => discussion.discussionVersionState === 'Live')[0];

    if (liveDiscussion && liveDiscussion.discussionId) {


      // tslint:disable-next-line:max-line-length
      this.reRoute.navigate(['/discussion-details'], { queryParams: { groupId: liveDiscussion.groupId, discussionId: liveDiscussion.discussionId } });

    }

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  removeVersionFromList(discussionId) {

    const updateArray = [];

    this.discussionVersions.forEach(discussion => {

      if (discussion.discussionId !== discussionId) {

        updateArray.push(discussion);

      }

    });
    this.discussionVersions = updateArray;

  }

  /* API's to fetch Version Details */
  getVersions(discussionVersionGroupId) {

    const getVersionsURL = `${this.discussionAPI}/${discussionVersionGroupId}/version`;

    this.cService.serviceRequestCommon('get', getVersionsURL).subscribe((data: any) => {

      this.discussionVersions = data.discussions;
      this.isLoaded = true;

    }, () => {

      this.isLoaded = true;

    });

  }

  createVersion(data) {

    this.isLoaded = false;
    const getVersionsURL = `${this.discussionAPI}/version`;

    const payload = JSON.parse(JSON.stringify(data));


    // payload.discussionId = null;
    payload.discussionVersionState = 'Draft';
    payload.updateUserId = null;
    payload.createUserId = payload?.createUser?.userId;
    this.cService.serviceRequestCommon('post', getVersionsURL, '', payload).subscribe((res: any) => {

      this.isLoaded = true;
      this.reRoute.navigate(['/discussion-details'], { queryParams: { groupId: res.groupId, discussionId: res.discussionId } });

    }, () => {

      this.isLoaded = true;

    });

  }

  deleteVersion(discussionId) {

    this.isLoaded = false;
    const getVersionsURL = `${this.discussionAPI}/${discussionId}/version`;

    this.cService.serviceRequestCommon('delete', getVersionsURL).subscribe(() => {

      this.toastService.createMessage('success', 'Group Version deleted successfully.');
      this.removeVersionFromList(discussionId);
      this.isLoaded = true;

    }, () => {

      this.isLoaded = true;
      this.toastService.createMessage('error', 'Error deleting Group Version. Please try again.');

    });

  }

}
