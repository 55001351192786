<nz-layout>

  <app-standard-header
    title='Back'
    [defaultBackAction]='true'></app-standard-header>

  <nz-layout>
    <nz-content>

      <div class='support-tabs'>
        <nz-tabset
          (nzSelectChange)='onChangeTab($event)'
          [(nzSelectedIndex)]='tabIndex'>

          <!----------- FAQ ----------->

          <nz-tab nzTitle='FAQ'>
            <ng-container *ngTemplateOutlet='help'></ng-container>
            <app-support-faq [isVisible]='tabIndex === 0'></app-support-faq>
          </nz-tab>

          <!----------- TRAINING ----------->

          <nz-tab nzTitle='Training'>
            <ng-container *ngTemplateOutlet='help'></ng-container>
            <app-support-training [isVisible]='tabIndex === 1'></app-support-training>
          </nz-tab>

          <!----------- VERSIONS ----------->

          <nz-tab nzTitle='Versions'>
            <ng-container *ngTemplateOutlet='help'></ng-container>
            <app-support-versions [isVisible]='tabIndex === 2'></app-support-versions>
          </nz-tab>

        </nz-tabset>
      </div>

    </nz-content>

  </nz-layout>
</nz-layout>

<ng-template #help>
  <section class='help'>
      <p>
        <i nz-icon
           class='slack-icon'
           nzType='slack'
           nzTheme='outline'></i>
<span>Want to share feedback, get involved, or hear about the latest tech? Join the <a href='https://nbcuniversal.enterprise.slack.com/archives/C0554GKKM5E'
                                                                                       rel='noopener noreferrer'
                                                                                       target='_blank'>#newsroom-product-innovation</a> Slack channel!</span></p>
    <p><i nz-icon
          nzType='mail'
          class='mail-icon'
          nzTheme='outline'></i>
        <span>For technical support, please email <a [href]='supportEmailSubject'>newsconnecthelp&commat;nbcuni.com</a>.</span></p>
  </section>
</ng-template>
