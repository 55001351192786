import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AppConstants } from '@constants/app.constants';
import { Common } from '@utilities/common';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, pairwise } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { NavItem } from '@models/nav-item';
import { NAV_DISPLAY } from '../shared/navigation/navigation';

/**
 * Subscribes and publishes events related to changes to the Navigation
 * when someone visits a new page, monitoring what page the user is
 * currently on and what page the user has been to.
 *
 *
 */
@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {

  public readonly activePath: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public activePath$: Observable<string> = this.activePath.asObservable();

  public readonly previousPath: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public previousPath$: Observable<string> = this.previousPath.asObservable();

  public readonly pageTitle: BehaviorSubject<string> = new BehaviorSubject<string>('NewsConnect');

  public pageTitle$: Observable<string> = this.pageTitle.asObservable();

  // Is Mobile App (website loaded into the ios-app-shell)
  // Is the user viewing the site through an actual iPad or iPhone mobile device (not the same as a mobile breakpoint; see breakpoint service)
  public readonly isIOSMobileApp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isIOSMobileApp$: Observable<boolean> = this.isIOSMobileApp.asObservable();

  // All the navigation items that are displayed in the navigation drawer are set here by default
  public readonly navDisplay: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>(NAV_DISPLAY);
  public navDisplay$: Observable<NavItem[]> = this.navDisplay.asObservable();

  private subs = new SubSink();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {

    /**
     * Determines if the user is viewing the site through the ios-shell iPhone mobile app
     */
    const browser = Common.getBrowser();

    console.log('Navigation Service', browser);
    this.isIOSMobileApp.next(browser.isMobile);

    /**
     * Monitors the PREVIOUS path.
     * Using this subscription to record the paths that were navigated FROM (previous paths)
     * so that subscribing pages will know what page to return to.
     *
     */
    this.subs.sink = this.router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise(),
    ).subscribe((event: any[]) => {

      const [previous] = event;

      const previousUrl = previous.urlAfterRedirects;

      this.previousPath.next(previousUrl);

    });

    /**
     * Monitors the ACTIVE path.
     * Using this subscription to record the paths that were navigated TO so that
     * the navigation elements will know the active Url.
     *
     */
    this.subs.sink = this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        const currentUrl = event.urlAfterRedirects;

        this.activePath.next(currentUrl);

      }

    });

    /**
     * Set the Page Title based on the navigation
     *
     */
    this.subs.sink = this.router.events.subscribe(event => {

      let pageTitle = 'NewsConnect';

      if (event instanceof NavigationEnd) {

        // Set the DEFAULT route title based on what's in the routing module
        const currentRoute = this.getChildRoute(this.route);

        currentRoute.data.subscribe((data: any) => {

          pageTitle = data.title;

        });

        // OVERRIDE THE DEFAULT title based on the logic below
        if (event.url.startsWith(AppConstants.local.Url_storiesDashboard)) {

          pageTitle = AppConstants.local.Url_storiesDashboard_header;

        } else if (event.url.startsWith(AppConstants.local.Url_editStory)) {

          //Edit Story condition should be prior to Create Story
          pageTitle = AppConstants.local.Url_editStory_header;

        } else if (event.url.startsWith(AppConstants.local.Url_createStory)) {

          pageTitle = AppConstants.local.Url_createStory_header;

        } else if (event.url.startsWith(AppConstants.local.Url_viewStory)) {

          pageTitle = AppConstants.local.Url_viewStory_header;

        } else if (event.url.startsWith(AppConstants.local.Url_storyLanding)) {

          pageTitle = AppConstants.local.Url_storyLanding_header;

        } else if (event.url.startsWith(AppConstants.local.Url_editPost)) {

          //Edit Post condition should be prior to Create Post
          pageTitle = AppConstants.local.Url_editPost_header;

        } else if (event.url.startsWith(AppConstants.local.Url_createPost)) {

          pageTitle = AppConstants.local.Url_createPost_header;

        } else if (event.url.startsWith(AppConstants.local.Url_viewPost)) {

          pageTitle = AppConstants.local.Url_viewPost_header;

        } else if (event.url.startsWith(AppConstants.local.Url_postVersions)) {

          pageTitle = AppConstants.local.Url_postVersions_header;

        } else if (event.url.startsWith(AppConstants.local.Url_editAngle)) {

          //Edit Angle condition should be prior to Create Angle
          pageTitle = AppConstants.local.Url_editAngle_header;

        } else if (event.url.startsWith(AppConstants.local.Url_createAngle)) {

          pageTitle = AppConstants.local.Url_createAngle_header;

        } else if (event.url.startsWith(AppConstants.local.Url_angleLanding)) {

          pageTitle = AppConstants.local.Url_angleLanding_header;

        } else if (event.url.startsWith(AppConstants.local.Url_profile)) {

          pageTitle = AppConstants.local.Url_profile_header;

        } else if (event.url.startsWith(AppConstants.local.Url_profileHistory)) {

          pageTitle = AppConstants.local.Url_profileHistory_header;

        } else if (event.url.startsWith(AppConstants.local.Url_settings)) {

          pageTitle = AppConstants.local.Url_settings_header;

        } else if (event.url.startsWith(AppConstants.local.Url_following)) {

          pageTitle = AppConstants.local.Url_following_header;

        } else if (event.url.startsWith(AppConstants.local.Url_followedStories)) {

          pageTitle = AppConstants.local.Url_followedStories_header;

        } else if (event.url.startsWith(AppConstants.local.Url_editGroup)) {

          //Edit Group condition should be prior to Create Group
          pageTitle = AppConstants.local.Url_editGroup_header;

        } else if (event.url.startsWith(AppConstants.local.Url_createGroup)) {

          pageTitle = AppConstants.local.Url_createGroup_header;

        } else if (event.url.startsWith(AppConstants.local.Url_groupDetails)) {

          pageTitle = AppConstants.local.Url_groupDetails_header;

        } else if (event.url.startsWith(AppConstants.local.Url_groupLanding)) {

          pageTitle = AppConstants.local.Url_groupLanding_header;

        } else if (event.url.startsWith(AppConstants.local.Url_manageMembers)) {

          pageTitle = AppConstants.local.Url_manageMembers_header;

        } else if (event.url.startsWith(AppConstants.local.Url_createDiscussion)) {

          pageTitle = AppConstants.local.Url_createDiscussion_header;

        } else if (event.url.startsWith(AppConstants.local.Url_discussionDetails)) {

          pageTitle = AppConstants.local.Url_discussionDetails_header;

        } else if (event.url.startsWith(AppConstants.local.Url_groupFollowers)) {

          pageTitle = AppConstants.local.Url_groupFollowers_header;

        } else if (event.url.startsWith(AppConstants.local.Url_groupVersions)) {

          pageTitle = AppConstants.local.Url_groupVersions_header;

        } else if (event.url.startsWith(AppConstants.local.Url_connections)) {

          pageTitle = AppConstants.local.Url_connections_header;

        } else if (event.url.startsWith(AppConstants.local.Url_drafts)) {

          pageTitle = AppConstants.local.Url_drafts_header;

        } else if (event.url.startsWith(AppConstants.local.Url_activity)) {

          pageTitle = AppConstants.local.Url_activity_header;

        } else if (event.url.startsWith(AppConstants.local.Url_activityHot)) {

          pageTitle = AppConstants.local.Url_activityHot_header;

        } else if (event.url.startsWith(AppConstants.local.Url_activityStandards)) {

          pageTitle = AppConstants.local.Url_activityStandards_header;

        } else if (event.url.startsWith(AppConstants.local.Url_alerts)) {

          pageTitle = AppConstants.local.Url_alerts_header;

        } else if (event.url.startsWith(AppConstants.local.Url_otherTools)) {

          pageTitle = AppConstants.local.Url_otherTools_header;

        } else if (event.url.startsWith(AppConstants.local.Url_admin)) {

          pageTitle = AppConstants.local.Url_admin_header;

        } else if (event.url.startsWith(AppConstants.local.Url_trackSendToMediaCentral)) {

          pageTitle = AppConstants.local.Url_trackSendToMediaCentral_header;

        } else if (event.url.startsWith(AppConstants.local.Url_feed)) {

          pageTitle = AppConstants.local.Url_feed_header;

        } else if (event.url.startsWith(AppConstants.local.Url_topics)) {

          pageTitle = AppConstants.local.Url_topics_header;

        } else if (event.url.startsWith(AppConstants.local.Url_topics)) {

          pageTitle = AppConstants.local.Url_topics_header;

        }

        this.pageTitle.next(pageTitle);

        this.titleService.setTitle(pageTitle);

      }

    });

  }

  getChildRoute(activatedRoute: ActivatedRoute) {

    if (activatedRoute.firstChild) {

      return this.getChildRoute(activatedRoute.firstChild);

    } else {

      return activatedRoute;

    }

  }

  /**
   * Can be called outside this service to set a page title from any of the pages
   *
   */
  overridePageTitle(title: string) {

    this.pageTitle.next(title);
    this.titleService.setTitle(title);

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

}
