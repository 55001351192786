import { Injectable } from '@angular/core';
import { AppConstants } from '@constants/app.constants';
import { environment } from '@environments/environment';

const oldDEV1Url = AppConstants.domain.prior_dev1_url;

const oldDEV2Url = AppConstants.domain.prior_dev2_url;

const rtsDEVUrl = AppConstants.domain.rts_dev_ui_url;

const capxDEVUrl = AppConstants.domain.capx_dev_ui_url;

const oldQA1Url = AppConstants.domain.prior_qa1_url;

const oldQA2Url = AppConstants.domain.prior_qa2_url;

const rtsQAUrl = AppConstants.domain.rts_qa_ui_url;

const capxQAUrl = AppConstants.domain.capx_qa_ui_url;

const oldSTG1Url = AppConstants.domain.prior_stg1_url;

const oldSTG2Url = AppConstants.domain.prior_stg2_url;

const rtsSTGUrl = AppConstants.domain.rts_stg_ui_url;

const capxSTGUrl = AppConstants.domain.capx_stg_ui_url;

const oldPRDUrl = AppConstants.domain.prod_ui_url;

const newPRDUrl = AppConstants.domain.prod_ui_url;

const localHostUrl = AppConstants.domain.local_url;


const capxUrlRedirects: { [key: string]: string } = {
  [oldDEV1Url]: capxDEVUrl,
  [oldDEV2Url]: capxDEVUrl,
  [oldQA1Url]: capxQAUrl,
  [oldQA2Url]: capxQAUrl,
  [oldSTG1Url]: capxSTGUrl,
  [oldSTG2Url]: capxSTGUrl
};


const rtsUrlRedirects: { [key: string]: string } = {
  [oldDEV1Url]: rtsDEVUrl,
  [oldDEV2Url]: rtsDEVUrl,
  [oldQA1Url]: rtsQAUrl,
  [oldQA2Url]: rtsQAUrl,
  [oldSTG1Url]: rtsSTGUrl,
  [oldSTG2Url]: rtsSTGUrl
};

const prodUrlRedirects: { [key: string]: string } = {
  [oldPRDUrl]: newPRDUrl
};

const oldAPIDEV1Url = AppConstants.domain.prior_dev1_url;

const oldAPIDEV2Url = AppConstants.domain.prior_dev2_url;

const rtsAPIDEVUrl = AppConstants.domain.rts_dev_api_url;

const capxAPIDEVUrl = AppConstants.domain.capx_dev_api_url;

const oldAPIQA1Url = AppConstants.domain.prior_qa1_url;

const oldAPIQA2Url = AppConstants.domain.prior_qa2_url;

const rtsAPIQAUrl = AppConstants.domain.rts_qa_api_url;

const capxAPIQAUrl = AppConstants.domain.capx_qa_api_url;

const oldAPISTG1Url = AppConstants.domain.prior_stg1_url;

const oldAPISTG2Url = AppConstants.domain.prior_stg2_url;

const rtsAPISTGUrl = AppConstants.domain.rts_stg_api_url;

const capxAPISTGUrl = AppConstants.domain.capx_stg_api_url;

const oldAPIPRDUrl = AppConstants.domain.prod_ui_url

const newAPIPRDUrl = AppConstants.domain.prod_api_url;

const capxUrlAPIRedirects: { [key: string]: string } = {
  [oldAPIDEV1Url]: capxAPIDEVUrl,
  [oldAPIDEV2Url]: capxAPIDEVUrl,
  [oldAPIQA1Url]: capxAPIQAUrl,
  [oldAPIQA2Url]: capxAPIQAUrl,
  [oldAPISTG1Url]: capxAPISTGUrl,
  [oldAPISTG2Url]: capxAPISTGUrl
};


const rtsUrlAPIRedirects: { [key: string]: string } = {
  [oldAPIDEV1Url]: rtsAPIDEVUrl,
  [oldAPIDEV2Url]: rtsAPIDEVUrl,
  [oldAPIQA1Url]: rtsAPIQAUrl,
  [oldAPIQA2Url]: rtsAPIQAUrl,
  [oldAPISTG1Url]: rtsAPISTGUrl,
  [oldAPISTG2Url]: rtsAPISTGUrl
};

const prodUrlAPIRedirects: { [key: string]: string } = {
  [oldAPIPRDUrl]: newAPIPRDUrl
};

const capxDevUiUrl = AppConstants.domain.capx_dev_ui_url;

const capxDevApiUrl = AppConstants.domain.capx_dev_api_url;

const capxQaUiUrl = AppConstants.domain.capx_qa_ui_url;

const capxQaApiUrl = AppConstants.domain.capx_qa_api_url;

const capxStgUiUrl = AppConstants.domain.capx_stg_ui_url;

const capxStgApiUrl = AppConstants.domain.capx_stg_api_url;

const ProdUiUrl = AppConstants.domain.prod_ui_url;

const ProdApiUrl = AppConstants.domain.prod_api_url;

const prodUiApiUrlRedirects: { [key: string]: string } = {
  [ProdUiUrl]: ProdApiUrl
};

const capxUiApiUrlRedirects: { [key: string]: string } = {
  [capxDevUiUrl]: capxDevApiUrl,
  [capxQaUiUrl]: capxQaApiUrl,
  [capxStgUiUrl]: capxStgApiUrl
};

const rtsDevUiUrl = AppConstants.domain.rts_dev_ui_url;

const rtsDevApiUrl = AppConstants.domain.rts_dev_api_url;

const rtsQaUiUrl = AppConstants.domain.rts_qa_ui_url;

const rtsQaApiUrl = AppConstants.domain.rts_qa_api_url;

const rtsStgUiUrl = AppConstants.domain.rts_stg_ui_url;

const rtsStgApiUrl = AppConstants.domain.rts_stg_api_url;

const rtsUiApiUrlRedirects: { [key: string]: string } = {
  [rtsDevUiUrl]: rtsDevApiUrl,
  [rtsQaUiUrl]: rtsQaApiUrl,
  [rtsStgUiUrl]: rtsStgApiUrl
};

@Injectable({
  providedIn: 'root'
})
export class UrlRedirectService {

  /** validDomains variable is used in common.function.helper.ts file to understand the valid domains
     *  Snyk reported few issues on the direct usage of urls in window.location.href 
     *  URL pattern and sanitazion checks of valid domains have been added to overcome the issue
     */
  public validDomains: Array<string> = [capxDEVUrl, capxQAUrl, capxSTGUrl, rtsDEVUrl, rtsQAUrl, rtsSTGUrl, newPRDUrl, capxAPIDEVUrl, capxAPIQAUrl, capxAPISTGUrl, rtsAPIDEVUrl, rtsAPIQAUrl, rtsAPISTGUrl, newAPIPRDUrl, localHostUrl];


  /**
     *  
     *  To redirect the old /other UI env to new UI env
     *  In very old env, both the ui and api url holds the same name like https://dev1-ncx.nbcuni.com
     * In DEV env, 
     *  Domain https://dev1-ncx.nbcuni.com will to be redirected to dev.newsconnect.nbcuni.com
     *  Domain https://dev2-ncx.nbcuni.com will to be redirected to dev.newsconnect.nbcuni.com
     *  Domain https://devui.newsconnect.nbcuni.com will to be redirected to dev.newsconnect.nbcuni.com
     * 
     * In RTS env,
     *  Domain https://dev1-ncx.nbcuni.com will to be redirected to devui.newsconnect.nbcuni.com
     *  Domain https://dev2-ncx.nbcuni.com will to be redirected to devui.newsconnect.nbcuni.com
     *  Domain https://dev.newsconnect.nbcuni.com will to be redirected to devui.newsconnect.nbcuni.com
     *  Above domain switch change has to be done in app.constants file
     * 
     * 
     */
  public getRedirectURL(url: string) {

    // console.log('environment  : ' + environment.environmentName);

    try {

      if (environment.environmentName.includes('rts')) {

        const domainNameSplit = url.split('/');

        const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

        const newDomain = rtsUrlRedirects[currentDomain] != null ? rtsUrlRedirects[currentDomain] : currentDomain;

        const newUrl = url.replace(currentDomain, newDomain);

        // console.log({ currentDomain, newDomain, url, newUrl });
        return newUrl;

      } else if (environment.environmentName === 'prod') {

        const domainNameSplit = url.split('/');

        const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

        const newDomain = prodUrlRedirects[currentDomain] != null ? prodUrlRedirects[currentDomain] : currentDomain;

        const newUrl = url.replace(currentDomain, newDomain);

        // console.log({ currentDomain, newDomain, url, newUrl });
        return newUrl;

      } else {

        const domainNameSplit = url.split('/');

        const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

        const newDomain = capxUrlRedirects[currentDomain] != null ? capxUrlRedirects[currentDomain] : currentDomain;

        const newUrl = url.replace(currentDomain, newDomain);

        // console.log({ currentDomain, newDomain, url, newUrl });
        return newUrl;

      }

    } catch (err) {

      console.error(err);
      return url;

    }

  }

  /**
     *  
     *  To redirect the old /other env API to new env API in the attachment or search
     *  In very old env, both the ui and api url holds the same name like https://dev1-ncx.nbcuni.com
     * In DEV env, 
     *  Domain https://dev1-ncx.nbcuni.com will to be redirected to dev-api.newsconnect.nbcuni.com
     *  Domain https://dev2-ncx.nbcuni.com will to be redirected to dev-api.newsconnect.nbcuni.com
     *  Domain https://devapi.newsconnect.nbcuni.com will to be redirected to dev-api.newsconnect.nbcuni.com
     * 
     * In RTS env,
     *  Domain https://dev1-ncx.nbcuni.com will to be redirected to devapi.newsconnect.nbcuni.com
     *  Domain https://dev2-ncx.nbcuni.com will to be redirected to devapi.newsconnect.nbcuni.com
     *  Domain https://dev-api.newsconnect.nbcuni.com will to be redirected to devapi.newsconnect.nbcuni.com
     *  Above domain switch change has to be done in app.constants file
     * 
     */
  public getRedirectAPIURL(url: string) {

    try {

      // console.log('environment : ' + environment.environmentName);
      if (environment.environmentName.includes('rts')) {

        const domainNameSplit = url.split('/');

        const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

        const newDomain = rtsUrlAPIRedirects[currentDomain] != null ? rtsUrlAPIRedirects[currentDomain] : currentDomain;

        const newUrl = url.replace(currentDomain, newDomain);

        // console.log({ currentDomain, newDomain, url, newUrl });
        return newUrl;

      } else if (environment.environmentName === 'prod') {

        const domainNameSplit = url.split('/');

        const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

        const newDomain = prodUrlAPIRedirects[currentDomain] != null ? prodUrlAPIRedirects[currentDomain] : currentDomain;

        const newUrl = url.replace(currentDomain, newDomain);

        // console.log({ currentDomain, newDomain, url, newUrl });
        return newUrl;

      } else {

        const domainNameSplit = url.split('/');

        const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

        const newDomain = capxUrlAPIRedirects[currentDomain] != null ? capxUrlAPIRedirects[currentDomain] : currentDomain;

        const newUrl = url.replace(currentDomain, newDomain);

        // console.log({ currentDomain, newDomain, url, newUrl });
        return newUrl;

      }

    } catch (err) {

      console.error(err);
      return url;

    }

  }


  /**
    *  When an attachment is added to the post, S3URLs will be created by the API and will be added to the Post object.(postAttachments - property)
    *  Ex: attachmentPath : "https://qa-api.newsconnect.nbcuni.com/ncx-gateway/api/v1/docs/post/1709669965365/1709669986201-20_national_park.jpg"
    *  Emails will be delivered when a post is published. If the post has attachments then the attachments will be delivered with the S3URLs in the email. 
    *  When the user clicks the attached images in the email then the S3URLs will be opened in a browser
    *  S3URLs with the api domains can't be accessed without JWTToken. JWTToken can't be generated at run time from the browser.
    *  Have to access NCX UI to generate the JWTToken and append it to the URL to get the image.
    *  In order to open the NCX UI from the S3URL in email, the API domain name of the attachment path will be delivered as UI domain name in the email 
    *  Ex: attachmentPath : "https://qa.newsconnect.nbcuni.com/ncx-gateway/api/v1/docs/post/1709669965365/1709669986201-20_national_park.jpg"
    *  When we click on the image in the email, the UI URL will be opened and will be redirected to API URL in code with JWTToken.
    *  Domain dev.newsconnect.nbcuni.com will to be redirected to dev-api.newsconnect.nbcuni.com
    *  Domain qa.newsconnect.nbcuni.com will to be redirected to qa-api.newsconnect.nbcuni.com
    *  Domain stg.newsconnect.nbcuni.com will to be redirected to stg-api.newsconnect.nbcuni.com
    *  Domain prod.newsconnect.nbcuni.com will to be redirected to api.newsconnect.nbcuni.com
    */
  getCorrespondingAPIDomain(url: string, apiUrl: string) {

    // console.log('environment: ' + environment.environmentName);
    if (url == AppConstants.domain.local_url) {

      return apiUrl;

    } else {

      if (environment.environmentName.includes('rts')) {

        const domainNameSplit = url.split('/');

        const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

        const newDomain = rtsUiApiUrlRedirects[currentDomain] != null ? rtsUiApiUrlRedirects[currentDomain] : currentDomain;

        const newUrl = url.replace(currentDomain, newDomain);

        // console.log({ currentDomain, newDomain, url, newUrl });
        return newUrl;

      } else if (environment.environmentName === 'prod') {

        const domainNameSplit = url.split('/');

        const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

        const newDomain = prodUiApiUrlRedirects[currentDomain] != null ? prodUiApiUrlRedirects[currentDomain] : currentDomain;

        const newUrl = url.replace(currentDomain, newDomain);

        // console.log({ currentDomain, newDomain, url, newUrl });
        return newUrl;

      } else {

        const domainNameSplit = url.split('/');

        const currentDomain = `${domainNameSplit[0]}//${domainNameSplit[2]}`;

        const newDomain = capxUiApiUrlRedirects[currentDomain] != null ? capxUiApiUrlRedirects[currentDomain] : currentDomain;

        const newUrl = url.replace(currentDomain, newDomain);

        // console.log({ currentDomain, newDomain, url, newUrl });
        return newUrl;

      }

    }

  }

}

