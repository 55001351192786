<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout
   *ngIf="!viewPreview"
   [attr.data-component]="'ncx/Group/create-discussion.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon>
      <nz-page-header-title>
         <nz-page-header-title class="headerTitle">{{ groupName }}</nz-page-header-title>
      </nz-page-header-title>
   </nz-page-header>

   <nz-content class="content">
      <section>
         <p class="title">Subject</p>

         <input
            #discussionInput
            nz-input
            placeholder="Give your discussion a title"
            class="input-field"
            [(ngModel)]="discussionText"
            (ngModelChange)="titleMatch($event)"
            (keydown)="onChange(discussionText, $event)"
            [nzAutocomplete]="auto" />

         <nz-autocomplete
            #auto
            [nzDataSource]="filteredOptions"></nz-autocomplete>

         <app-rteditor
            [rteValue]="discussionDetails.discussionContent"
            [input]="'discussionDesc'"
            [isSavedAsDraft]="isAutoSaveDraft"
            [isEmitted]="isEmitted"
            [summary]="true"
            [googleDocs]="googleDocs"
            (gFlag)="checkGoogleFlag($event)"
            (rteUpdated)="getRTEData($event)"
            (rtePasteContent)="getRTEData($event)"></app-rteditor>
      </section>

      <section>
         <app-attachments
            [isAttachmentDisabled]="isAttachmentDisabled"
            [hideElements]="hideElements"
            [attachments]="attachments"
            [saveDiscussionId]="discussionId"
            [screenType]="'DISCUSSION'"
            (attachmentsUpdated)="getAttachmentsData($event)"></app-attachments>
      </section>

      <div class="editableText">
         <label
            nz-checkbox
            [(ngModel)]="isPinnedToTop">
            Pin to top
         </label>
      </div>

      <div
         *ngIf="editableText"
         class="editableTextEmail">
         <label
            nz-checkbox
            [(ngModel)]="isEmailUpdate"
            (ngModelChange)="toggleEmailCheckbox()">
            Do not Send Email Update for this Edit
         </label>
      </div>
   </nz-content>

   <nz-footer class="site-page-footer">
      <button
         nz-button
         nzType="primary"
         nzDanger="true"
         (click)="reDirectTo()">
         Cancel
      </button>
      <button
         id="rteCustomLink"
         nz-button
         nzType="default"
         *ngIf="isAutoSaveDraft"
         (click)="saveAs('DRAFT')">
         Save Draft
      </button>
      <button
         nz-button
         nzType="primary"
         *ngIf="!isAutoSaveDraft"
         nzTooltipPlacement="top"
         nz-tooltip
         [nzTooltipTitle]="tooltipMsg">
         Save Draft
      </button>
      <button
         nz-button
         nzType="default"
         (click)="showPreview()">
         Preview
      </button>
      <button
         nz-button
         nzType="primary"
         (click)="saveAs('POST')"
         *ngIf="isAutoSaveDraft"
         [disabled]="isPublishDisable">
         POST
      </button>
      <button
         nz-button
         nzType="primary"
         *ngIf="!isAutoSaveDraft"
         nzTooltipPlacement="top"
         nz-tooltip
         [nzTooltipTitle]="tooltipMsg">
         POST
      </button>
   </nz-footer>
</nz-layout>

<nz-layout *ngIf="viewPreview">
   <nz-page-header
      class="site-page-header"
      nzBackIcon
      (nzBack)="returnToEdit()">
      <nz-page-header-title>
         <nz-page-header-title class="headerTitle">{{ groupName }}</nz-page-header-title>
      </nz-page-header-title>
   </nz-page-header>

   <nz-layout class="preview-container">
      <nz-content>
         <div class="discussionContent">
            <div class="ddTitle">
               {{ discussionDetails.discussionSubject }}
            </div>
            <div
               class="preview-content fr-element fr-view"
               *ngIf="discussionContent">
               <span [innerHtml]="discussionDetails?.discussionContent | safeHtml"></span>
            </div>
            <div *ngIf="attachments">
               <app-view-attachments [postAttachments]="attachments"></app-view-attachments>
            </div>
            <div
               nz-row
               class="iconSection">
               <div style="float: right">
                  <span
                     class="mr-8"
                     *ngIf="attachments && attachments.length">
                     <i
                        nz-icon
                        nzType="paper-clip"
                        nzTheme="outline"></i>
                     <span>
                        {{ attachments.length }}
                     </span>
                  </span>

                  <!-- No existing implementation for comments and share so commenting these two icons -->
                  <!-- <span class="mr-8">
                     <i
                        nz-icon
                        nzType="comment"
                        nzTheme="outline"></i>
                  </span>
                  <span>
                     <button>
                        <i
                           nz-icon
                           nzType="share-alt"
                           nzTheme="outline"></i>
                     </button>
                  </span> -->
               </div>
            </div>
         </div>
      </nz-content>
   </nz-layout>

   <nz-footer class="create-discussion site-page-footer">
      <button
         nz-button
         nzType="primary"
         nzDanger="true"
         (click)="returnToEdit()">
         Cancel
      </button>
      <button
         nz-button
         nzType="primary"
         (click)="saveAs('POST')">
         POST
      </button>
   </nz-footer>
</nz-layout>

<app-share-discussion
   [isVisible]="isVisibleShare"
   [message]="discussionDetails.discussionSubject"
   (closedSharedContent)="handleCancel()"></app-share-discussion>

<app-google-docs
   [isVisible]="isVisibleGoogleDocs"
   (docLinkUpdated)="getDocLink($event)"></app-google-docs>

<!-- NOT IN USE
<app-embedly
		[isEmbedlyVisible]="isVisibleEmbedly"
		(embedlyUpdated)="getEmbedlyData($event)"></app-embedly>
-->
