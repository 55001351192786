import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import * as moment from 'moment';

@Component({
  selector: 'support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
})
export class SupportPageComponent {

  tabIndex = 0;

  username = '';

  tabs = ['faq', 'training', 'versions'];

  constructor(
    private activeRoute: ActivatedRoute,
    private userInfo: TransferInformationComponent,
    private location: Location,
  ) {

    this.activeRoute.params.subscribe((params: { [key: string]: string }) => {

      if ((params['tab'] || '').match(/^(faq|training|versions)$/i)) {

        this.tabIndex = this.tabs.indexOf(params['tab']);

      }

    });

    this.userInfo.currentLinkDetails.subscribe((username) => {

      this.username = username;

    });

    this.location.replaceState(`/ncx/support/${this.tabs[this.tabIndex]}`);

  }

  /**
   * Update the URL when the tab changes
   *
   */
  onChangeTab(event: NzTabChangeEvent) {

    const index = event.index;

    this.location.replaceState(`/ncx/support/${this.tabs[index]}`);

  }

  /**
   * Subject for Help e-mail
   *
   */
  get supportEmailSubject(): string {

    const date = moment().format('MM/DD/YYYY HH:mm:ss');

    return `mailto:newsconnecthelp@nbcuni.com?subject=${date + ' - ' + this.username + ' - NewsConnect help request'}`;

  }

}
