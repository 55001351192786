import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { Stories } from '@models/ncx/story';
import { environment } from 'src/environments/environment';
import { Sort } from '@utilities/sort';

@Component({
  selector: 'app-following-stories',
  templateUrl: './following-stories.component.html',
  styleUrls: ['./following-stories.component.scss']
})
export class FollowingStoriesComponent implements OnInit {

  isLoaded;

  stories: Stories[] = [];
  pageIndex: number;
  pageStartCount: any;
  pageLength: any;
  isScrolled: boolean;
  isRef: boolean = false;

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {
    this.pageStartCount = 1;
    this.pageLength = 20;
    this.getFollowedStories();

  }

  /**
   * Get list of all stories followed by this user
   *
   */
  getFollowedStories() {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', environment.getStoriesAPI, `?isFollowing=true&pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}`).subscribe({

      next: (res: any) => {

        if (!this.isScrolled) {
          this.stories = [...res.stories];
        } else {
          if (this.stories && this.stories.length) {
            this.stories = [...this.stories, ...res.stories];
          }
        }
        this.stories = (this.stories as Stories[]).sort((a: Stories, b: Stories) => Sort.string(a.storyTitle, b.storyTitle));

      },

      error: (error: any) => {

        console.error('getFollowedStories', error);

        this.toastService.createMessage('error', 'Error while loading. Please try again');

      }

    }).add(() => {

      this.isLoaded = true;
      this.isScrolled = false;
    
    });
  
  }

  /**
   * Unfollow a story
   *
   *
   */
  unfollowStory(story: Stories) {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('delete', environment.storyFollower, `/${story.storyId}`).subscribe((data:any) => {

      console.log('Unfollow Response', data);

      this.toastService.createMessage('success', `You have unfollowed the story ${story.storyTitle}`);
      const topicRecord = this.stories.findIndex(story => story.storyId === data.storyId);
      if(topicRecord)
        this.stories.splice(topicRecord,1);
 
    }, (error: any) => {

      console.error({ error });

      this.toastService.createMessage('error', 'Error updating data. Please try again.');

    }).add(() => {

      this.isLoaded = true;
      this.isScrolled = false;

    });

  }

  onScrollDown() {
    this.pageStartCount += this.pageLength;
    this.isScrolled = true;
    this.getFollowedStories();
  }

  /**
   * View story
   *
   */
  viewStory(storyId: number) {

    this.router.navigate([`/ncx/landing-story/:${storyId}`]);

  }

}
