<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<app-standard-header
   title="Back"
   [defaultBackAction]="true"></app-standard-header>

<div
   class="topic-layout"
   [attr.data-component]="'src/app/ncx/following/stories'">
   <p class="heading">Following:</p>

   <div
      class="story-container"
      infiniteScroll
      [infiniteScrollDistance]="2"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false"
      #panel
      *ngIf="stories.length">
      <nz-card
         *ngFor="let story of stories"
         class="card">
         <button
            (click)="viewStory(story.storyId)"
            class="title">
            {{ story.storyTitle }}
         </button>

         <button
            (click)="unfollowStory(story)"
            class="unfollow"
            nz-button>
            Unfollow
         </button>
      </nz-card>
   </div>
</div>
