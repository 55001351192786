import { DOCUMENT } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { environment } from '@environments/environment';
import { CommonService } from '@services/common-service';
import { CSSThemeService } from '@services/css-theme.service';
import { ToastService } from '@services/toastService/toastMessage.service';

@Component({

  //   selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, AfterContentChecked {

  public settingResponseData: any = {};

  public slackSettingResponseData: any = {};

  public cloneSettingsResponseData = {};

  public cloneSlackSettingsResponseData = {};

  public settingsDetails: any = {};

  settingsSource: any = {};

  getApiURL: string;

  getSlackSettingApiURL: string;

  postApiURL: string;

  getUserApiURL: string;

  mockProfileUrl: string;

  isLoaded: boolean;

  deviceDetails: any = [];

  userObj: any;

  postSlackApiURL: string;

  constructor(
    private toastService: ToastService,
    private cS: CommonService,
    private themeService: CSSThemeService,
    private changeDetector: ChangeDetectorRef,
    private settings: TransferInformationComponent,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.settings.currentSettingsDetails.subscribe(settingJson => this.settingsSource = settingJson);
  }

  ngOnInit() {

    this.isLoaded = false;
    this.getUserApiURL = environment.getSettingsAPIURL + localStorage.getItem('userId');
    this.getApiURL = environment.getSettingsAPIURL + localStorage.getItem('userId') + '/settings';
    this.getSlackSettingApiURL = environment.getSettingsAPIURL + localStorage.getItem('ssoId') + '/slack/settings';
    this.postApiURL = environment.postSettingsAPIURL + localStorage.getItem('userId') + '/settings';
    this.postSlackApiURL = environment.postSettingsAPIURL + localStorage.getItem('ssoId') + '/slack/settings';
    this.mockProfileUrl = environment.mockProfileAPIURL;
    this.fetchSettingsDetails();
    this.fetchSlackSettingDetails();
    this.fetchUserDetails();
  
    setTimeout(() => {
      this.settingsDetails = this.settingsSource?.settings;
    }, 700);
     

    //this.config.swipeEasing = false;

  }

  ngAfterContentChecked(): void {

    // console.log('ngAfterContentChecked');
    this.changeDetector.detectChanges();

    // console.clear();

  }

  formatDivider(index, length) {

    if (window.innerWidth <= 1599) {

      return (index < (length - 1));

    } else if (window.innerWidth >= 1600) {

      return (index < (length - 2));

    }

  }

  public saveSettings() {

    const isChanged = this.comparePrivacyAndEmailChanges();

    if (!isChanged) {

      return;

    }

    this.isLoaded = false;

    Object.keys(this.cloneSettingsResponseData).forEach((key) => {

      if (key === 'enableDarkMode' && this.settingResponseData[key] !== this.cloneSettingsResponseData[key]) {

        return;

      }

    });

    this.cS.serviceRequestCommon('put', this.postApiURL, '', this.settingResponseData).subscribe((res: any) => {

      this.settingResponseData = res;
      this.cloneSettingsResponseData = Object.assign({}, res);
      this.toastService.createMessage('success', 'The settings has been successfully saved.');
      this.isLoaded = true;
      const theme = res.enableDarkMode ? 'dark' : 'default';

      this.themeService.loadTheme(theme);

    }, () => {

      this.toastService.createMessage('error', 'Error in saving data please try again.');
      this.isLoaded = true;

    });

  }

  public saveSlackSettings() {

    const isChanged = this.compareSlackChanges();

    if (!isChanged) {

      return;

    }

    this.isLoaded = false;

    Object.keys(this.cloneSlackSettingsResponseData).forEach((key) => {

      if (key === 'enableDarkMode' && this.slackSettingResponseData[key] !== this.cloneSlackSettingsResponseData[key]) {

        return;

      }

    });

    console.log('slack req:' + JSON.stringify(this.slackSettingResponseData));

    this.cS.serviceRequestCommon('put', this.postSlackApiURL, '', this.slackSettingResponseData).subscribe((res: any) => {

      this.slackSettingResponseData = res;
      this.cloneSlackSettingsResponseData = Object.assign({}, res);
      this.toastService.createMessage('success', 'The slack settings has been successfully saved.');
      this.isLoaded = true;
      const theme = res.enableDarkMode ? 'dark' : 'default';

      this.themeService.loadTheme(theme);

    }, () => {

      this.toastService.createMessage('error', 'Error in saving data please try again.');
      this.isLoaded = true;

    });

  }

  comparePrivacyAndEmailChanges(): boolean {

    let flag = false;

    Object.keys(this.cloneSettingsResponseData).forEach((key) => {

      if (this.settingResponseData[key] !== this.cloneSettingsResponseData[key]) {

        flag = true;
        return;

      }

    });
    return flag;

  }

  compareSlackChanges(): boolean {

    let flag = false;

    Object.keys(this.cloneSlackSettingsResponseData).forEach((key) => {

      if (this.slackSettingResponseData[key] !== this.cloneSlackSettingsResponseData[key]) {

        flag = true;
        return;

      }

    });
    return flag;

  }

  public createPayload(deviceObj, col, value) {

    const deviceToBeUpdated = this.deviceDetails.filter(device => device.deviceId === deviceObj.deviceId)[0];

    deviceToBeUpdated[col] = value;
    console.log('createPayload ', this.userObj, this.deviceDetails);

    // this.userObj.
    return this.userObj;

  }

  public fetchSettingsDetails() {

    this.cS.serviceRequestCommon('get', this.getApiURL).subscribe((res: any) => {

      // this.profileService.getSettingsDetails(this.getApiURL, false).subscribe((res: any) => {
      // console.log('settings Data', res);
      this.settingResponseData = res; // replace with res for real api
      this.cloneSettingsResponseData = Object.assign({}, res);
      this.isLoaded = true;

    }, err => {

      this.toastService.createMessage('error', err);
      this.isLoaded = true;

    });

  }

  public fetchSlackSettingDetails() {

    this.cS.serviceRequestCommon('get', this.getSlackSettingApiURL).subscribe((res: any) => {

      if (!res) {

        res = {
          notifySlackFollowing: false,
          slackDmId: '',
          slackUserId: '',
          slackUserNotification: false
        };

      }
      this.slackSettingResponseData = res; // replace with res for real api
      console.log('slack res: ' + JSON.stringify(this.slackSettingResponseData));
      this.cloneSlackSettingsResponseData = Object.assign({}, res);
      this.isLoaded = true;

    }, err => {

      this.toastService.createMessage('error', err);
      this.isLoaded = true;

    });

  }

  public fetchUserDetails() {

    this.cS.serviceRequestCommon('get', this.getUserApiURL).subscribe((res: any) => {

      if (res && res.deviceDetails) {

        this.userObj = res;
        this.deviceDetails = res.deviceDetails;
        console.log('fetchUserDetails : ', this.deviceDetails);

      }

    }, err => {

      this.toastService.createMessage('error', err);

    });

  }

  public updateDeviceDetails(device, col, value) {

    this.isLoaded = false;
    const payload = this.createPayload(device, col, value);

    this.cS.serviceRequestCommon('put', this.getUserApiURL, '', payload).subscribe((res: any) => {

      this.isLoaded = true;
      this.toastService.createMessage('success', 'Device Details Successfully Updated.');
      if (res && res.deviceDetails) {

        this.userObj = res;
        this.deviceDetails = res.deviceDetails;
        console.log('fetchUserDetails PUT: ', this.deviceDetails);

      }

    }, err => {

      this.isLoaded = true;
      this.toastService.createMessage('error', err);

    });

  }

}
