<nz-layout
   class="invalidLayout"
   style="height: 100vh"
   [attr.data-component]="'app/components/invalid-user'">
   <nz-empty
      [nzNotFoundContent]="contentTpl"
      [nzNotFoundFooter]="footerTpl">
      <ng-template #contentTpl>
         <div *ngIf="errorDetails && !(errorDetails.errorCode === 401 || errorDetails.errorCode === 10003)">
            <div style="padding-top: 24px">
               {{ errorDetails.errMsg }}
            </div>
         </div>
         <div *ngIf="errorDetails && (errorDetails.errorCode === 401 || errorDetails.errorCode === 10003)">
            <div>SSO Error</div>
            <div style="padding-top: 24px">
               Hi,
               <br />
               Your username and password is accepted but it has to be configured in NewsConnect Please contact
               <a
                  href=""
                  class="link">
                  &#64;NBC UNI News NewsConnect Support Requests
               </a>
               for Support
            </div>
         </div>
      </ng-template>
      <ng-template #footerTpl>
         <button
            nz-button
            nzType="primary"
            (click)="clearStorage()"
            *ngIf="errorDetails && errorDetails.errorCode !== 500">
            Click Here to Try Again
         </button>
         <button
            nz-button
            nzType="primary"
            (click)="refresh()"
            *ngIf="errorDetails && errorDetails.errorCode === 500">
            Click Here to Try Again
         </button>
         <!-- <button nz-button nzDanger="true" (click)="logout()" class="logoutbtn">logout</button> -->
      </ng-template>
   </nz-empty>
</nz-layout>
